/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import BrandLogo from "../../../../_admin/assets/media/uTradeLogo.png";
import APICallService from "../../../../api/apiCallService";
import { AUTH } from "../../../../api/apiEndPoints";
import { APIJSON } from "../../../../api/apiJSON/auth";
import { auth } from "../../../../utils/toast";
const loginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
});
const initialValues = {
  email: "",
};
const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("");
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        let data = {};
        if (/^\d+$/.test(values.email)) {
          data = { phone: values.email, phoneCountry: "+255" };
          setPhoneCountry("+255");
          setPhone(values.email);
        } else {
          data = { email: values.email };
          setPhone(values.email);
        }
        let apiService = new APICallService(
          AUTH.FORGOTPASSWORD,
          APIJSON.forgotPassword(data)
        );
        let response = await apiService.callAPI();
        if (response) {
          toast.success(auth.resendOTP);
          navigate("/auth/verify-password", { state: data });
        }
      } catch (error) {
        setStatus("The login details are incorrect");
      }
      setSubmitting(false);
      setLoading(false);
    },
  });
  return (
    <div className="d-flex flex-column flex-root bg-white">
      <div className="d-flex flex-column flex-lg-row-fluid py-10">
        <div className="">
          <Link
            to="/"
            className="mb-12 position-absolute top-0 start-0 mt-10 ms-10"
          >
            <img alt="Logo" src={BrandLogo} className="h-40px" />
          </Link>
        </div>
        <div className="d-flex flex-center pt-lg-0 pt-15 flex-column flex-column-fluid">
          <div className="w-lg-500px p-8 p-md-10 p-lg-15 mx-auto">
            <form
              className="form w-100"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <div className="mb-6 text-center">
                <h1 className="text-dark fs-35 fw-bolder mb-3">
                  Forgot password?
                </h1>
                <div className="text-dark fs-16 fw-500">
                  Please enter your registered email address to
                  receive an OTP for resetting your password.
                  <br></br>
                </div>
              </div>
              <div className="d-flex flex-wrap mb-2 justify-content-center">
                <div className="d-flex flex-wrap">
                  <input
                    placeholder="Enter email"
                    {...formik.getFieldProps("email")}
                    className={clsx(
                      "form-control form-control-custom text-center w-375px border-0"
                    )}
                    type="text"
                    name="email"
                    value={formik.values.email.trimStart()}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-primary br-8 w-lg-375px h-60px"
                  disabled={!formik.values.email.trimStart()}
                >
                  {!loading && (
                    <span className="indicator-label fs-16 fw-bolder">
                      Submit
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress indicator-label fs-16 fw-600"
                      style={{ display: "block" }}
                    >
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <div className="d-flex flex-center fs-16">
            Go back to
            <Link to="/auth/login" className="text-primary fs-16 fw-bold ms-1">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ForgotPassword };
