import { Button, Card, Col, Row } from "react-bootstrap";
import logo from "../../../../_admin/assets/media/uTradeLogo.png";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="p-9 bg-white h-50">
        <Row className="align-items-center">
          <Col xs>
            <img height={40} src={logo} alt="" />
          </Col>
          <Col xs="auto">
            <Button
              variant="link"
              className="btn-flush text-primary fs-18 fw-600"
              onClick={() => navigate("/auth")}
            >
              {" "}
              Sing In
            </Button>
          </Col>
          <Col xs={12} className="mt-15 mt-md-5 text-center">
            <h3 className="fs-26 fw-bolder">Terms and Conditions</h3>
          </Col>
        </Row>

        <div className="mt-15">
          <div className="position-absolute start-0 end-0 w-100 px-18">
            <Card className="p-5 bg-light-primary">
              <Card.Body className="fs-18 fw-500 text-dark">
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Eligendi rerum repellendus dicta facilis dolor fuga quasi
                  perspiciatis corrupti numquam atque sit, qui voluptatem
                  asperiores, adipisci minus dolores recusandae molestias ut,
                  aspernatur odio temporibus natus perferendis dolorem!
                  Recusandae praesentium expedita veritatis, illum perspiciatis
                  at ipsa provident fugiat a est laudantium, aliquid, blanditiis
                  eaque repudiandae officia. Nostrum natus iste velit
                  repudiandae ratione dignissimos ipsa doloremque laudantium,
                  eveniet aut odit saepe eaque ut harum, id ullam quaerat
                  dolorum assumenda illum vel enim aspernatur reprehenderit.
                  Rem, dolorum expedita. Voluptatum ad totam harum odit,
                  consectetur ex excepturi nulla voluptatibus voluptatem earum
                  expedita. Aliquam, harum ut?
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptate animi optio aliquam voluptatem? Perspiciatis
                  temporibus sint optio quo magnam ipsum. Amet, autem! Assumenda
                  hic architecto corporis quidem molestiae nihil quisquam atque
                  reiciendis! Optio natus quaerat veniam, rem ex impedit
                  deserunt reprehenderit! Voluptatum, accusamus ex. Eum aliquid
                  cum suscipit earum provident!
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Adipisci non similique minus illo, expedita officia ipsa,
                  laborum vero assumenda corporis quia natus alias nesciunt
                  sunt?
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
