import { Modal, Button, Card, Col, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { IMAGES } from '../../utils/dummyJSON';
import { useState } from 'react';
import clsx from 'clsx';
const AdjustInventory = (props: any) => {
  return (
    <>
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-850px"
        className="border-r10px"
        contentClassName="p-5"
        centered
        backdrop={props.loading ? 'static' : true}
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-425px pt-lg-3">
            Are you sure you save this changes?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="row justify-content-center ">
              <div className="col-lg-10">
                <div className="bg-light-danger text-center border-r10px p-3">
                  <span className="fs-18 fw-500">
                    {`By continuing this, you agree to perform this operation. Once the operation is performed, it cannot be reverted`}{' '}
                  </span>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-4 border-top-0">
          <Button
            size="lg"
            onClick={props.onSave}
          >
            {!props.loading && <span className="indicator-label"> Yes</span>}
            {props.loading && (
              <span
                className="indicator-progress"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
          <Button
            size="lg"
            onClick={props.onHide}
            variant="danger"
          >
            <span className="indicator-label">No</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AdjustInventory;
