import {
  IForgotPassword,
  ILoginData,
  IResetPassword,
  IVerify,
  IVerifyPassword,
} from "../../types";
export const APIJSON = {
  login: ({ email, password }: ILoginData) => {
    return {
      email: email,
      password: password,
    };
  },
  verify: ({ code }: IVerify) => {
    return { code: code };
  },
  forgotPassword: ({ email, phone, phoneCountry }: IForgotPassword) => {
    if (email) {
      return {
        email: email,
      };
    }
    if (phone && phoneCountry) {
      return {
        phoneCountry: phoneCountry,
        phone: phone,
      };
    }
  },
  verifyPassword: ({ email, phone, phoneCountry, code }: IVerifyPassword) => {
    if (email) {
      return {
        email: email,
        code: code,
      };
    }
    if (phone && phoneCountry) {
      return {
        phoneCountry: phoneCountry,
        phone: phone,
        code: code,
      };
    }
  },
  resetPassword: ({
    email,
    phone,
    phoneCountry,
    code,
    newPassword,
  }: IResetPassword) => {
    if (email) {
      return {
        email: email,
        code: code,
        newPassword: newPassword,
      };
    }
    if (phone && phoneCountry) {
      return {
        phoneCountry: phoneCountry,
        phone: phone,
        code: code,
        newPassword: newPassword,
      };
    }
  },
};
