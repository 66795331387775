import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../Global/loader';
import Pagination from '../../../Global/pagination';
import b1 from '../../../_admin/assets/media/product/default.svg';
import { KTSVG } from '../../../_admin/helpers';
import APICallService from '../../../api/apiCallService';
import { buyer, reports } from '../../../api/apiEndPoints';
import { customerJSON } from '../../../api/apiJSON/customer';
import { IGetCustomers } from '../../../types/responseIndex';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
import {
  FinanceManager,
  FulfillmentManager,
  NotRegistered,
  PAGE_LIMIT,
} from '../../../utils/constants';
import { listCustomers } from '../../../utils/storeString';
import { getKey, setKey } from '../../../Global/history';
import AllCustomerReport from '../../modals/report/all-customers-report';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import errorDanger from '../../../_admin/assets/media/svg_uTrade/error-danger.svg';
import { customerStateJSON } from '../../../utils/staticJSON';
import { error } from '../../../Global/toast';
const ViewCustomers = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(getKey(listCustomers.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listCustomers.limit) || PAGE_LIMIT
  );
  const [customers, setCustomers] = useState<IGetCustomers | any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [empty, setEmpty] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [customerState, setCustomerState] = useState(-1);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      await fetchInquiryData(page, pageLimit, search, true);
      setFetchLoading(false);
    })();
  }, []);
  const fetchInquiryData = async (
    pageNo: number,
    limit: number,
    search: string,
    initial?: boolean,
    state?: number
  ) => {
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'createdAt',
      sortOrder: -1,
      searchTerm: search,
      // state: 1,
    };
    if (state !== -1) {
      params = {
        ...params,
        state: state,
      };
    }
    let apiService = new APICallService(
      buyer.listBuyer,
      customerJSON.listCustomers(params)
    );
    let response = await apiService.callAPI();
    if (response) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      // else {
      //   let prevTotal = totalRecords;
      //   setTotalRecords(prevTotal);
      // }
      if (initial) {
        if (response.records.length) {
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      } else {
        setEmpty(false);
      }
      setCustomers(response.records);
    }
  };
  const handleCurrentPage = async (val: number) => {
    setLoading(true);
    setPage(val);
    setKey(listCustomers.page, val);
    await fetchInquiryData(val, pageLimit, search, false, customerState);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    setKey(listCustomers.page, val + 1);
    await fetchInquiryData(val + 1, pageLimit, search, false, customerState);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    setKey(listCustomers.page, val - 1);
    await fetchInquiryData(val - 1, pageLimit, search, false, customerState);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    setKey(listCustomers.page, 1);
    setKey(listCustomers.limit, parseInt(event.target.value));
    await setPageLimit(parseInt(event.target.value));
    await fetchInquiryData(1, event.target.value, search, false, customerState);
    setLoading(false);
  };
  const handleSearch = async (value: string) => {
    setLoading(true);
    setSearch(value);
    setKey(listCustomers.page, 1);
    await fetchInquiryData(1, pageLimit, value, false, customerState);
    if (!value.length) {
      setPage(1);
    }
    setLoading(false);
  };
  const handleCustomerProfile = (id: string) => {
    navigate('/customers/customer-profile', { state: id });
  };
  const handleFilter = async (value: number) => {
    setCustomerState(value);
    setKey(listCustomers.page, 1);
    setLoading(true);
    setPage(1);
    await fetchInquiryData(1, pageLimit, search, false, value);
    setLoading(false);
  };
  const popover = (
    <Popover
      id="popover-basic"
      className="bg-black"
    >
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>Customer haven't completed registration</span>
      </Popover.Body>
    </Popover>
  );
  const handleDownload = async () => {
    setRegistrationLoading(true);
    let apiService = new APICallService(
      reports.customerRegistrationReport,
      {
        utcOffset: new Date().getTimezoneOffset(),
        state: 0,
      },
      undefined,
      'blob'
    );
    let response = await apiService.callAPI();
    if (response) {
      const pdfBlob = new Blob([response], { type: 'application/pdf' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(pdfBlob);
      downloadLink.download = 'registration_report' + '.pdf';
      downloadLink.click();
    } else {
      error('No data found');
    }
    setRegistrationLoading(false);
  };
  return (
    <>
      <div className="p-9">
        {showDownload ? (
          <AllCustomerReport
            show={showDownload}
            onHide={() => setShowDownload(false)}
          />
        ) : (
          <></>
        )}
        <Row className="align-items-center mb-7">
          <Col sm>
            <h1 className="fs-22 fw-bolder mb-sm-0 mb-3">Customers</h1>
          </Col>
          {!fetchLoading ? (
            <>
              {!empty ? (
                <>
                  {currentUser?.role.some(
                    (obj: any) =>
                      obj.type === FinanceManager ||
                      obj.type === FulfillmentManager
                  ) ? (
                    <></>
                  ) : (
                    <Col sm={'auto'}>
                      <Link to="/customers/add-new-customers">
                        {' '}
                        <Button
                          size="lg"
                          className="mh-50px"
                        >
                          Add new customer{' '}
                        </Button>
                      </Link>
                    </Col>
                  )}
                  {currentUser?.role.length === 0 ||
                  currentUser?.role.some(
                    (obj: any) => obj.type === FinanceManager
                  ) ? (
                    <Col sm="auto">
                      <Button
                        variant=""
                        size="lg"
                        className="text-primary  bg-light-primary ms-3"
                        onClick={() => handleDownload()}
                      >
                        {!registrationLoading && (
                          <span className="indicator-label">
                            Download Pending Customers
                          </span>
                        )}
                        {registrationLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {currentUser?.role.length === 0 ||
                  currentUser?.role.some(
                    (obj: any) => obj.type === FinanceManager
                  ) ? (
                    <Col sm="auto">
                      <Button
                        variant=""
                        size="lg"
                        className="text-primary  bg-light-primary ms-3"
                        onClick={() => setShowDownload(true)}
                      >
                        Download Report
                      </Button>
                    </Col>
                  ) : (
                    <></>
                  )}
                </>
              ) : empty ? (
                <>
                  <Col
                    lg={12}
                    className="mt-6"
                  >
                    <Card className="border py-1">
                      <Card.Body>
                        <h2 className="fs-22 fw-bolder">
                          Start adding customers!
                        </h2>
                        <p className="fs-18 fw-500">
                          You can add customers and manage them.
                        </p>
                        {currentUser?.role.some(
                          (obj: any) => obj.type === FinanceManager
                        ) ? (
                          <></>
                        ) : (
                          <>
                            <Link to="/customers/add-new-customers">
                              <Button
                                className="mt-4"
                                variant="primary"
                                size="lg"
                              >
                                Add customers
                              </Button>
                            </Link>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col lg={12}>
            {!fetchLoading ? (
              <>
                {!empty ? (
                  <>
                    {' '}
                    <Card className="border bg-light mb-4">
                      <Card.Body>
                        <Col className="d-flex justify-content-between">
                          <div className="position-relative my-1">
                            <KTSVG
                              path="/media/icons/duotune/general/gen021.svg"
                              className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
                            />
                            <input
                              type="text"
                              className="form-control form-control-custom borde-r8px bg-white w-375px ps-11"
                              name="Search Team"
                              value={search}
                              onChange={(event: any) => {
                                handleSearch(event.target.value.trimStart());
                              }}
                              placeholder="Search by customer name, business name…"
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor=""
                              className="fs-16 fw-500"
                            >
                              Filter customer
                            </label>
                            <div className="min-w-lg-300px ms-6">
                              <CustomSelect
                                backgroundColor="#ffff"
                                // value={teamMember}
                                options={customerStateJSON}
                                onChange={(event: any) => {
                                  handleFilter(event.value);
                                }}
                                isSearchable={false}
                                isMulti={false}
                              />
                            </div>
                          </div>
                        </Col>
                      </Card.Body>
                    </Card>
                    <Card className="border">
                      <Card.Body>
                        {/* begin::Search */}
                        {/* end::Search */}
                        <div className="table-responsive">
                          <table className="table table-rounded table-row-bordered align-middle gy-4 mb-0">
                            <thead>
                              <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                <th className="min-w-275px">Business name</th>
                                <th className="min-w-175px">Customer name</th>
                                <th className="min-w-175px">Business type</th>
                                <th className="min-w-100px">Registered on</th>
                                <th className="min-w-200px text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <>
                                  <td colSpan={4}>
                                    <div className="w-100 d-flex justify-content-center">
                                      <Loader
                                        loading={fetchLoading || loading}
                                      />
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  {customers.length ? (
                                    <>
                                      {customers.map(
                                        (
                                          customVal: any,
                                          customIndex: number
                                        ) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className="d-flex align-items-center flex-row">
                                                  <div className="symbol symbol-50px border position-relative">
                                                    {customVal.regCompleted ||
                                                    customerState ===
                                                      NotRegistered ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div className="position-absolute start-0 bottom-0 m-n1">
                                                          <OverlayTrigger
                                                            trigger="hover"
                                                            placement="bottom"
                                                            overlay={popover}
                                                          >
                                                            <img
                                                              className="error-icon"
                                                              src={errorDanger}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </div>
                                                      </>
                                                    )}
                                                    <img
                                                      className="img-fluid border-r8px object-fit-contain"
                                                      src={
                                                        customVal.business.image
                                                          ? customVal.business
                                                              .image
                                                          : b1
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <span className="fs-15 fw-600 ms-3">
                                                    {customVal.business.name
                                                      ? customVal.business.name
                                                      : '-'}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-500">
                                                  {customVal.name
                                                    ? customVal.name
                                                    : '-'}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {customVal.business.types
                                                    .length
                                                    ? customVal.business
                                                        .types[0].reference
                                                        .title
                                                    : '-'}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-500">
                                                  {Method.convertDateToDDMMYYYY(
                                                    customVal.createdAt
                                                  )}
                                                </span>
                                              </td>
                                              <td>
                                                <div className="text-end">
                                                  <Button
                                                    className="fs-14"
                                                    onClick={() => {
                                                      handleCustomerProfile(
                                                        customVal._id
                                                      );
                                                    }}
                                                  >
                                                    View details
                                                  </Button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                          No Data found
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                    {!loading ? (
                      <>
                        {customers.length ? (
                          <Pagination
                            totalRecords={totalRecords}
                            currentPage={page}
                            handleCurrentPage={(event: any) => {
                              handleCurrentPage(event);
                            }}
                            handleNextPage={(event: any) => {
                              handleNextPage(event);
                            }}
                            handlePreviousPage={(event: any) => {
                              handlePreviousPage(event);
                            }}
                            pageLimit={pageLimit}
                            handlePageLimit={(event: any) => {
                              handlePageLimit(event);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <Loader loading={fetchLoading}></Loader>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ViewCustomers;
