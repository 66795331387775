import { FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_admin/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_admin/assets/ts/_utils';
import { WithChildren } from '../../_admin/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
// Modules
import DashboardIndex from '../modules/dashboard/index';
import MemberDashboardIndex from '../modules/member-dashboard/index';
import CustomersIndex from '../modules/customers';
import { useAuth } from '../modules/auth';
import { Manager } from '../../utils/constants';
import Orders from '../modules/orders-delivery/index';

import MyTeamIndex from '../modules/my-team';
// import OrdersIndex from "../modules/orders";
const AssistAdminRoutes = () => {
  const { currentUser } = useAuth();
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*"
          element={<Navigate to="/customers" />}
        />
        {/* Pages */}
        <Route
          path="builder"
          element={<BuilderPageWrapper />}
        />
        {/* Lazy Modules */}
        <Route
          path="dashboard/*"
          element={
            currentUser?.role.some((obj: any) => obj.type === Manager) ? (
              <DashboardIndex />
            ) : (
              <MemberDashboardIndex />
            )
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <Orders />
            </SuspensedView>
          }
        />
        <Route
          path="customers/*"
          element={<CustomersIndex />}
        />
        <Route
          path="my-team/*"
          element={<MyTeamIndex />}
        />
        {/* <Route path="orders/*" element={<OrdersIndex />} /> */}
        {/* Page Not Found */}
        <Route
          path="*"
          element={<Navigate to="/error/404" />}
        />
      </Route>
    </Routes>
  );
};
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
export { AssistAdminRoutes };
