import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_admin/layout/core';
import PrivacyPolicy from './privacy-policy';
import TermsCondition from './terms-condition';
import GeneralSettings from './general-settings';
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];
const SettingPages = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/error/404" />}
      />
      <Route
        index
        element={<Navigate to="/settings" />}
      />
      <Route
        path="privacy-policy"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Privacy Policy
            </PageTitle>
            <PrivacyPolicy />
          </>
        }
      />
      <Route
        path="terms-condition"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>
              Terms & Conditions
            </PageTitle>
            <TermsCondition />
          </>
        }
      />
      <Route
        path="app-settings"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>App Settings</PageTitle>
            <GeneralSettings />
          </>
        }
      />
    </Routes>
  );
};
export default SettingPages;
