import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { CustomSelect } from '../../../../custom/Select/CustomSelect';
import { IMAGES } from '../../../../../utils/dummyJSON';
import ViewGoodsRequestProducts from '../../../../modals/view-requested-goods-products';
import { useNavigate } from 'react-router-dom';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  PAGE_LIMIT,
  Piece,
  Seller,
  SuperAdmin,
} from '../../../../../utils/constants';
import Method from '../../../../../utils/methods';
import { seller } from '../../../../../api/apiEndPoints';
import APICallService from '../../../../../api/apiCallService';
import Loader from '../../../../../Global/loader';
import { goodsRequestStatusJSON } from '../../../../../utils/staticJSON';
import CustomDatePicker from '../../../../custom/DateRange/DatePicker';
const SellerGoodsRequest = (props: any) => {
  const navigate = useNavigate();
  const [date, setDate] = useState<any>('');
  const [requestState, setRequestState] = useState<any>(
    goodsRequestStatusJSON[0]
  );
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [fetchLoader, setFetchLoader] = useState(true);
  const [goods, setGoods] = useState<any>({});
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showRequestedGoods, setRequestedGoods] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [search, setSearch] = useState<string>('');
  const [tabKey, setTabKey] = useState<any>(1);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails(page, pageLimit, [1]);
      setFetchLoader(false);
    })();
  }, []);
  useEffect(() => {
    let temp = { ...requestState };
    if (temp.value == 1) {
      temp.label = (
        <>
          <span className="fs-16 fw-600 text-black mb-0">
            Pending {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    if (temp.value == 2) {
      temp.label = (
        <>
          <span className="fs-16 fw-600 text-black mb-0">
            Accepted
            {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    if (temp.value == 3) {
      temp.label = (
        <>
          <span className="fs-16 fw-600 text-black mb-0">
            Completed {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    if (temp.value == 4) {
      temp.label = (
        <>
          <span className="fs-16 fw-600 text-black mb-0">
            Rejected {totalRecords ? ' (' + totalRecords + ')' : ''}
          </span>
        </>
      );
    }
    setRequestState(temp);
  }, [totalRecords]);
  const fetchDetails = async (
    pageNo: number,
    limit: number,
    status?: any,
    search?: string,
    fromDate?: string,
    toDate?: string
  ) => {
    setLoading(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      needCount: pageNo === 1,
      searchTerm: search ? search : '',
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
    if (status.length > 0) {
      status.map((val: any, index: number) => {
        params = { ...params, ['status[' + index + ']']: val };
      });
    }
    let apiService = new APICallService(seller.listGoodsRequest, params, {
      id: props.businessId,
    });
    let response: any = await apiService.callAPI();
    if (response.records) {
      if (pageNo === 1) {
        setTotalRecords(response.total);
      } else {
        let prevTotal = totalRecords;
        setTotalRecords(prevTotal);
      }
    }
    setGoods(response.records);
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setFetchLoader(true);
    setPage(val);
    // await fetchDetails(val, pageLimit, '');
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchDetails(
        val,
        pageLimit,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    setFetchLoader(false);
  };
  const handleNextPage = async (val: number) => {
    setFetchLoader(true);
    setPage(val + 1);
    // await fetchDetails(val + 1, pageLimit, '');
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchDetails(
        val + 1,
        pageLimit,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    setFetchLoader(false);
  };
  const handlePreviousPage = async (val: number) => {
    setFetchLoader(true);
    setPage(val - 1);
    // await fetchDetails(val - 1, pageLimit, '');
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchDetails(
        val - 1,
        pageLimit,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    setFetchLoader(false);
  };
  const handlePageLimit = async (event: any) => {
    setFetchLoader(true);
    setPage(1);
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchDetails(
        1,
        event.target.value,
        getTabCategories(tabIndex),
        search,
        startDate,
        endDate
      );
    }
    await setPageLimit(parseInt(event.target.value));
    setFetchLoader(false);
  };
  const handleDateFilter = async (event: any) => {
    setStartDate(event[0]);
    setEndDate(event[1]);
    setTotalRecords(0);
    setGoods([]);
    setFetchLoader(true);
    setPage(1);
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    const startDateFormatted =
      event[0] && event[1]
        ? Method.convertDateToFormat(event[0], 'YYYY-MM-DD')
        : '';
    const endDateFormatted =
      event[0] && event[1]
        ? Method.convertDateToFormat(event[1], 'YYYY-MM-DD')
        : '';
    for (const tabIndex of selectedTabIndices) {
      await fetchDetails(
        1,
        pageLimit,
        getTabCategories(tabIndex),
        '',
        startDateFormatted,
        endDateFormatted
      );
    }
    setFetchLoader(false);
  };
  const getTabCategories = (tabIndex: number) => {
    switch (tabIndex) {
      case 1:
        return [1];
      case 2:
        return [2, 5, 6];
      case 3:
        return [7];
      case 4:
        return [3, 4];
      default:
        return [];
    }
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setFetchLoader(true);
    setPage(1);
    let selectedTabIndices: any = [];
    selectedTabIndices = [parseInt(tabKey)];
    for (const tabIndex of selectedTabIndices) {
      await fetchDetails(
        1,
        pageLimit,
        getTabCategories(tabIndex),
        value,
        startDate,
        endDate
      );
    }
    setFetchLoader(false);
  };
  const handleRequestState = async (event: any) => {
    setFetchLoader(true);
    setPage(1);
    setRequestState(event);
    setTabKey(event.value);
    await fetchDetails(
      1,
      pageLimit,
      getTabCategories(event.value),
      startDate,
      endDate
    );
    setFetchLoader(false);
  };
  return (
    <>
      {/* GOODS REQUEST */}
      <Col xs={12}>
        <Row className="align-items-center g-5">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Goods request
          </Col>
          <Col sm="auto">
            <CustomDatePicker
              className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
              onChange={handleDateFilter}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker={true}
              maxDate={new Date()}
              isClearable={true}
              inputTextBG="bg-white"
            />
          </Col>
          <Col sm="auto">
            <CustomSelect
              backgroundColor="#ffff"
              minHeight="60px"
              value={requestState}
              defaultValue={requestState}
              onChange={(event: any) => {
                handleRequestState(event);
              }}
              options={goodsRequestStatusJSON}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        {/* <div className="border border-r10px mb-6">
          <div className="d-flex justify-content-center text-center align-items-center min-h-160px px-3">
            <span className="fs-18 fw-500">No records available.</span>
          </div>
        </div> */}
        {products && products.length && showRequestedGoods ? (
          <ViewGoodsRequestProducts
            show={showRequestedGoods}
            onHide={() => setRequestedGoods(false)}
            products={products}
          />
        ) : (
          <></>
        )}
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table
                className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0"
                style={{ tableLayout: 'auto' }}
              >
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-150px">Request initiated on</th>
                    {tabKey === 3 ? (
                      <th className="min-w-150px">Request completed on</th>
                    ) : (
                      <></>
                    )}
                    <th className="min-w-150px">
                      {tabKey === 2 ? 'Delivered by' : 'Request initiated by'}
                    </th>
                    {tabKey === 2 ? (
                      <th className="min-w-150px">Status</th>
                    ) : (
                      <></>
                    )}
                    <th className="min-w-150px">Quantity</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {!fetchLoader ? (
                    <>
                      {' '}
                      {goods && goods.length ? (
                        <>
                          {goods.map((goodVal: any, goodIndex: number) => {
                            return (
                              <tr>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {Method.convertDateToDDMMYYYYHHMM(
                                      goodVal._createdAt,
                                      '-'
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600 ">
                                    {goodVal.requestor.userType === SuperAdmin
                                      ? 'U-Trade'
                                      : ''}{' '}
                                    {goodVal.requestor.userType === Seller
                                      ? 'Seller'
                                      : ''}
                                  </span>
                                </td>
                                <td>
                                  <div>
                                    <span className="fs-15 fw-600">
                                      {goodVal.totalQuantities
                                        .map((item: any) => {
                                          if (item.type === CartonWithDozens) {
                                            return `${item.stockCount} C(D)`;
                                          }
                                          if (item.type === CartonWithPieces) {
                                            return `${item.stockCount} C(P)`;
                                          }
                                          if (item.type === Dozen) {
                                            return `${item.stockCount} D`;
                                          }
                                          if (item.type === Piece) {
                                            return `${item.stockCount} P`;
                                          }
                                          return '';
                                        })
                                        .join(', ')}
                                    </span>
                                    <br />
                                    <Button
                                      variant="link"
                                      className="btn-flush fs-16 fw-600 text-decoration-underline"
                                      onClick={() => {
                                        setProducts(goodVal.variants);
                                        setRequestedGoods(true);
                                      }}
                                    >
                                      View products
                                    </Button>
                                  </div>
                                </td>
                                <td className="text-end">
                                  <Button
                                    className="btn-table"
                                    onClick={() =>
                                      navigate(
                                        '/inventory/goods-requests-details',
                                        { state: goodVal._id }
                                      )
                                    }
                                  >
                                    View details
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <>
                      <td colSpan={5}>
                        <div className="w-100 d-flex justify-content-center">
                          <Loader loading={fetchLoader || loading} />
                        </div>
                      </td>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {/* <Col xs={12}>
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-600 align-middle h-65px">
                    <th className="min-w-175px">Request initiated on</th>
                    <th className="min-w-150px">Delivered by</th>
                    <th className="min-w-175px">Status</th>
                    <th className="min-w-150px">Quantity</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">
                        U Trade transport <br />
                        <span>T 940 AGY</span>
                      </span>
                    </td>
                    <td>
                      <span className="badge badge-light fs-14 fw-600 text-black p-3 px-4">
                        Request accepted
                      </span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">Seller transport</span>
                    </td>
                    <td>
                      <span className="badge bg-fff4d9 fs-14 fw-600 text-black p-3 px-4">
                        Pickup in progress
                      </span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">U Trade transport</span>
                    </td>
                    <td>
                      <div className="badge bg-e5f6de fs-14 fw-600 text-black p-3 px-4 pulse pulse-success">
                        <img
                          width={11}
                          height={11}
                          className="me-2"
                          src={IMAGES.GreenDot}
                          alt=""
                        />
                        Arriving today
                        <span className="pulse-ring start-0"></span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-150px">Request initiated on</th>
                    <th className="min-w-150px">Request completed on</th>
                    <th className="min-w-150px">Request initiated by</th>
                    <th className="min-w-150px">Quantity</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">23/04/23 - 13:17</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">U-Trade</span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">15/04/23 - 10:15</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">23/04/23 - 13:17</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">Seller</span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button className="btn-table">View details</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">18/04/23 - 21:30</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">23/04/23 - 13:17</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">U-Trade</span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col> */}
      {/* RETURN GOODS */}
      {/* <Col xs={12}>
        <Row className="align-items-center g-5">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Return goods
          </Col>
          <Col sm="auto">
            <CustomSelect
              backgroundColor="#f9f9f9"
              minHeight="50px"
            />
          </Col>
          <Col sm="auto">
            <CustomSelect
              backgroundColor="#f9f9f9"
              minHeight="50px"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table
                id="kt_datatable_zero_configuration"
                className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0 no-footer"
                style={{ tableLayout: 'auto' }}
              >
                <thead>
                  <tr className="fw-bold fs-16 h-77px align-middle">
                    <th className="min-w-175px">Seller name</th>
                    <th className="min-w-150px">Delivered / Pickup by</th>
                    <th className="min-w-175px">Status</th>
                    <th className="min-w-150px">Quantity</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">U Trade transport</span>
                    </td>
                    <td>
                      <span className="badge badge-light fs-14 fw-600 text-black p-3 px-4">
                        Return initiated
                      </span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/return-goods-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">Seller transport</span>
                    </td>
                    <td>
                      <span className="badge bg-fff4d9 fs-14 fw-600 text-black p-3 px-4">
                        Preparing for dispatch
                      </span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/return-goods-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600">U Trade transport</span>
                    </td>
                    <td>
                      <div className="badge bg-e5f6de fs-14 fw-600 text-black p-3 px-4 pulse pulse-success">
                        <img
                          width={11}
                          height={11}
                          className="me-2"
                          src={IMAGES.GreenDot}
                          alt=""
                        />
                        Collecting today
                        <span className="pulse-ring start-0"></span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/return-goods-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12}>
        <Card className="border border-r10px">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-150px">Request initiated on</th>
                    <th className="min-w-150px">Request completed on</th>
                    <th className="min-w-150px">Delivered / Pickup by</th>
                    <th className="min-w-150px">Quantity</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">12/04/23 - 08:10</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">23/04/23 - 13:17</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">U-Trade</span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">15/04/23 - 10:15</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">23/04/23 - 13:17</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">Seller</span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button className="btn-table">View details</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="fs-15 fw-600">18/04/23 - 21:30</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">23/04/23 - 13:17</span>
                    </td>
                    <td>
                      <span className="fs-15 fw-600 ">U-Trade</span>
                    </td>
                    <td>
                      <div>
                        <span className="fs-15 fw-600">50 C, 120 P</span>
                        <br />
                        <Button
                          variant="link"
                          className="btn-flush fs-16 fw-600 text-decoration-underline"
                        >
                          View products
                        </Button>
                      </div>
                    </td>
                    <td className="text-end">
                      <Button
                        className="btn-table"
                        onClick={() =>
                          navigate('/inventory/goods-requests-details')
                        }
                      >
                        View details
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col> */}
    </>
  );
};
export default SellerGoodsRequest;
