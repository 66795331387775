import { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
const PaymentDashboard = lazy(() => import('./dashboard/payment-dashboard'));
const PaymentsToSellers = lazy(() => import('./payments/payments-to-sellers'));
const SellerFinanceDetails = lazy(
  () => import('./payments/seller-finance-details')
);
const MakePayment = lazy(() => import('./payments/make-payment'));
const PaymentCompleted = lazy(() => import('./payments/payment-completed'));
const RefundsToCustomers = lazy(() => import('./refunds/refunds-to-customers'));
const CustomerFinanceDetails = lazy(
  () => import('./refunds/customer-finance-details')
);
const PaymentsIndex = () => {
  return (
    <>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            index
            element={<Navigate to="/payments" />}
          />
          {/* Payments to sellers */}
          <Route
            path="payment-dashboard"
            element={
              <>
                <PaymentDashboard />
              </>
            }
          />
          <Route
            path="payments-to-sellers"
            element={
              <>
                <PaymentsToSellers />
              </>
            }
          />
          <Route
            path="payments-to-sellers/seller-finance-details"
            element={
              <>
                <SellerFinanceDetails />
              </>
            }
          />
          <Route
            path="/payments-to-sellers/seller-finance-details/make-payment"
            element={
              <>
                <MakePayment />
              </>
            }
          />
          <Route
            path="/payments-to-sellers/seller-finance-details/payment-completed"
            element={
              <>
                <PaymentCompleted />
              </>
            }
          />
          {/* Refunds to customers */}
          <Route
            path="refunds-to-customers"
            element={
              <>
                <RefundsToCustomers />
              </>
            }
          />
          <Route
            path="refunds-to-customers/customer-finance-details"
            element={
              <>
                <CustomerFinanceDetails />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );
};
export default PaymentsIndex;
