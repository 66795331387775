import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_admin/layout/core';
import SalesReport from './sales-report';
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/finance-reports/sales-report',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];
const ProfilePage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path="sales-report"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Sales Report</PageTitle>
            <SalesReport />
          </>
        }
      />

      <Route
        index
        element={<Navigate to="/finance-reports/sales-report" />}
      />
    </Route>
  </Routes>
);
export default ProfilePage;
