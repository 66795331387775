import {
  IKycStatusUpdate,
  IListSellerKyc,
  IProfileUpdate,
  IListSeller,
  IUpdateSellerState,
  IAddSeller,
  IListPayoutProfiles,
  IListPayoutOrders,
  IListPayout,
  IPayout,
} from '../../types';
export const sellerJSON = {
  listSellerKyc: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    listType,
    searchTerm,
  }: IListSellerKyc) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      listType: listType,
      searchTerm: searchTerm,
    };
  },
  sellerProfileUpdate: ({ profile, pendingCertificates }: IProfileUpdate) => {
    let form = new FormData();
    form.append('ownerName', profile.ownerName);
    form.append('name', profile.name);
    form.append('phone', profile.phone);
    form.append('phoneCountry', profile.phoneCountry);
    form.append('email', profile.email);
    form.append('address', profile.address);
    form.append('lat', profile.lat.toString());
    form.append('lng', profile.lng.toString());
    form.append('about', profile.about);
    if (profile.imageReader && profile.imageReader !== '')
      form.append('image', profile.imageReader);
    if (profile.categories.length) {
      profile.categories.map((catVal: any, index: number) => {
        return form.append('categories[' + index + ']', catVal.reference._id);
      });
    }
    if (profile.certificates.length) {
      let changedCertificate: any = [];
      profile.certificates.map((catVal: any, index: number) => {
        if (catVal.isChanged === true) {
          changedCertificate.push(catVal);
        }
      });
      if (changedCertificate.length) {
        changedCertificate.map((val: any, index: number) => {
          form.append(
            'certificates[' + index + '][reference]',
            val.reference._id
          );
          form.append(
            'certificates[' + index + '][expiry]',
            val.expiry ? val.expiry : ''
          );
          if (val.imageReader) {
            form.append('certificates[' + index + '][file]', val.imageReader);
            form.append('certificates[' + index + '][removeFile]', 'true');
          } else {
            form.append('certificates[' + index + '][removeFile]', 'false');
          }
          return form;
        });
      }
    }
    if (profile.types.length) {
      profile.types.map((businessVal: any, index: number) => {
        return form.append('types[' + index + ']', businessVal.reference._id);
      });
    }
    return form;
  },
  kycStatusUpdate: ({ status, message }: IKycStatusUpdate) => {
    return { status: status, message: message };
  },
  listSeller: ({
    limit,
    pageNo,
    sortKey,
    sortOrder,
    searchTerm,
    status,
  }: IListSeller) => {
    return {
      limit: limit,
      pageNo: pageNo,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
      status: status,
    };
  },
  updateSellerState: ({ activate }: IUpdateSellerState) => {
    return { activate: activate };
  },
  addSellerProfile: ({
    about,
    address,
    categories,
    email,
    name,
    ownerName,
    phone,
    phoneCountry,
    types,
    certificates,
    lat,
    lng,
    imageReader,
  }: IAddSeller) => {
    let form = new FormData();
    form.append('ownerName', ownerName);
    form.append('name', name);
    form.append('phone', phone);
    form.append('phoneCountry', phoneCountry);
    form.append('email', email);
    form.append('address', address);
    form.append('lat', lat);
    form.append('lng', lng);
    form.append('about', about);
    if (imageReader && imageReader !== '') form.append('image', imageReader);
    if (categories.length) {
      categories.map((catVal: any, index: number) => {
        return form.append('categories[' + index + ']', catVal);
      });
    }
    if (certificates.length) {
      certificates.map((val: any, index: number) => {
        if (val.reference) {
          form.append('certificates[' + index + '][reference]', val.reference);
          form.append(
            'certificates[' + index + '][expiry]',
            val.expiry ? val.expiry : ''
          );
          form.append('certificates[' + index + '][file]', val.imageReader);
          form.append('certificates[' + index + '][removeFile]', 'true');
          return form;
        }
      });
    }
    form.append('types[' + 0 + ']', types);
    return form;
  },
  listPayoutProfiles: ({
    limit,
    pageNo,
    sortKey,
    sortOrder,
    searchTerm,
  }: IListPayoutProfiles) => {
    return {
      limit: limit,
      pageNo: pageNo,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
    };
  },
  listPayoutOrders: ({
    limit,
    pageNo,
    sortKey,
    sortOrder,
    searchTerm,
    state,
  }: IListPayoutOrders) => {
    return {
      limit: limit,
      pageNo: pageNo,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
      state: state,
    };
  },
  listPayout: ({
    limit,
    pageNo,
    sortKey,
    sortOrder,
    searchTerm,
  }: IListPayout) => {
    return {
      limit: limit,
      pageNo: pageNo,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
    };
  },
  payout: ({ orders, paymentMethod, password, paymentRef }: IPayout) => {
    return {
      orders: orders,
      paymentMethod: paymentMethod,
      password: password,
      paymentRef: paymentRef,
    };
  },
};
