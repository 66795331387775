export const listSeller = {
  page: 'listSellerPage',
  limit: 'listSellerLimit',
  currentTab: 'listSellerCurrentTab',
};
export const sellerProfile = {
  currentTab: 'sellerProfileCurrentTab',
};
export const listSellerProfileCatalogue = {
  page: 'listSellerProfileCataloguePage',
  limit: 'listSellerProfileCatalogueLimit',
};
export const listSellerInventory = {
  page: 'listSellerInventoryPage',
  limit: 'listSellerInventoryLimit',
};
export const listSellerGoodsRequest = {
  page: 'listSellerGoodsRequestPage',
  limit: 'listSellerGoodsRequestLimit',
};
export const listSellerStockDetails = {
  page: 'listSellerStockDetailsPage',
  limit: 'listSellerStockDetailsLimit',
};
export const listSellerHistoryOfInventory = {
  page: 'listSellerHistoryOfInventoryPage',
  limit: 'listSellerHistoryOfInventoryLimit',
};
export const listSellerAdvertisement = {
  page: 'listSellerAdvertisementPage',
  limit: 'listSellerAdvertisementLimit',
};
export const listKycVerification = {
  page: 'listKycVerificationPage',
  limit: 'listKycVerificationLimit',
  currentTab: 'listKycVerificationCurrentTab',
};
export const listPendingCertificates = {
  page: 'listPendingCertificatesPage',
  limit: 'listPendingCertificatesLimit',
};
export const listPaymentToSeller = {
  page: 'listPaymentToSellerPage',
  limit: 'listPaymentToSellerLimit',
};
export const listSellerFinanceDetails = {
  page: 'listSellerFinanceDetailsPage',
  limit: 'listSellerFinanceDetailsLimit',
  currentTab: 'listSellerFinanceDetailsCurrentTab',
  filter: 'listSellerFinanceDetailsFilter',
};
export const listCustomerRefundDetails = {
  page: 'listCustomerRefundDetailsPage',
  limit: 'listCustomerRefundDetailsLimit',
  currentTab: 'listCustomerRefundDetailsCurrentTab',
  filter: 'listCustomerRefundDetailsFilter',
};
export const listReceivablePayouts = {
  page: 'listReceivablePayoutsPage',
  limit: 'listReceivablePayoutsLimit',
  filterStartDate: 'listReceivablePayoutsFilterStartDate',
  filterEndDate: 'listReceivablePayoutsFilterEndDate',
  filterCustomer: 'listReceivablePayoutsFilterCustomer',
};
export const listCustomers = {
  page: 'listCustomersPage',
  limit: 'listCustomersLimit',
};
export const listRefundCustomers = {
  page: 'listRefundCustomersPage',
  limit: 'listRefundCustomersLimit',
};
export const listOrdersDelivery = {
  page: 'listOrdersDeliveryPage',
  limit: 'listOrdersDeliveryLimit',
  currentTab: 'listOrdersDeliveryCurrentTab',
  currentDistrict: 'listOrdersDeliveryCurrentDistrict',
  currentAreas: 'listOrdersDeliveryCurrentAreas',
  currentPlanStatus: 'listOrdersDeliveryCurrentPlanStatus',
  selectedOrders: 'selectedOrders',
  selectedVehicle: 'selectedVehicle',
  selectedListVehicle: 'selectedListVehicle',
};
export const listBuyerOrder = {
  page: 'listBuyerOrderPage',
  limit: 'listBuyerOrderLimit',
  currentTab: 'listBuyerOrderCurrentTab',
  currentDistrict: 'listBuyerOrderCurrentDistrict',
  currentPlanStatus: 'listBuyerOrderCurrentPlanStatus',
};
export const listCategories = {
  page: 'listCategoriesPage',
  limit: 'listCategoriesLimit',
};
export const listBusinessTypes = {
  page: 'listBusinessTypesPage',
  limit: 'listBusinessTypesLimit',
};
export const listCertificates = {
  page: 'listCertificatesPage',
  limit: 'listCertificatesLimit',
};
export const listBrands = {
  page: 'listBrandsPage',
  limit: 'listBrandsLimit',
};
export const listVariants = {
  page: 'listVariantsPage',
  limit: 'listVariantsLimit',
  currentTab: 'listVariantsCurrentTab',
  currentTabId: 'listVariantsCurrentTabId',
};
export const listTechnicalDetails = {
  page: 'listTechnicalDetailsPage',
  limit: 'listTechnicalDetailsLimit',
  currentTab: 'listTechnicalDetailsCurrentTab',
  currentTabId: 'listTechnicalDetailsCurrentTabId',
};
export const listVehicles = {
  page: 'listVehiclesPage',
  limit: 'listVehiclesLimit',
};
export const listDrivers = {
  page: 'listDriversPage',
  limit: 'listDriversLimit',
};
export const listMasterProducts = {
  page: 'listMasterProductsPage',
  limit: 'listMasterProductsLimit',
};
export const listVehiclesLoadingArea = {
  page: 'listVehiclesLoadingAreaPage',
  limit: 'listVehiclesLoadingAreaLimit',
};
export const listSellerProductZone = {
  page: 'listSellerProductZonePage',
  limit: 'listSellerProductZoneLimit',
};
export const listFinanceMember = {
  page: 'listFinanceMemberPage',
  limit: 'listFinanceMemberLimit',
};
export const listGoodsInWarehouse = {
  page: 'listGoodsInWarehousePage',
  limit: 'listGoodsInWarehouseLimit',
};
export const listGoodsRequest = {
  page: 'listGoodsRequestPage',
  limit: 'listGoodsRequestLimit',
  currentTab: 'listGoodsRequestCurrentTab',
};
export const listDistricts = {
  page: 'listDistrictsPage',
  limit: 'listDistrictsLimit',
};
export const listCustomerServiceTeam = {
  page: 'listCustomerServiceTeamPage',
  limit: 'listCustomerServiceTeamLimit',
  currentTab: 'listCustomerServiceTeamCurrentTab',
};
export const listAllProducts = {
  page: 'listAllProductsPage',
  limit: 'listAllProductsLimit',
  currentTab: 'listAllProductsCurrentTab',
};
export const listPlatformFees = {
  currentTab: 'listPlatformFeesCurrentTab',
};
export const listAdvertisement = {
  page: 'listAdvertisementPage',
  limit: 'listAdvertisementLimit',
  currentTab: 'listAdvertisementCurrentTab',
};
export const listContactInquiries = {
  page: 'listContactInquiriesPage',
  limit: 'listContactInquiriesLimit',
  currentTab: 'listContactInquiriesCurrentTab',
};
