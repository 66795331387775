import {
  Modal,
  Button,
  Row,
  Col,
  Card,
  FormLabel,
  Form,
  InputGroup,
} from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { CustomSelectWhite } from '../custom/Select/CustomSelectWhite';
import { useEffect, useState } from 'react';
import APICallService from '../../api/apiCallService';
import { buyer, customerService } from '../../api/apiEndPoints';
import clsx from 'clsx';
import AutoComplete from '../custom/autoComplete';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { APIkey } from '../../utils/constants';
import Validations from '../../utils/validations';
const AddNewAddress = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<{
    name: string;
    phoneCountry: string;
    phone: string;
    addressLine1: string;
    landmark: string;
    city: string;
    district: string;
    districtName: string;
    lat: string;
    lng: string;
    ward: string;
  }>({
    name: '',
    phoneCountry: '+255',
    phone: props.details.phone,
    addressLine1: '',
    districtName: '',
    landmark: '',
    city: '',
    district: '',
    lat: '1',
    lng: '1',
    ward: '',
  });
  const [districts, setDistricts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [validation, setValidation] = useState<{
    name: boolean;
    phoneCountry: boolean;
    phone: boolean;
    addressLine1: boolean;
    landmark: boolean;
    city: boolean;
    district: boolean;
  }>({
    name: false,
    phoneCountry: false,
    phone: false,
    addressLine1: false,
    landmark: false,
    city: false,
    district: false,
  });
  const [fetchLoader, setFetchLoader] = useState(false);
  const [ward, setWard] = useState('');
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDistrict(page, 10);
      setFetchLoader(false);
    })();
  }, []);
  const fetchDistrict = async (pageNo: number, limit: number) => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params);
    let response = await apiService.callAPI();
    let data: any = [...districts];
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        _id: val._id,
        name: val.name,
      });
    });
    setDistricts(data);
  };
  const onMenuScrollToBottom = async () => {
    if (!(districts.length === totalRecords)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchDistrict(tempPage, 10);
    }
  };
  const handleChange = (value: any, name: string) => {
    let temp: any = { ...address };
    let validateTemp: any = { ...validation };
    temp[name] = value.trimStart();
    if (value.trimStart().length > 0) {
      validateTemp[name] = false;
    } else {
      validateTemp[name] = true;
    }
    setAddress(temp);
    setValidation(validateTemp);
  };
  const handleDistrict = async (event: any) => {
    let temp: any = { ...address };
    let tempValidation: any = { ...validation };
    temp.district = event._id;
    temp.districtName = event.label;
    tempValidation.district = false;
    setValidation(tempValidation);
    setAddress(temp);
  };
  const handleSaveAddress = async () => {
    let temp = { ...address };
    let validateTemp = { ...validation };
    if (
      !temp.name &&
      !temp.phone &&
      !temp.addressLine1 &&
      !temp.city &&
      !temp.district
    ) {
      validateTemp.name = true;
      validateTemp.phone = true;
      validateTemp.addressLine1 = true;
      validateTemp.city = true;
      validateTemp.district = true;
    } else if (!temp.name) {
      validateTemp.name = true;
    } else if (!temp.phone) {
      validateTemp.phone = true;
    } else if (!temp.addressLine1) {
      validateTemp.addressLine1 = true;
    } else if (!temp.landmark) {
      validateTemp.landmark = true;
    } else if (!temp.city) {
      validateTemp.city = true;
    } else if (!temp.district) {
      validateTemp.district = true;
    } else {
      setLoading(true);
      // Use the filter method to find matching objects
      const matchingObjects: any = districts.filter((obj: any) => {
        // we're checking if 'searchText' is present in the 'name' property
        return obj.name.includes(temp.districtName);
      });
      if (matchingObjects.length) {
        temp.districtName = matchingObjects[0].name;
        temp.district = matchingObjects[0]._id;
      } else {
        temp.districtName = 'UpCountry';
        temp.district = '651d5fa8317c6e0dcdcd8fa1';
      }
      let apiService = new APICallService(buyer.addAddress, temp, {
        _id: props.selectedCustomer,
      });
      let response = await apiService.callAPI();
      if (response) {
        props.onSaveAddress(response);
      }
    }
    setValidation(validateTemp);
    setLoading(false);
  };
  const handleAddressChange = async (value: string, lat: any, lng: any) => {
    let tempValidation: any = { ...validation };
    let temp: any = { ...address };
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIkey}`;
    await fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          const results = data.results[0];
          let districtMatched = false;
          // Extract city and district from the address components
          let ward;
          for (const result of data.results) {
            for (const component of result.address_components) {
              if (
                component.types.includes('sublocality_level_1') ||
                component.types.includes('administrative_area_level_4')
              ) {
                ward = component.long_name;
                break;
              }
            }
            if (ward) break;
          }
          temp.ward = ward;
          console.log(ward);
          for (const component of results.address_components) {
            console.log(component);
            if (component.types.includes('administrative_area_level_3')) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            } else if (
              component.types.includes('administrative_area_level_2')
            ) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            } else if (
              component.types.includes('administrative_area_level_1')
            ) {
              if (!districtMatched) {
                temp.districtName = component.long_name;
                tempValidation.district = false;
                districtMatched = true;
              }
            }
            if (!districtMatched) {
              temp.districtName = '';
              tempValidation.district = true;
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    temp['addressLine1'] = value.trimStart();
    temp['lat'] = lat;
    temp['lng'] = lng;
    const matchingObjects: any = districts.filter((obj: any) => {
      // we're checking if 'searchText' is present in the 'name' property
      return obj.name.includes(temp.districtName);
    });
    if (matchingObjects.length) {
      temp.districtName = matchingObjects[0].name;
      temp.district = matchingObjects[0]._id;
    } else {
      temp.districtName = 'UpCountry';
      temp.district = '651d5fa8317c6e0dcdcd8fa1';
    }
    setAddress(temp);
    tempValidation['addressLine1'] = false;
    if (!value.length) tempValidation['addressLine1'] = true;
    setValidation(tempValidation);
  };
  const handleOnKeyPress = async (event: any) => {
    // Allow only numbers
    const key = event.key;
    let validations = await Validations.allowOnlyNumbers(key);
    if (!validations) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog-centered min-w-lg-794px"
      className="border-r10px"
      centered
      backdrop="static"
    >
      <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
        <img
          className="close-inner-top-2"
          width={40}
          height={40}
          src={CrossSvg}
          alt="closebutton"
          onClick={props.onHide}
        />
        <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-lg-3">
          Add new address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="bg-f9f9f9 border">
          <Card.Body>
            <Row className="g-6">
              <Col md={6}>
                <FormLabel className="fs-16 fw-500 text-dark">
                  Shop name
                </FormLabel>
                <Form.Control
                  className={clsx(
                    'form-control-custom bg-white',
                    validation.name ? 'border-danger' : ''
                  )}
                  value={address.name}
                  onChange={(event: any) => {
                    handleChange(event.target.value, 'name');
                  }}
                />
              </Col>
              <Col md={6}>
                <FormLabel className="fs-16 fw-500 text-dark">
                  Phone number
                </FormLabel>
                <InputGroup
                  className={clsx(
                    validation.phone ? 'border border-danger' : ''
                  )}
                >
                  <InputGroup.Text className="bg-white border-right-0 fs-16 fw-600 text-black px-6">
                    +255
                  </InputGroup.Text>
                  <Form.Control
                    name="phoneNumber"
                    className="form-control-custom border-active-none bg-white border-left-0 ps-0"
                    aria-label="Default"
                    onChange={async (event: any) => {
                      handleChange(event.target.value, 'phone');
                    }}
                    onKeyPress={(event: any) => {
                      handleOnKeyPress(event);
                    }}
                    value={address.phone}
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
              </Col>
              <Col md={6}>
                <FormLabel className="fs-16 fw-500 text-dark">
                  Address line 1
                </FormLabel>
                {/* <Form.Control
                  className={clsx(
                    'form-control-custom bg-white',
                    validation.addressLine1 ? 'border-danger' : ''
                  )}
                  value={address.addressLine1}
                  onChange={(event: any) => {
                    handleChange(event.target.value, 'addressLine1');
                  }}
                /> */}
                <AutoComplete
                  address={address.addressLine1}
                  handleAddressChange={handleAddressChange}
                  lat={address.lat}
                  lng={address.lng}
                  border={clsx(validation.addressLine1 ? '#e55451' : '')}
                />
              </Col>
              <Col md={6}>
                <FormLabel className="fs-16 fw-500 text-dark">
                  Landmark
                </FormLabel>
                <Form.Control
                  className={clsx(
                    'form-control-custom bg-white',
                    validation.landmark ? 'border-danger' : ''
                  )}
                  value={address.landmark}
                  onChange={(event: any) => {
                    handleChange(event.target.value, 'landmark');
                  }}
                />
              </Col>
              <Col md={6}>
                <FormLabel className="fs-16 fw-500 text-dark">City</FormLabel>
                <Form.Control
                  className={clsx(
                    'form-control-custom bg-white',
                    validation.city ? 'border-danger' : ''
                  )}
                  onChange={(event: any) => {
                    handleChange(event.target.value, 'city');
                  }}
                  value={address.city}
                />
              </Col>
              <Col md={6}>
                <FormLabel className="fs-16 fw-500 text-dark">
                  District
                </FormLabel>
                {/* <Form.Control
                  className={clsx(
                    'form-control-custom bg-white',
                    validation.district ? 'border-danger' : ''
                  )}
                  onChange={(event: any) => {
                    handleChange(event.target.value, 'district');
                  }}
                  value={address.district}
                /> */}
                <CustomSelectWhite
                  border={validation.district ? '#e55451' : ''}
                  disabled={loading}
                  isLoading={fetchLoader}
                  default={districts[0] ? districts[0] : []}
                  options={districts}
                  loadingMessage={'Fetching Data'}
                  value={districts.filter((value: any) => {
                    return address.district == value._id;
                  })}
                  // onMenuScrollToBottom={onMenuScrollToBottom}
                  onChange={(e: any) => handleDistrict(e)}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer className="justify-content-center mb-4 border-top-0">
        <Button
          variant="primary"
          size="lg"
          onClick={handleSaveAddress}
        >
          {!loading && <span className="indicator-label">Add address</span>}
          {loading && (
            <span
              className="indicator-progress"
              style={{ display: 'block' }}
            >
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AddNewAddress;
