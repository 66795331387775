import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './components/forgot-password';
import { CreatePassword } from './components/create-password';
import { Login } from './components/login';
import { AuthLayout } from './AuthLayout';
import VerifyOTP from './components/verify-otp';
import VerifyPassword from './components/VerifyPassword';
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route
        path="login"
        element={<Login />}
      />
      <Route
        index
        element={<Login />}
      />
    </Route>
    <Route
      path="forgot-password"
      element={<ForgotPassword />}
    />
    <Route
      path="create-password"
      element={<CreatePassword />}
    />
    <Route
      path="verify-password"
      element={<VerifyPassword />}
    />
  </Routes>
);
export { AuthPage };
