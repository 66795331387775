import moment from "moment";
import { Card, Col, Row } from "react-bootstrap";
import uploadImg from "../../../../../_admin/assets/media/svg_uTrade/upload-file.svg";

const ViewBusinessCertificate = (props: any) => {
  return (
    <>
      <Card.Body>
        <Row>
          {props.profileData.business.certificates.length ? (
            props.profileData.business.certificates.map(
              (certVal: any, index: number) => {
                return (
                  <>
                    <Col xl={3} md={4} sm={6} className="mb-5">
                      <Row>
                        <Col
                          lg={8}
                          className="fs-16 fw-500 text-black line-clamp-1 mb-3"
                        >
                          {certVal.reference.title}
                          {":"}
                        </Col>
                      </Row>
                      <div className="image-input image-input-outline d-flex flex-center border rounded h-200px">
                        <div
                          className="image-input-wrapper shadow-none bgi-contain bgi-position-center w-100 h-100"
                          style={{
                            backgroundImage: `url(${certVal.document.url})`,
                          }}
                        ></div>
                      </div>
                      {certVal.expiry ? (
                        <div className="fs-16 fw-500 mt-3 text-center">
                          Expires on{" "}
                          {moment(certVal.expiry).format("DD MMM, YYYY")}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </>
                );
              }
            )
          ) : (
            <></>
          )}
          {props.profileData.pendingCertificates &&
          props.profileData.pendingCertificates.length ? (
            props.profileData.pendingCertificates.map(
              (pendVal: any, index: number) => {
                return (
                  <Col xl={3} md={4} sm={6} className="mb-5">
                    <Row>
                      <Col
                        lg={8}
                        className="fs-16 fw-500 text-black line-clamp-1 mb-3"
                      >
                        {pendVal.title}
                      </Col>
                    </Row>
                    <div className="image-input image-input-outline d-flex flex-center border rounded w-lg-200px h-200px">
                      <div className="image-input-wrapper shadow-none bgi-contain mw-175px min-h-175px mh-175px d-flex align-items-center justify-content-center w-200px">
                        <div className="d-flex flex-column align-items-center">
                          <div className="upload-btn-wrapper text-center">
                            <img
                              className="img-fluid mb-3"
                              width={46}
                              height={46}
                              src={uploadImg}
                              alt=""
                            />
                            <div className="fs-15 fw-600">Click to upload</div>
                            <span className="text-gray fs-14 fw-normal fw-500">
                              Maximum file size: 5 MB
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              }
            )
          ) : (
            <></>
          )}
        </Row>
      </Card.Body>
    </>
  );
};

export { ViewBusinessCertificate };
