import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../_admin/assets/media/close.png';
const ProductRejected = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
        contentClassName="p-5"
      >
        <Modal.Header className="border-bottom-0 text-center pb-4 mx-auto">
          <div className="symbol symbol-md-40px symbol-35px close-inner-top">
            <img
              width={40}
              height={40}
              src={CrossSvg}
              alt="closebutton"
              onClick={props.onHide}
            />
          </div>
          <Modal.Title className="fs-26 fw-bolder mw-lg-450px">
            Product details rejected <br className="br" />
            {props.rejectedProduct.variant.title.replace(/\s*\)\s*/g, ')')}
          </Modal.Title>
        </Modal.Header>
        {props.rejectedProduct.variant.statusMessage ? (
          <Modal.Body className="pt-2 mx-auto">
            <div className="w-fit-content">
              <div className="bg-light-danger text-center border-r10px p-4 px-lg-10 px-md-8 px-6">
                <span className="fs-18 fw-500">
                  {' '}
                  {props.rejectedProduct.variant.statusMessage}
                </span>
              </div>
            </div>
          </Modal.Body>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
export default ProductRejected;
