import { Button, Card, Col, FormLabel, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PAGE_LIMIT } from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { buyer } from '../../../api/apiEndPoints';
import Method from '../../../utils/methods';
import Loader from '../../../Global/loader';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { listReceivablePayouts } from '../../../utils/storeString';
import { getKey, removeKey, setKey } from '../../../Global/history';
import Pagination from '../../../Global/pagination';
const ReceivableReport = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [details, setDetails] = useState<any>();
  const [enableSelectAll, setEnableSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<any>(
    getKey(listReceivablePayouts.filterEndDate)
      ? new Date(getKey(listReceivablePayouts.filterEndDate)?.toString() || '')
      : null
  );
  const [startDate, setStartDate] = useState<any>(
    getKey(listReceivablePayouts.filterStartDate)
      ? new Date(
          getKey(listReceivablePayouts.filterStartDate)?.toString() || ''
        )
      : null
  );
  const [fetchLoading, setFetchLoading] = useState(false);
  const [page, setPage] = useState<any>(
    getKey(listReceivablePayouts.page) || 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listReceivablePayouts.limit) || PAGE_LIMIT
  );
  const [search, setSearch] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [receivablePayout, setReceivablePayout] = useState(false);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [customerData, setCustomerData] = useState<any>([]);
  const [totalCustomer, setTotalCustomer] = useState<any>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(
    getKey(listReceivablePayouts.filterCustomer) || ''
  );
  const [fetchCustomerLoader, setFetchCustomerLoader] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      await fetchOrder(page, pageLimit, startDate, endDate, selectedCustomer);
      setFetchLoading(false);
      await fetchCustomer(1, 10);
    })();
  }, []);
  const fetchOrder = async (
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    customer: string
  ) => {
    setLoading(true);
    let params = {
      pageNo: page,
      limit: limit,
      needCount: true,
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      customer: customer,
    };
    let apiService = new APICallService(buyer.receivablePayouts, params);
    let response = await apiService.callAPI();
    if (response) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setPaymentData(response.records);
    }
    setLoading(false);
  };
  const fetchCustomer = async (page: number, limit: number) => {
    setFetchCustomerLoader(true);
    let params = {
      sortKey: 'name',
      sortOrder: 1,
      needCount: page === 1,
      state: 1,
    };
    let apiService = new APICallService(buyer.listBuyer, params);
    let response = await apiService.callAPI();
    let data: any = [...customerData];
    if (page === 1) {
      setTotalCustomer(response.total);
    } else {
      let prevTotal = totalCustomer;
      setTotalCustomer(prevTotal);
    }
    response.records.map((val: any) => {
      data.push(val);
    });
    setCustomerData(data);
    setFetchCustomerLoader(false);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setKey(listReceivablePayouts.page, 1);
    setPage(1);
    if (startDate && endDate) {
      setKey(listReceivablePayouts.filterStartDate, startDate, false);
      setKey(listReceivablePayouts.filterEndDate, endDate, false);
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchOrder(
        page,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        selectedCustomer
      );
      setFetchLoading(false);
    }
    if (!startDate && !endDate) {
      setKey(listReceivablePayouts.filterStartDate, startDate, false);
      setKey(listReceivablePayouts.filterEndDate, endDate, false);
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchOrder(page, pageLimit, '', '', selectedCustomer);
      setFetchLoading(false);
    }
  };
  const handleCustomerChange = async (event: any) => {
    setKey(listReceivablePayouts.page, 1);
    setPage(1);
    if (event) {
      setSelectedCustomer(event.value);
      setFetchLoading(true);
      setTotalRecords(0);
      setKey(listReceivablePayouts.filterCustomer, event.value, false);
      await fetchOrder(
        page,
        pageLimit,
        startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
        endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
        event.value
      );
      setFetchLoading(false);
    } else {
      setSelectedCustomer('');
      setFetchLoading(true);
      setTotalRecords(0);
      removeKey(listReceivablePayouts.filterCustomer);
      await fetchOrder(
        page,
        pageLimit,
        startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
        endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
        ''
      );
      setFetchLoading(false);
    }
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listReceivablePayouts.page, val);
    await fetchOrder(
      val,
      pageLimit,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listReceivablePayouts.page, val + 1);
    await fetchOrder(
      val + 1,
      pageLimit,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listReceivablePayouts.page, val - 1);
    await fetchOrder(
      val - 1,
      pageLimit,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listReceivablePayouts.page, 1);
    setKey(listReceivablePayouts.limit, parseInt(event.target.value));
    await fetchOrder(
      1,
      event.target.value,
      startDate ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD') : '',
      endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      selectedCustomer
    );
  };
  const handleSelectAll = async () => {
    if (selected) {
      setSelectLoading(true);
      setSelectedId([]);
      setSelectedData([]);
      setSelected(!selected);
      setSelectLoading(false);
    } else {
      setSelectLoading(true);
      setSelected(!selected);
      let params: any = {
        fromDate: startDate ? startDate : '',
        toDate: endDate ? endDate : '',
        customer: selectedCustomer ? selectedCustomer : '',
      };
      let apiService = new APICallService(buyer.receivablePayouts, params);
      let response: any = await apiService.callAPI();
      let temp: any = [...selectedId];
      let tempData = [...selectedData];
      if (response.records) {
        response.records.map((val: any) => {
          temp.push(val._id);
          tempData.push(val);
        });
      }
      setSelectedData(tempData);
      setSelectedId(temp);
      setSelectLoading(false);
    }
  };
  const handleChecked = (id: string, data: any) => {
    let temp = [...selectedId];
    let tempData = [...selectedData];
    if (temp.length) {
      if (temp.includes(id)) {
        temp = temp.filter((item) => item != id);
        tempData = tempData.filter((item) => item._id != id);
      } else {
        temp.push(id);
        tempData.push(data);
      }
    } else {
      temp.push(id);
      tempData.push(data);
    }
    setSelectedData(tempData);
    setSelectedId(temp);
    setSelected(temp.length == totalRecords);
  };
  return (
    <>
      <div className="p-9">
        <Row className="g-4">
          <Col md>
            <div className="d-flex align-items-center mt-4">
              <h1 className="fs-22 fw-bolder">Receivable Report</h1>
            </div>
          </Col>
          {paymentData.length ? (
            <Col
              md="auto"
              className="ms-2 "
            >
              <div className="d-flex align-items-center mt-2">
                {enableSelectAll ? (
                  <Button
                    variant="light-primary"
                    className="fs-18 fw-600 btn-lg me-4"
                    // onClick={() => setDeliveryRoutesPlanningModal(true)}
                    onClick={() => {
                      setSelectedId([]);
                      setSelected(!selected);
                      setEnableSelectAll(false);
                      setSelectLoading(false);
                      setSelected(false);
                      setSelectedData([]);
                    }}
                    disabled={selectLoading}
                  >
                    Cancel
                  </Button>
                ) : (
                  <></>
                )}
                {selectedId.length ? (
                  <Button
                    variant="primary"
                    className="fs-16 fw-bold btn-lg"
                    onClick={() => {
                      navigate('/customers/receivable-report/collect-payment', {
                        state: {
                          data: selectedData,
                        },
                      });
                    }}
                  >
                    Mark as collected for {selectedId.length} order
                  </Button>
                ) : (
                  <>
                    <Button
                      type="button"
                      variant="primary"
                      onClick={() => setEnableSelectAll(true)}
                    >
                      Mark as collected
                    </Button>
                  </>
                )}
              </div>
            </Col>
          ) : (
            <></>
          )}
          <Col xs={12}>
            <Card className="bg-light border mb-7">
              <Card.Body className="px-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center mw-lg-515px   position-relative me-lg-4">
                      <FormLabel className="fs-16 fw-500">
                        Filter by customer
                      </FormLabel>
                      <div className="ms-5">
                        <CustomSelect
                          disabled={fetchCustomerLoader}
                          isClearable={true}
                          backgroundColor="#ffff"
                          options={
                            customerData &&
                            customerData.map((val: any) => ({
                              label: (
                                <>
                                  <div className="symbol symbol-30px border me-3">
                                    <img
                                      src={val.business.image}
                                      className="object-fit-contain"
                                      alt=""
                                    />
                                  </div>
                                  <span className="fs-16 fw-600 text-black mb-0">
                                    {val.name}
                                  </span>
                                </>
                              ),
                              title: val.name,
                              value: val._id,
                            }))
                          }
                          value={
                            customerData && customerData.length
                              ? customerData
                                  .filter(
                                    (item: any) => item._id === selectedCustomer
                                  )
                                  .map((val: any) => ({
                                    label: (
                                      <>
                                        <div className="symbol symbol-30px border me-3">
                                          <img
                                            src={val.business.image}
                                            className="object-fit-contain"
                                            alt=""
                                          />
                                        </div>
                                        <span className="fs-16 fw-600 text-black mb-0">
                                          {val.name}
                                        </span>
                                      </>
                                    ),
                                    title: val.name,
                                    value: val._id,
                                  }))
                              : null // Set to null when no match is found
                          }
                          onChange={(event: any) => {
                            handleCustomerChange(event);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="auto">
                    <div className="d-flex align-items-center">
                      <FormLabel className="fs-16 fw-500">
                        Filter by dates
                      </FormLabel>
                      <div className="ms-5">
                        <CustomDatePicker
                          className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                          onChange={handleChange}
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          showFullMonthYearPicker={true}
                          maxDate={new Date()}
                          isClearable={true}
                          inputTextBG="bg-white"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card className="border border-r10px mt-8">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 my-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    {enableSelectAll ? (
                      <th
                        className="min-w-200px"
                        tabIndex={0}
                        rowSpan={1}
                        colSpan={1}
                      >
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input h-30px w-30px"
                            type="checkbox"
                            value="1"
                            checked={
                              selectLoading ||
                              (selectedId.length == totalRecords && selected)
                            }
                            onChange={(event: any) => {
                              handleSelectAll();
                            }}
                          />
                          <label className="form-check-label fs-16 fw-600 text-dark">
                            Select all
                          </label>
                        </div>
                      </th>
                    ) : (
                      <th className="min-w-md-175px min-w-200px">
                        Order Date & ID
                      </th>
                    )}
                    <th className="min-w-150px">Customer Name</th>
                    <th className="w-150px">Total Amount</th>
                    <th className="w-250px">Paid Amount</th>
                    <th className="w-350px">Remaining Amount</th>
                    <th className="w-150px"></th>
                  </tr>
                </thead>
                <tbody className="fs-15 fw-600">
                  {fetchLoading || loading ? (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={fetchLoading || loading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {selectLoading ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="d-flex justify-content-center text-center align-items-center min-h-160px px-3 fs-20 text-dark fw-600">
                              Please wait while processing...
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {paymentData.length ? (
                            <>
                              {paymentData.map((val: any, index: number) => (
                                <>
                                  <tr key={index}>
                                    {enableSelectAll ? (
                                      <td>
                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                          <input
                                            className="form-check-input h-30px w-30px"
                                            type="checkbox"
                                            value={val._id}
                                            checked={selectedId.includes(
                                              val._id
                                            )}
                                            onChange={() => {
                                              handleChecked(val._id, val);
                                            }}
                                          />
                                          <div className="d-flex align-items-start flex-column ms-2">
                                            <div className="d-flex flex-row">
                                              <span className="text-dark d-block">
                                                {Method.convertDateToDDMMYYYYHHMM(
                                                  val._createdAt,
                                                  '-'
                                                )}
                                              </span>
                                            </div>
                                            <span className="text-gray d-block">
                                              {val.refKey}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    ) : (
                                      <td className="fs-15 fw-500">
                                        <div className="d-flex align-items-start flex-column">
                                          <div className="d-flex flex-row">
                                            <span className="text-dark d-block">
                                              {Method.convertDateToDDMMYYYYHHMM(
                                                val._createdAt,
                                                '-'
                                              )}
                                            </span>
                                          </div>
                                          <span className="text-gray d-block">
                                            {val.refKey}
                                          </span>
                                        </div>
                                      </td>
                                    )}
                                    <td>
                                      {' '}
                                      <span className="fs-15 fw-500 d-block">
                                        {' '}
                                        {val.customer.name}{' '}
                                      </span>
                                    </td>
                                    <td>
                                      {' '}
                                      <span className="fs-15 fw-600 d-block">
                                        {'TSh ' +
                                          Method.getGeneralizedAmount(
                                            val.payment.totalCharge
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-600 d-block ">
                                        {'TSh ' +
                                          Method.getGeneralizedAmount(
                                            val.payment.receivedCharge
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-600 d-block text-danger">
                                        {'TSh ' +
                                          Method.getGeneralizedAmount(
                                            val.payment.remainingCharge
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        type="button"
                                        variant="primary"
                                        className="btn-table"
                                        onClick={() =>
                                          navigate('/orders/order-details', {
                                            state: val._id,
                                          })
                                        }
                                      >
                                        View Details
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                  No Data found
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!fetchLoading && paymentData.length ? (
          <Pagination
            totalRecords={totalRecords}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            pageLimit={pageLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default ReceivableReport;
