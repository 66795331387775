import clsx from "clsx";
import { Children } from "react";
import { Button } from "react-bootstrap";

const Notification2 = (props: any) => {
   const handleShow = () => {
     if (props.onClick) {
       props.onClick();
     }
   };
  return (
    <>
      <div
        className={`notice d-flex align-items-md-center ${
          props.bgColor ? props.bgColor : 'bg-fbe5e5'
        } min-w-lg-600px flex-shrink-0 p-9 py-5`}
      >
        {props.icon === true ? (
          <div className="h-20px w-20px me-3 mt-md-0 mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <g data-name="Layer 35">
                <path
                  data-name="Path 16426"
                  d="M13.4 3.4a10 10 0 1 0 10 10 10 10 0 0 0-10-10zm0 17.2a1.511 1.511 0 1 1 1.507-1.5 1.511 1.511 0 0 1-1.507 1.5zm1.829-12.445-.57 7.692a.21.21 0 0 1-.21.2h-2.1a.21.21 0 0 1-.21-.2l-.57-7.692a1.832 1.832 0 1 1 3.657 0z"
                  transform="translate(-3.4 -3.4)"
                  style={{ fill: props.iconColor }}
                />
              </g>
            </svg>
          </div>
        ) : (
          ''
        )}
        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
          <span className={clsx('fs-16 fw-600', props.textColor)}>
            {props.text}
          </span>
          {props.click ? (
            <>
              {' '}
              <Button
                variant="link"
                className="fs-16 fw-bolder text-primary btn-flush align-self-center text-nowrap"
                onClick={handleShow}
              >
                {props.clickText}
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification2;
