import { Button, Card, Col, Row } from "react-bootstrap";
import logo from "../../../../_admin/assets/media/uTradeLogo.png";
import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="p-9 bg-white h-50">
        <Row className="align-items-center">
          <Col xs>
            <img height={40} src={logo} alt="" />
          </Col>
          <Col xs="auto">
            <Button
              variant="link"
              className="btn-flush text-primary fs-18 fw-600"
              onClick={() => navigate("/auth")}
            >
              Sing In
            </Button>
          </Col>
          <Col xs={12} className="mt-15 mt-md-5 text-center">
            <h3 className="fs-26 fw-bolder">Privacy policy.</h3>
          </Col>
        </Row>

        <div className="mt-15">
          <div className="position-absolute start-0 end-0 w-100 px-18">
            <Card className="p-5 bg-light-primary">
              <Card.Body className="fs-18 fw-500 text-dark">
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Quibusdam eum, ratione sequi commodi numquam officia
                  architecto accusamus alias voluptatum facere eligendi earum
                  ullam? Non veniam error repellat repellendus modi odio id aut
                  doloribus, harum corporis laboriosam dignissimos tempora
                  expedita beatae nemo ad, esse facere vero officiis iste
                  exercitationem quasi unde.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea
                  maxime delectus culpa facilis aspernatur provident. Animi
                  deleniti dolor illo repellendus voluptas incidunt nostrum quod
                  odio. Debitis, praesentium fuga. Nisi, nemo.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Tenetur repudiandae consequatur deserunt inventore rem, beatae
                  soluta deleniti nam voluptatem, alias similique incidunt, quas
                  ipsum laborum reprehenderit? Doloribus iure accusantium, id at
                  animi modi? Reiciendis sed officiis aliquam voluptate omnis
                  odit tempore eveniet magni quasi ad? Repellendus consectetur
                  aspernatur alias voluptate eius, quidem rem excepturi nisi ad,
                  sapiente, voluptatum non voluptates?
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
