import Modal from "react-bootstrap/Modal";
import CrossSvg from "../../_admin/assets/media/close.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const ManagerAlreadyAssigned = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-5">
            A manager is already assigned to the{" "}
            {props.managerData.districtName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-6">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="bg-light-danger text-center border-r10px p-3">
                <span className="fs-18 fw-500">
                  By continuing, the manager of the{" "}
                  {props.managerData.districtName} will be removed.
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pt-1 py-8 mb-4 border-top-0">
          <Button
            variant="danger"
            className="btn-lg fs-16 fw-600"
            onClick={() =>
              props.onSave(
                props.managerData.event,
                props.managerData.districtId,
                props.managerData.index,
                props.managerData.userId,
                props.managerData.districtName,
                props.managerData.assignedId
              )
            }
          >
            Okay, remove manager
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ManagerAlreadyAssigned;
