import { FC } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_admin/helpers';
// import Logo from 'src\_admin\assets\media\uTradeLogo.png'
import Logo from '../../../../_admin/assets/media/uTradeLogo.png';
const PaymentSuccess: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <img
        src={Logo}
        alt="UTrade_Logo"
        className="logo h-60px mb-6"
      ></img>
      <h1 className="fw-bolder fs-1hx text-success mb-4">Payment Successful</h1>
      {/* end::Title */}
      {/* begin::Text */}
      <div className="fw-semibold fs-15 text-dark-500 mb-7">
        Thank you for your payment! Your transaction was successful.
      </div>
      {/* end::Text */}
      {/* begin::Illustration */}
      <div className="fw-semibold fs-15 text-dark-500 mb-7">
        We appreciate your business.
      </div>
      <div className="fw-semibold fs-15 text-dark-500 mb-7">
        If you have any questions, please contact our customer support.
      </div>
      {/* end::Illustration */}
      {/* begin::Link */}
    </>
  );
};
export { PaymentSuccess };
