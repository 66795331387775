import { Outlet, Route, Routes } from "react-router-dom";
import MyTeam from "./my-team";
import MemberDetails from "./member-details";
const MyTeamIndex = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route index element={<MyTeam />} />
      <Route
        path="/member-details"
        element={
          <>
            <MemberDetails />
          </>
        }
      />
    </Route>
  </Routes>
);
export default MyTeamIndex;
