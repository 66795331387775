import { Button } from 'react-bootstrap';
import { KTSVG } from '../../../../_admin/helpers';
import { useEffect, useState } from 'react';
import ViewInquiries from '../../../modals/view-inquiries';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import Pagination from '../../../../Global/pagination';
const BuyerContact = (props: any) => {
  const [showInquiries, setShowInquiries] = useState(false);
  const [inquiryData, setInquiryData] = useState();
  const [inquiries, setInquiries] = useState<any>();
  const [search, setSearch] = useState<string>('');
  const handleInquiryShow = (data: any) => {
    setInquiryData(data);
    setShowInquiries(true);
  };
  const handleClose = () => {
    setShowInquiries(!showInquiries);
    setInquiryData(undefined);
  };
  useEffect(() => {
    setInquiries(props.inquiries);
  }, [props]);
  return (
    <>
      {showInquiries ? (
        <ViewInquiries
          show={showInquiries}
          onHide={() => handleClose()}
          inquiryData={inquiryData}
        />
      ) : (
        <></>
      )}
      <div className="position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
        />
        <input
          type="text"
          className="form-control form-control-custom borde-r8px bg-light w-375px ps-11"
          name="Search Team"
          onChange={(event: any) => {
            props.handleSearch(event.target.value.trimStart(), '2');
          }}
          placeholder="Search by buyer name…"
        />
      </div>
      {/* end::Search */}
      <div className="table-responsive">
        <table className="table table-rounded table-row-bordered align-middle gy-4">
          <thead>
            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
              <th className="min-w-250px">Name</th>
              <th className="min-w-150px">Contact</th>
              <th className="min-w-150px">Received on</th>
              <th className="min-w-180px"></th>
            </tr>
          </thead>
          <tbody>
            {props.fetchLoading ? (
              <>
                <td colSpan={3}>
                  <div className="w-100 d-flex justify-content-center">
                    <Loader loading={props.fetchLoading} />
                  </div>
                </td>
              </>
            ) : (
              <>
                {inquiries !== undefined && inquiries.length ? (
                  inquiries.map((inquiryVal: any, inquiryIndex: number) => {
                    return (
                      <tr>
                        <td>
                          <span className="fs-15 fw-500">
                            {inquiryVal.user
                              ? inquiryVal.user.name
                              : inquiryVal.name
                              ? inquiryVal.name
                              : '-'}
                          </span>
                        </td>
                        <td>
                          <span className="fs-15 fw-500">
                            {inquiryVal.user
                              ? inquiryVal.user.phoneCountry +
                                ' ' +
                                inquiryVal.user.phone
                              : '-'}
                          </span>
                        </td>
                        <td>
                          <span className="fs-15 fw-500">
                            {Method.convertDateToDDMMYYYYHOURS(
                              inquiryVal._createdAt
                            )}
                          </span>
                        </td>
                        <td className="text-end">
                          <Button
                            onClick={() => handleInquiryShow(inquiryVal)}
                            variant="primary"
                            className="btn-md fs-14 fw-600"
                          >
                            View message{' '}
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                          No Data found
                        </div>
                      </td>
                    </tr>
                  </>
                )}{' '}
              </>
            )}
          </tbody>
        </table>
      </div>
      {props.fetchLoading ? (
        <></>
      ) : (
        <>
          {inquiries !== undefined && inquiries.length ? (
            <>
              <Pagination
                totalRecords={props.total}
                currentPage={props.currentPage}
                handleCurrentPage={(event: any) => {
                  props.handleCurrentPage(event, '2');
                }}
                handleNextPage={(event: any) => {
                  props.handleNextPage(event, '2');
                }}
                handlePreviousPage={(event: any) => {
                  props.handlePreviousPage(event, '2');
                }}
                pageLimit={parseInt(props.pageLimit)}
                handlePageLimit={(event: any) => {
                  props.handlePageLimit(event, '2');
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default BuyerContact;
