import { IListInquires } from "../../types";
export const inquiresJSON = {
  listInquires: ({
    pageNo,
    limit,
    sortKey,
    sortOrder,
    searchTerm,
  }: IListInquires) => {
    return {
      pageNo: pageNo,
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      searchTerm: searchTerm,
    };
  },
};
