import { Form, Button, Modal } from "react-bootstrap";
import CrossSvg from "../../_admin/assets/media/close.png";

const RejectBusiness = (props: any) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-590px"
        className="border-r10px"
        contentClassName="p-5"
      >
        <Modal.Header className="border-bottom-0 pb-6 text-center mx-auto">
          <div className="symbol symbol-md-40px symbol-35px close-inner-top">
            <img
              width={40}
              height={40}
              src={CrossSvg}
              alt="closebutton"
              onClick={props.onHide}
            />
          </div>
          <Modal.Title className="fs-26 fw-bolder mw-lg-350px">
            Are you sure you want to reject {props.name}?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-0 text-center">
          <p className="fs-18 mb-6 fw-500">
            The seller won’t be able to use the platform.
          </p>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              className="form-control-custom"
              as="textarea"
              rows={5}
              placeholder="Please type the the reason…"
              onChange={props.onMessageChange}
              value={props.message}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center pt-1 border-top-0">
          <Button
            variant="danger"
            size="lg"
            onClick={props.onSave}
            disabled={!props.message.trimStart().length}
          >
            {props.loading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              "Yes, Reject this business"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RejectBusiness;
