import { useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { KTSVG } from '../../../_admin/helpers';
import { CustomSelectWhite } from '../../custom/Select/CustomSelectWhite';
import p2 from '../../../_admin/assets/media/document2.png';
import p1 from '../../../_admin/assets/media/product/nutro.png';
import gray from '../../../_admin/assets/media/svg_uTrade/gray_dot.svg';
import green from '../../../_admin/assets/media/svg_uTrade/green_dot.svg';
import ProductRejected from '../../modals/product-rejected';
import {
  Actived,
  AllProduct,
  ApprovedProduct,
  Deactivated,
  FinanceManager,
  LoadingBayManager,
  PAGE_LIMIT,
  PendingProduct,
  RejectedProduct,
} from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { manageProduct, master, product } from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import { useNavigate } from 'react-router-dom';
import Method from '../../../utils/methods';
import { productJSON } from '../../../api/apiJSON/product';
import { CustomComponentSelect } from '../../custom/Select/CustomComponentSelect';
import Pagination from '../../../Global/pagination';
import { useAuth } from '../auth';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { listAllProducts } from '../../../utils/storeString';
import { getKey, setKey } from '../../../Global/history';
import AllProductsReport from '../../modals/report/all-products-report';
import ProductPriceListReport from '../../modals/report/all-products-price-list-report';
const AllProducts = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [active, setActive] = useState<any>(
    getKey(listAllProducts.currentTab)
      ? () => {
          let temp: any = getKey(listAllProducts.currentTab);
          return parseInt(temp);
        }
      : currentUser?.role.some(
          (obj: any) =>
            obj.type === FinanceManager || obj.type === LoadingBayManager
        )
      ? ApprovedProduct
      : PendingProduct
  );
  const [filterProducts, setFilterProducts] = useState([
    {
      value: 'Food',
      name: 'Food',
      label: (
        <>
          <div className="form-check form-check-custom form-check-solid form-check-lg">
            <input
              className="form-check-input me-4"
              type="checkbox"
              value=""
              id="Ex1"
            />
            <div className="symbol symbol-40px symbol-circle border me-2">
              <img
                src={p1}
                className="object-fit-contain"
                alt=""
              />
            </div>
            <label
              className="form-check-label fs-16 fw-600 text-dark"
              htmlFor="Ex1"
            >
              Food
            </label>
          </div>
        </>
      ),
    },
    {
      value: 'Food',
      name: 'Food',
      label: (
        <>
          <div className="form-check form-check-custom form-check-solid form-check-lg">
            <input
              className="form-check-input me-4"
              type="checkbox"
              value=""
              id="Ex2"
            />
            <div className="symbol symbol-40px symbol-circle border me-2">
              <img
                src={p2}
                className="object-fit-contain"
                alt=""
              />
            </div>
            <label
              className="form-check-label fs-16 fw-600 text-dark"
              htmlFor="Ex2"
            >
              Grocery & Gourmet Food
            </label>
          </div>
        </>
      ),
    },
  ]);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(getKey(listAllProducts.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listAllProducts.limit) || PAGE_LIMIT
  );
  const [categories, setCategories] = useState<any>([]);
  const [rejectedProduct, setRejectedProduct] = useState<any>({});
  const [productState, setProductState] = useState(AllProduct);
  const [search, setSearch] = useState<string>('');
  const productOptions = [
    {
      value: AllProduct,
      name: 'All products',
      label: (
        <>
          <span className="fs-16 fw-600 text-black mb-0">All products</span>
        </>
      ),
      title: 'All products',
    },
    {
      value: Actived,
      name: 'Active',
      label: (
        <>
          <img
            src={green}
            width={12}
            height={12}
            className="me-3"
            alt=""
          />
          <span className="fs-16 fw-600 text-black ">Active</span>
        </>
      ),
      title: 'Active',
    },
    {
      value: Deactivated,
      name: 'Deactivated',
      label: (
        <>
          <img
            src={gray}
            width={12}
            height={12}
            className="me-3"
            alt=""
          />
          <span className="fs-16 fw-600 text-black">Deactivated</span>
        </>
      ),
      title: 'Deactivated',
    },
  ];
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDownloadPriceListModal, setShowDownloadPriceListModal] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      if (getKey(listAllProducts.currentTab)) {
        let activeTemp: any = getKey(listAllProducts.currentTab);
        await fetchProducts(
          page,
          pageLimit,
          productState,
          categories,
          search,
          parseInt(activeTemp)
        );
      } else {
        setActive(
          currentUser?.role.some(
            (obj: any) =>
              obj.type === FinanceManager || obj.type === LoadingBayManager
          )
            ? ApprovedProduct
            : PendingProduct
        );
        await fetchProducts(
          page,
          pageLimit,
          productState,
          categories,
          search,
          active
        );
      }
      await fetchCategory();
      setFetchLoader(false);
    })();
  }, []);
  const fetchCategory = async () => {
    let apiService = new APICallService(master.categoryList, {
      categoriesDepth: 1,
    });
    let response = await apiService.callAPI();
    if (response.records) {
      let temp: any = [];
      response.records.map((val: any) => {
        temp.push({
          value: val.title,
          name: val.title,
          title: val.title,
          id: val._id,
          label: (
            <>
              <div className="symbol symbol-40px symbol-circle border me-2">
                <img
                  src={val.image}
                  className="object-fit-contain"
                  alt=""
                />
              </div>
              <label
                className="form-check-label truncate-2 fs-16 fw-600 text-dark"
                htmlFor="Ex2"
              >
                {val.title}
              </label>
            </>
          ),
        });
      });
      setFilterProducts(temp);
    }
  };
  const fetchProducts = async (
    pageNo: number,
    limit: number,
    state: number,
    categories: any,
    search: string,
    status?: number
  ) => {
    setLoading(true);
    let data = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      searchTerm: search,
      state: state,
      needCount: true,
      status: status,
    };
    if (categories.length > 0) {
      categories.map((val: any, index: number) => {
        data = { ...data, ['categories[' + index + ']']: val.id };
      });
    }
    let apiService = new APICallService(manageProduct.listProduct, data);
    let response = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setProducts(response.records);
    } else {
      setProducts([]);
    }
    setLoading(false);
  };
  const handleActive = async (event: any) => {
    setActive(event);
    setKey(listAllProducts.currentTab, parseInt(event));
    setKey(listAllProducts.page, 1);
    setPage(1);
    setTotalRecords(0);
    await fetchProducts(1, pageLimit, productState, categories, search, event);
    setLoading(false);
  };
  const handleVariationShow = (variantionData: any, index: number) => {
    let temp: any = [...products];
    temp[index].showVariant = !temp[index].showVariant;
    setProducts(temp);
  };
  const updateProductState = async (
    state: boolean,
    id: string,
    isProduct: boolean,
    productIndex: number,
    variantIndex: number
  ) => {
    let temp = [...products];
    let apiService = new APICallService(
      product.updateProductState,
      productJSON.updateProductState({ activate: !state }),
      {
        id: id,
      }
    );
    let response = await apiService.callAPI();
    if (response) {
      temp[productIndex].variants[variantIndex].variant.active = !state;
    }
    if (productState !== AllProduct) {
      temp.splice(productIndex, 1);
    }
    setProducts(temp);
  };
  const handleCategoryFilter = async (event: any) => {
    setCategories(event);
    setKey(listAllProducts.page, 1);
    setPage(1);
    await fetchProducts(1, pageLimit, productState, event, search, active);
  };
  const handleProductStateChange = async (event: any) => {
    setKey(listAllProducts.page, 1);
    setPage(1);
    await fetchProducts(1, pageLimit, event.value, categories, search, active);
    setProductState(event.value);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setKey(listAllProducts.page, 1);
    setPage(1);
    await fetchProducts(1, pageLimit, productState, categories, value, active);
  };
  const rejectModal = (value: any) => {
    setRejectedProduct(value);
    setShowRejectModal(true);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listAllProducts.page, val);
    await fetchProducts(
      val,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listAllProducts.page, val + 1);
    await fetchProducts(
      val + 1,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listAllProducts.page, val - 1);
    await fetchProducts(
      val - 1,
      pageLimit,
      productState,
      categories,
      search,
      active
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    setKey(listAllProducts.page, 1);
    setKey(listAllProducts.limit, parseInt(event.target.value));
    await fetchProducts(
      1,
      event.target.value,
      productState,
      categories,
      search,
      active
    );
  };
  const handleViewDetails = async (currentId: string) => {
    let ids: any = [];
    if (active === PendingProduct) {
      if (products.length) {
        products.map((productVal: any) => {
          productVal.variants.map((variantVal: any) => {
            ids.push(variantVal.variant._id);
          });
        });
      }
      // Check if currentId exists in ids array
      const currentIdIndex = ids.indexOf(currentId);
      if (currentIdIndex !== -1) {
        // Remove currentId from its current position
        ids.splice(currentIdIndex, 1);
        // Add currentId to the start of the array
        ids.unshift(currentId);
      }
      navigate('/all-products/product-details', {
        state: { ids: ids, status: active },
      });
    } else {
      navigate('/all-products/product-details', {
        state: currentId,
      });
    }
  };
  return (
    <div className="p-9">
      {showRejectModal ? (
        <ProductRejected
          show={showRejectModal}
          onHide={() => setShowRejectModal(false)}
          rejectedProduct={rejectedProduct}
        />
      ) : (
        <></>
      )}
      {showDownloadModal ? (
        <AllProductsReport
          show={showDownloadModal}
          onHide={() => setShowDownloadModal(false)}
        />
      ) : (
        <></>
      )}
      {showDownloadPriceListModal ? (
        <ProductPriceListReport
          show={showDownloadPriceListModal}
          onHide={() => setShowDownloadPriceListModal(false)}
        />
      ) : (
        <></>
      )}
      <Row className="align-items-center">
        <Col
          xs
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">All products</h1>
        </Col>
        {currentUser?.role.length === 0 ||
        currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
          <Col xs="auto">
            <Button
              size="lg"
              className="mb-5"
              onClick={() => setShowDownloadPriceListModal(true)}
            >
              Download Product Price List
            </Button>
          </Col>
        ) : (
          <></>
        )}
        {currentUser?.role.length === 0 ||
        currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
          <Col xs="auto">
            <Button
              size="lg"
              className="mb-5"
              onClick={() => setShowDownloadModal(true)}
            >
              Download report
            </Button>
          </Col>
        ) : (
          <></>
        )}

        <Col xs={12}>
          <Card className="bg-light border mb-7">
            <Card.Body className="px-7">
              <Row className="align-items-center">
                <Col
                  xl={4}
                  lg={12}
                  className="mb-xl-0 mb-5"
                >
                  <div className="d-flex align-items-center position-relative me-lg-4">
                    <KTSVG
                      path="/media/icons/duotune/general/gen021.svg"
                      className="svg-icon-3 position-absolute ms-3"
                    />
                    <input
                      type="text"
                      id="kt_filter_search"
                      className="form-control form-control-white min-h-60px form-control-lg ps-10"
                      placeholder="Search by product name…"
                      onChange={(event: any) => {
                        handleSearch(event.target.value.trimStart());
                      }}
                      value={search}
                    />
                  </div>
                </Col>
                <Col xl={8}>
                  <Row className="align-items-center justify-content-xl-end">
                    <Col
                      md="auto"
                      className="mb-md-0 mb-5"
                    >
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Filter products
                          </label>
                        </Col>
                        <Col xs>
                          <div className="min-w-lg-250px">
                            <CustomComponentSelect
                              // onChange={(options: any) => {
                              //   if (Array.isArray(options)) {
                              //   }
                              // }}
                              isDisabled={loading}
                              hideSelectedOptions={false}
                              options={filterProducts}
                              onChange={(event: any) => {
                                handleCategoryFilter(event);
                              }}
                              isMulti={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {active === ApprovedProduct ? (
                      <Col md="auto">
                        <div className="min-w-lg-150px">
                          <CustomSelect
                            backgroundColor="white"
                            defaultValue={[
                              {
                                value: AllProduct,
                                name: 'All products',
                                label: (
                                  <>
                                    <span className="fs-16 fw-600 text-black mb-0">
                                      All products
                                    </span>
                                  </>
                                ),
                                title: 'All products',
                              },
                            ]}
                            isDisabled={loading}
                            options={productOptions}
                            onChange={(event: any) => {
                              handleProductStateChange(event);
                            }}
                            isMulti={false}
                          />
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <div className="custom-tabContainer">
            <Tab.Container defaultActiveKey={active}>
              <Row className="variant-categories">
                {currentUser?.role.some(
                  (obj: any) =>
                    obj.type === FinanceManager ||
                    obj.type === LoadingBayManager
                ) ? (
                  <></>
                ) : (
                  <Col
                    lg={'auto'}
                    className="mb-6"
                  >
                    <div className="bg-light border border-r8px p-3 px-md-6">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey={PendingProduct}
                            onClick={() => {
                              handleActive(PendingProduct);
                            }}
                            active={active === PendingProduct}
                          >
                            Pending{' '}
                            {active === PendingProduct
                              ? totalRecords > 0
                                ? '(' + totalRecords + ')'
                                : ''
                              : ''}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={ApprovedProduct}
                            onClick={() => {
                              handleActive(ApprovedProduct);
                            }}
                            active={active === ApprovedProduct}
                          >
                            Approved{' '}
                            {active === ApprovedProduct
                              ? totalRecords > 0
                                ? '(' + totalRecords + ')'
                                : ''
                              : ''}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey={RejectedProduct}
                            onClick={() => {
                              handleActive(RejectedProduct);
                            }}
                            active={active === RejectedProduct}
                          >
                            Rejected{' '}
                            {active === RejectedProduct
                              ? totalRecords > 0
                                ? '(' + totalRecords + ')'
                                : ''
                              : ''}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <Tab.Content>
                    <>
                      <Tab.Pane active={true}>
                        <Card className="border border-r10px">
                          <Card.Body className="p-0">
                            <div className="table-responsive">
                              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                <thead>
                                  <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                    <th className="w-250px">Product name</th>
                                    <th className="min-w-175px">
                                      Business name
                                    </th>
                                    <th className="min-w-175px">
                                      {active === PendingProduct
                                        ? 'Added on'
                                        : ''}
                                      {active === ApprovedProduct
                                        ? 'Approved on'
                                        : ''}
                                      {active === RejectedProduct
                                        ? 'Rejected on'
                                        : ''}
                                    </th>
                                    {active === ApprovedProduct ? (
                                      <th className="min-w-50px ">
                                        Activate / <br /> Deactivate
                                      </th>
                                    ) : null}
                                    <th className="w-225px text-end"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading ? (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="w-100 d-flex justify-content-center">
                                          <Loader loading={loading} />
                                        </div>
                                      </td>
                                    </tr>
                                  ) : products.length ? (
                                    products.map(
                                      (productVal: any, index: number) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                <></>
                                                <div className="d-flex align-items-center">
                                                  <div className="symbol symbol-50px border me-5">
                                                    <img
                                                      src={
                                                        productVal.variants[0]
                                                          .variant.media[0].url
                                                      }
                                                      className="object-fit-contain"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="fs-15 fw-600 mw-lg-175px">
                                                    {productVal.title.replace(
                                                      /\s*\)\s*/g,
                                                      ')'
                                                    )}
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                {productVal.variants.length &&
                                                productVal.variants[0].variant[
                                                  'variantType'
                                                ][0] != undefined ? (
                                                  <span className="fs-20 fw-600">
                                                    -
                                                  </span>
                                                ) : (
                                                  <>
                                                    <span className="fs-15 fw-600">
                                                      {
                                                        productVal.variants[0]
                                                          .variant.product
                                                          .business.name
                                                      }
                                                    </span>
                                                  </>
                                                )}
                                              </td>
                                              <td>
                                                {productVal.variants.length &&
                                                productVal.variants[0].variant[
                                                  'variantType'
                                                ][0] != undefined ? (
                                                  <span className="d-block fs-15 fw-600 text-dark">
                                                    -
                                                  </span>
                                                ) : (
                                                  <>
                                                    {active ===
                                                    ApprovedProduct ? (
                                                      <span className="d-block fs-15 fw-600 text-dark">
                                                        {Method.convertDateToDDMMYYYYHOURS(
                                                          productVal.variants[0]
                                                            .variant
                                                            .approvalInfo.date
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {active ===
                                                    PendingProduct ? (
                                                      <span className="d-block fs-15 fw-600 text-dark">
                                                        {Method.convertDateToDDMMYYYYHOURS(
                                                          productVal.variants[0]
                                                            .variant._createdAt
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {active ===
                                                    RejectedProduct ? (
                                                      <span className="d-block fs-15 fw-600 text-dark">
                                                        {Method.convertDateToDDMMYYYYHOURS(
                                                          productVal.variants[0]
                                                            .variant
                                                            .rejectionInfo.date
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </td>
                                              {active === ApprovedProduct ? (
                                                <td>
                                                  {productVal.variants.length &&
                                                  productVal.variants[0]
                                                    .variant[
                                                    'variantType'
                                                  ][0] != undefined ? (
                                                    <span className="fs-20 fw-600">
                                                      -
                                                    </span>
                                                  ) : (
                                                    <label className="form-check form-switch form-check-custom form-check-solid ">
                                                      <input
                                                        className="form-check-input form-check-success w-50px h-30px"
                                                        type="checkbox"
                                                        name="notifications"
                                                        value={
                                                          productVal.variants[0]
                                                            .variant['_id']
                                                        }
                                                        disabled={currentUser?.role.some(
                                                          (obj: any) =>
                                                            obj.type ===
                                                            LoadingBayManager
                                                        )}
                                                        checked={
                                                          productVal.variants[0]
                                                            .variant.active
                                                        }
                                                        onChange={() => {
                                                          updateProductState(
                                                            productVal
                                                              .variants[0]
                                                              .variant.active,
                                                            productVal
                                                              .variants[0]
                                                              .variant['_id'],
                                                            true,
                                                            index,
                                                            0
                                                          );
                                                        }}
                                                      />
                                                    </label>
                                                  )}
                                                </td>
                                              ) : (
                                                <></>
                                              )}
                                              <td className="text-end">
                                                {productVal.variants.length &&
                                                productVal.variants[0].variant[
                                                  'variantType'
                                                ][0] != undefined ? (
                                                  <span
                                                    className="fs-16 fw-600 text-gray text-hover-primary cursor-pointer"
                                                    onClick={() => {
                                                      handleVariationShow(
                                                        products.variants,
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    {productVal.showVariant
                                                      ? 'Hide'
                                                      : 'View'}{' '}
                                                    {productVal.variants.length}{' '}
                                                    variations
                                                  </span>
                                                ) : (
                                                  <>
                                                    <div className="d-inline-flex flex-nowrap">
                                                      <Button
                                                        variant="primary"
                                                        className="btn-active-light-primary fs-14 fw-600"
                                                        onClick={() => {
                                                          // navigate(
                                                          //   '/all-products/product-details',
                                                          //   {
                                                          //     state:
                                                          //       productVal
                                                          //         .variants[0]
                                                          //         .variant[
                                                          //         '_id'
                                                          //       ],
                                                          //   }
                                                          // );
                                                          handleViewDetails(
                                                            productVal
                                                              .variants[0]
                                                              .variant['_id']
                                                          );
                                                        }}
                                                      >
                                                        View details
                                                      </Button>
                                                      {active ===
                                                      RejectedProduct ? (
                                                        <Button
                                                          variant="light-primary"
                                                          className="me-3 btn-active-light-primary fs-14 fw-600"
                                                          onClick={() => {
                                                            rejectModal(
                                                              productVal
                                                                .variants[0]
                                                            );
                                                          }}
                                                        >
                                                          View Reason
                                                        </Button>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                            {productVal.showVariant ? (
                                              productVal.variants.map(
                                                (
                                                  variantVal: any,
                                                  variantIndex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-50px border me-5">
                                                              <img
                                                                src={
                                                                  variantVal
                                                                    .variant
                                                                    .media[0]
                                                                    .url
                                                                }
                                                                className="object-fit-contain"
                                                                alt=""
                                                              />
                                                            </div>
                                                            <div className="fs-15 fw-600 mw-lg-175px">
                                                              {variantVal.variant.title.replace(
                                                                /\s*\)\s*/g,
                                                                ')'
                                                              )}
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <span className="fs-15 fw-600">
                                                            {
                                                              variantVal.variant
                                                                .product
                                                                .business.name
                                                            }
                                                          </span>
                                                        </td>
                                                        {active ===
                                                        ApprovedProduct ? (
                                                          <td>
                                                            <span className="d-block fs-15 fw-600 text-dark">
                                                              {Method.convertDateToDDMMYYYYHOURS(
                                                                variantVal
                                                                  .variant
                                                                  .approvalInfo
                                                                  .date
                                                              )}
                                                            </span>
                                                          </td>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {active ===
                                                        PendingProduct ? (
                                                          <td>
                                                            <span className="d-block fs-15 fw-600 text-dark">
                                                              {Method.convertDateToDDMMYYYYHOURS(
                                                                variantVal
                                                                  .variant
                                                                  ._createdAt
                                                              )}
                                                            </span>
                                                          </td>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {active ===
                                                        RejectedProduct ? (
                                                          <td>
                                                            <span className="d-block fs-15 fw-600 text-dark">
                                                              {Method.convertDateToDDMMYYYYHOURS(
                                                                variantVal
                                                                  .variant
                                                                  .rejectionInfo
                                                                  .date
                                                              )}
                                                            </span>
                                                          </td>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        {active ===
                                                        ApprovedProduct ? (
                                                          <>
                                                            <td>
                                                              <label className="form-check form-switch form-check-custom form-check-solid ">
                                                                <input
                                                                  className="form-check-input form-check-success w-50px h-30px"
                                                                  type="checkbox"
                                                                  name="notifications"
                                                                  value={
                                                                    variantVal
                                                                      .variant
                                                                      ._id
                                                                  }
                                                                  checked={
                                                                    variantVal
                                                                      .variant
                                                                      .active
                                                                  }
                                                                  disabled={currentUser?.role.some(
                                                                    (
                                                                      obj: any
                                                                    ) =>
                                                                      obj.type ===
                                                                      LoadingBayManager
                                                                  )}
                                                                  onChange={() => {
                                                                    updateProductState(
                                                                      variantVal
                                                                        .variant
                                                                        .active,
                                                                      variantVal
                                                                        .variant
                                                                        ._id,
                                                                      false,
                                                                      index,
                                                                      variantIndex
                                                                    );
                                                                  }}
                                                                />
                                                              </label>
                                                            </td>
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                        <td className="text-end">
                                                          <Button
                                                            variant="primary"
                                                            className="me-3 btn-active-light-primary fs-14 fw-600"
                                                            onClick={() => {
                                                              // navigate(
                                                              //   '/all-products/product-details',
                                                              //   {
                                                              //     state:
                                                              //       variantVal
                                                              //         .variant
                                                              //         ._id,
                                                              //   }
                                                              // );
                                                              handleViewDetails(
                                                                variantVal
                                                                  .variant[
                                                                  '_id'
                                                                ]
                                                              );
                                                            }}
                                                          >
                                                            View details
                                                          </Button>
                                                          {active ===
                                                          RejectedProduct ? (
                                                            <Button
                                                              variant="light-primary"
                                                              className="me-3 btn-active-light-primary fs-14 fw-600"
                                                              onClick={() => {
                                                                rejectModal(
                                                                  variantVal
                                                                );
                                                              }}
                                                            >
                                                              View Reason
                                                            </Button>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                          No Data found
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Card.Body>
                        </Card>
                        {!loading ? (
                          <>
                            {products.length ? (
                              <Pagination
                                totalRecords={totalRecords}
                                currentPage={page}
                                handleCurrentPage={(event: any) => {
                                  handleCurrentPage(event);
                                }}
                                handleNextPage={(event: any) => {
                                  handleNextPage(event);
                                }}
                                handlePreviousPage={(event: any) => {
                                  handlePreviousPage(event);
                                }}
                                pageLimit={pageLimit}
                                handlePageLimit={(event: any) => {
                                  handlePageLimit(event);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                    </>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AllProducts;
