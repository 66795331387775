import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PageTitle } from '../../../_admin/layout/core';
import AddNewCustomers from './add-new-customers';
import ViewCustomers from './view-customers';
import CustomerProfile from './customer-profile';
import ViewCustomersManager from './view-customers-manager';
import ViewCustomersMember from './view-customers-member';
import { useAuth } from '../auth';
import {
  FinanceManager,
  FulfillmentManager,
  ServiceMember,
} from '../../../utils/constants';
import RefundsToCustomers from '../finance/refunds/refunds-to-customers';
import CustomerFinanceDetails from '../finance/refunds/customer-finance-details';
import MakeRefund from '../finance/refunds/make-refund';
import ReceivableReport from './receivable-report';
import CollectPayment from './collect-payment';
const Index = () => {
  const { currentUser } = useAuth();
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="*"
          element={<Navigate to="/error/404" />}
        />
        <Route
          index
          element={
            <>
              {currentUser?.role.length ? (
                currentUser?.role.some(
                  (obj: any) => obj.type === ServiceMember
                ) ? (
                  <ViewCustomersMember />
                ) : currentUser?.role.some(
                    (obj: any) =>
                      obj.type === FinanceManager ||
                      obj.type === FulfillmentManager
                  ) ? (
                  <ViewCustomers />
                ) : (
                  <ViewCustomersManager />
                )
              ) : (
                <ViewCustomers />
              )}
            </>
          }
        />
        <Route
          path="add-customers"
          element={
            <>
              <PageTitle>Customers</PageTitle>
              <AddNewCustomers />
            </>
          }
        />
        <Route
          path="add-new-customers"
          element={
            <>
              <PageTitle>Customers</PageTitle>
              <AddNewCustomers />
            </>
          }
        />
        <Route
          path="refunds-to-customers"
          element={
            <>
              <RefundsToCustomers />
            </>
          }
        />
        <Route
          path="receivable-report"
          element={
            <>
              <PageTitle>Receivable Report</PageTitle>
              <ReceivableReport />
            </>
          }
        />
        <Route
          path="receivable-report/collect-payment"
          element={
            <>
              <PageTitle>Collect Payment</PageTitle>
              <CollectPayment />
            </>
          }
        />
        <Route
          path="refunds-to-customers/customer-refund-details"
          element={
            <>
              <CustomerFinanceDetails />
            </>
          }
        />
        <Route
          path="refunds-to-customers/make-refund"
          element={
            <>
              <MakeRefund />
            </>
          }
        />
        <Route
          path="add-customers/add-new-customers/view-customers"
          element={
            <>
              <PageTitle>Customers</PageTitle>
              <ViewCustomers />
            </>
          }
        />
        <Route
          path="view-customers"
          element={
            <>
              <PageTitle>Customers</PageTitle>
              {currentUser?.role.length ? (
                currentUser?.role.some(
                  (obj: any) => obj.type === ServiceMember
                ) ? (
                  <ViewCustomersMember />
                ) : currentUser?.role.some(
                    (obj: any) =>
                      obj.type === FinanceManager ||
                      obj.type === FulfillmentManager
                  ) ? (
                  <ViewCustomers />
                ) : (
                  <ViewCustomersManager />
                )
              ) : (
                <ViewCustomers />
              )}
            </>
          }
        />
        <Route
          path="customer-profile"
          element={
            <>
              <PageTitle>customer profile</PageTitle>
              <CustomerProfile />
            </>
          }
        />
      </Route>
    </Routes>
  );
};
export default Index;
