import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import Calendar from "react-calendar";

import prev from "../../_admin/assets/media/svg_uTrade/left-arrow.svg";
import next from "../../_admin/assets/media/svg_uTrade/right-arrow.svg";
import CrossSvg from "../../_admin/assets/media/close.png";

const ExpiryLicence = (props: any) => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, onChange] = useState(new Date());

  return (
    <>
      {/* <Button variant='primary' onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal
        show={props.showExpiry}
        onHide={props.handleHideExpiry}
        dialogClassName="modal-dialog-centered "
        className="border-r10px"
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.handleHideExpiry}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-5">
            Select expiry date for Business License
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-5 d-flex justify-content-center">
          <Calendar
            value={props.value}
            onChange={(e: any) => {
              props.handleExpirySelect(e);
            }}
            minDate={new Date()}
            prev2Label={null}
            tileClassName={"demo"}
            next2Label={null}
            prevLabel={<img src={prev} height={15} alt=""></img>}
            nextLabel={<img src={next} height={15} alt=""></img>}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

// export {kycRejected}
export default ExpiryLicence;
