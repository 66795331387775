import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../_admin/layout/core";

import ViewAdvertisement from "./view-advertisement";
import AddNewAdvertisement from "./add-new-advertisement";
import AdvertisementDetails from "./advertising-details";

const AdvertisementPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path="view-advertisement"
        element={
          <>
            <PageTitle>View Advertisement</PageTitle>
            <ViewAdvertisement />
          </>
        }
      />

      <Route
        path="add-new-advertisement"
        element={
          <>
            <PageTitle>Add New Advertisement</PageTitle>
            <AddNewAdvertisement />
          </>
        }
      />

      <Route
        path="view-advertisement/advertising-details"
        element={
          <>
            <PageTitle>Advertising Details</PageTitle>
            <AdvertisementDetails />
          </>
        }
      />

      <Route
        index
        element={<Navigate to="/advertisement/view-advertisement" />}
      />
    </Route>
  </Routes>
);

export default AdvertisementPage;
