import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../_admin/layout/core";

import ContactInquiries from "./contact-inquiries";

const index = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="*" element={<Navigate to="/error/404" />} />

        <Route index element={<Navigate to="/contact-inquiries" />} />

        <Route
          path="inquiries"
          element={
            <>
              <PageTitle>Contact inquiries</PageTitle>
              <ContactInquiries />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default index;
