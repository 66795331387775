import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMAGES, ordersJSON } from '../../../../utils/dummyJSON';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import PaymentOfOrderRefunds from '../../../modals/payment-of-order-refunds';
import {
  OrderCancelled,
  OrderCash,
  OrderDelivered,
  OrderOutForDelivery,
  OrderProcessed,
  OrderSubmitted,
  OrderTigoPesa,
  PAGE_LIMIT,
} from '../../../../utils/constants';
import { buyer } from '../../../../api/apiEndPoints';
import { customerJSON } from '../../../../api/apiJSON/customer';
import APICallService from '../../../../api/apiCallService';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import { refundListJSON } from '../../../../utils/staticJSON';
import Pagination from '../../../../Global/pagination';
import secureLocalStorage from 'react-secure-storage';
const CustomerFinanceDetails = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [orderRefunds, setOrderRefunds] = useState(false);
  const [filterValue, setFilterValue] = useState(refundListJSON[0]);
  const [loading, setLoading] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [enableSelectAll, setEnableSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [refundList, setRefundList] = useState([]);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [totalRecords, setTotalRecords] = useState(0);
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (secureLocalStorage.getItem('customerDetails')) {
        let details: any = secureLocalStorage.getItem('customerDetails');
        setCustomerDetails(details);
        await fetchRefundList(page, pageLimit, details._id, 0);
      } else {
        secureLocalStorage.setItem('customerDetails', state);
        setCustomerDetails(state);
        await fetchRefundList(page, pageLimit, state._id, 0);
      }
      setLoading(false);
    })();
  }, []);
  const fetchRefundList = async (
    pageNo: number,
    limit: number,
    buyerId: string,
    listType?: number
  ) => {
    setLoading(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      needCount: pageNo == 1,
      buyer: buyerId,
      listType: listType,
    };
    let apiService = new APICallService(buyer.refundBuyerOrderList, params);
    let response = await apiService.callAPI();
    if (pageNo === 1) {
      setTotalRecords(response.total);
    } else {
      let prevTotal = totalRecords;
      setTotalRecords(prevTotal);
    }
    setRefundList(response.records);
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    await fetchRefundList(
      val,
      pageLimit,
      customerDetails._id,
      filterValue.value
    );
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    await fetchRefundList(
      val + 1,
      pageLimit,
      customerDetails._id,
      filterValue.value
    );
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    await fetchRefundList(
      val - 1,
      pageLimit,
      customerDetails._id,
      filterValue.value
    );
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    await fetchRefundList(
      1,
      event.target.value,
      customerDetails._id,
      filterValue.value
    );
  };
  const handleSelectAll = async () => {
    if (selected) {
      setSelectLoading(true);
      setSelectedId([]);
      setSelected(!selected);
      setSelectedData([]);
      setSelectLoading(false);
    } else {
      setSelectLoading(true);
      setSelected(!selected);
      let params: any = {
        sortKey: 'name',
        sortOrder: 1,
        buyer: state._id,
        listType: filterValue.value,
      };
      let apiService = new APICallService(buyer.refundBuyerOrderList, params);
      let response: any = await apiService.callAPI();
      let temp: any = [];
      let tempData: any = [];
      if (response.records) {
        response.records.map((val: any) => {
          temp.push(val._id);
          tempData.push(val);
        });
      }
      setSelectedId(temp);
      setSelectedData(tempData);
      setSelectLoading(false);
    }
  };
  const handleChecked = (id: string, data: any, index: number) => {
    let temp = [...selectedId];
    let tempData = [...selectedData];
    if (temp.length) {
      if (temp.includes(id)) {
        temp = temp.filter((item) => item != id);
        tempData = tempData.filter((item) => item._id != id);
      } else {
        temp.push(id);
        tempData.push(data);
      }
    } else {
      temp.push(id);
      tempData.push(data);
    }
    setSelectedId(temp);
    setSelectedData(tempData);
    setSelected(temp.length == totalRecords);
  };
  const handleRefund = (data: any) => {
    // setOrderRefunds(true);
    navigate('/customers/refunds-to-customers/make-refund', {
      state: { selectedData: [data], customerDetails: customerDetails },
    });
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>
          You are allowed to issue a refund for a delivered or cancelled order
        </span>
      </Popover.Body>
    </Popover>
  );
  const markAsRefund = async () => {
    if (selectedData.length) {
      setRefundLoading(true);
      let temp: any = [];
      selectedData.map((val: any) => {
        temp.push({
          orderId: val._id,
          paymentMode: OrderCash,
          amount: val.payment.adjustmentCharge
            ? val.payment.adjustmentCharge
            : val.payment.receivedCharge,
        });
      });
      let params = {
        refundData: temp,
        buyerId: state._id,
      };
      let apiService = new APICallService(buyer.updateRefundStatus, params);
      let response = await apiService.callAPI();
      // setRefundList(response.records);
      let refundTemp = [...refundList];
      refundTemp.map((val: any) => {
        if (selectedId.includes(val._id)) {
          val.payment.adjustmentTransactions = [
            {
              completed: true,
              completedAt: new Date(),
            },
          ];
        }
        return val;
      });
      setSelectLoading(true);
      setSelectedId([]);
      setSelected(false);
      setSelectedData([]);
      setEnableSelectAll(false);
      setSelectLoading(false);
      setRefundList(refundTemp);
      setRefundLoading(false);
      setOrderRefunds(false);
    }
  };
  const handleFilter = async (event: any) => {
    setFilterValue(event);
    setPage(1);
    await fetchRefundList(1, pageLimit, customerDetails._id, event.value);
  };
  return (
    <div className="p-9">
      {orderRefunds ? (
        <PaymentOfOrderRefunds
          show={orderRefunds}
          onHide={() => setOrderRefunds(false)}
          selectedData={selectedData}
          state={state}
          markAsRefund={markAsRefund}
          refundLoading={refundLoading}
        />
      ) : (
        <></>
      )}
      <h1 className="fs-22 fw-bolder">Customer finance details</h1>
      {customerDetails.refundVal ? (
        <Card className="border-r10px mt-7">
          <Card.Body className="p-7">
            <Row className="align-items-center g-5">
              <Col xs="auto">
                <div className="symbol symbol-50px border">
                  <img
                    src={customerDetails.refundVal.business.image}
                    className="img-fluid object-fit-contain"
                    alt=""
                  />
                </div>
              </Col>
              <Col
                xs
                className="ps-lg-0"
              >
                <div className="fs-20 fw-bolder">
                  {customerDetails.refundVal.business.name}
                  {/* <img className="ms-2 mb-1"
                                    width={24} height={24} src={IMAGES.verifiedBadge}
                                    alt=""
                                /> */}
                </div>
              </Col>
              <Col
                sm="auto"
                className="text-end"
              >
                <Button
                  variant="link"
                  className="btn-flush fs-16 fw-bold text-primary"
                  onClick={() => {
                    navigate('/customers/customer-profile', {
                      state: customerDetails._id,
                    });
                  }}
                >
                  View customer profile
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
      <Row className="align-items-center g-5 mt-2">
        <Col
          lg
          className="fs-22 fw-bolder"
        >
          Order refunds
        </Col>
        <Col md="auto">
          <Row className="align-items-center g-5">
            <Col sm="auto">
              <label
                htmlFor=""
                className="fs-16 fw-500"
              >
                Filter orders
              </label>
            </Col>
            <Col xs>
              <div className="min-w-lg-198px">
                <CustomSelect
                  options={refundListJSON}
                  value={filterValue}
                  defaultValue={filterValue}
                  onChange={(event: any) => {
                    handleFilter(event);
                  }}
                />
              </div>
            </Col>
            {/* <Col xs="auto">
              {selectedId.length ? (
                <Button
                  size="lg"
                  className="min-h-60px"
                  onClick={() => {
                    // handleRefund();
                  }}
                >
                  Make a refund of {selectedId.length} orders
                </Button>
              ) : (
                <></>
              )}
              {selected || selectedId.length ? (
                <Button
                  size="lg"
                  variant="light-primary"
                  className="fs-18 fw-600 btn-lg me-4 min-h-60px ms-2"
                  // onClick={() => setDeliveryRoutesPlanningModal(true)}
                  onClick={() => {
                    setSelectedId([]);
                    setSelected(!selected);
                    setEnableSelectAll(false);
                    setSelectLoading(false);
                    setSelected(false);
                  }}
                  disabled={selectLoading}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  size="lg"
                  className="min-h-60px"
                  onClick={async () => {
                    setFilterValue({
                      value: 2,
                      label: 'Not Refunded Orders',
                    });
                    await fetchRefundList(1, pageLimit, customerDetails._id, 2);
                    setEnableSelectAll(true);
                  }}
                >
                  Make a payment
                </Button>
              )}
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Card className="border border-r10px mt-7">
        <Card.Body className="p-0">
          <div className="table-responsive">
            <table className="table table-row-bordered align-middle gs-7 gy-4 my-0">
              <thead>
                <tr className="fs-16 fw-600 h-65px align-middle">
                  {enableSelectAll ? (
                    <th
                      className="min-w-100px"
                      tabIndex={0}
                      rowSpan={1}
                      colSpan={1}
                    >
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input h-30px w-30px"
                          type="checkbox"
                          value="1"
                          checked={
                            selectLoading ||
                            (selectedId.length == totalRecords && selected)
                          }
                          onChange={(event: any) => {
                            handleSelectAll();
                          }}
                        />
                        <label className="form-check-label fs-16 fw-600 text-dark">
                          Select all
                        </label>
                      </div>
                    </th>
                  ) : (
                    <th className="min-w-md-175px min-w-150px">
                      Order Date & ID
                    </th>
                  )}
                  <th className="min-w-100px">
                    Total order <br /> value
                  </th>
                  <th className="min-w-120px">
                    Total refund <br /> value
                  </th>
                  <th className="min-w-120px">Refund reason</th>
                  <th className="min-w-120px">Status</th>
                  <th className="min-w-120px">Refund Status</th>
                  <th className="min-w-150px text-end"></th>
                </tr>
              </thead>
              <tbody className="fs-15 fw-600">
                {loading ? (
                  <>
                    <tr>
                      <td colSpan={4}>
                        <div className="d-flex justify-content-center">
                          <Loader loading={loading} />
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {refundList.length > 0 ? (
                      refundList.map((order: any, index: number) => (
                        <>
                          <tr key={index}>
                            {/* <td>
                              <span className="text-dark d-block">
                                {Method.convertDateToDDMMYYYYHHMM(
                                  order._createdAt,
                                  '-'
                                )}
                              </span>
                              <span className="text-gray d-block">
                                {order.refKey}
                              </span>
                            </td> */}
                            {enableSelectAll ? (
                              <td>
                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                  {order.payment.adjustmentTransactions
                                    .length ? (
                                    <></>
                                  ) : (
                                    <input
                                      className="form-check-input h-30px w-30px"
                                      type="checkbox"
                                      value={order._id}
                                      checked={selectedId.includes(order._id)}
                                      onChange={() => {
                                        handleChecked(order._id, order, index);
                                      }}
                                    />
                                  )}
                                  <div className="d-flex align-items-start flex-column ms-2">
                                    <div className="d-flex flex-row">
                                      <span className="text-dark d-block">
                                        {Method.convertDateToDDMMYYYYHHMM(
                                          order._createdAt,
                                          '-'
                                        )}
                                      </span>
                                    </div>
                                    <span className="text-gray d-block">
                                      {order.refKey}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <td className="fs-15 fw-500">
                                <div className="d-flex align-items-start flex-column">
                                  <div className="d-flex flex-row">
                                    <span className="text-dark d-block">
                                      {Method.convertDateToDDMMYYYYHHMM(
                                        order._createdAt,
                                        '-'
                                      )}
                                    </span>
                                  </div>
                                  <span className="text-gray d-block">
                                    {order.refKey}
                                  </span>
                                </div>
                              </td>
                            )}
                            <td>
                              {order.status === OrderCancelled
                                ? 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.receivedCharge
                                  )
                                : 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.adjustmentCharge
                                  )}
                            </td>
                            <td>
                              {order.status === OrderCancelled
                                ? 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.receivedCharge
                                  )
                                : 'TSh ' +
                                  Method.getGeneralizedAmount(
                                    order.payment.adjustmentCharge
                                  )}
                            </td>
                            <td>
                              <span className="badge badge-light fs-15 fw-600 border-r23px text-dark p-3 px-4">
                                {order.status === OrderCancelled
                                  ? 'Order Cancelled'
                                  : 'Order Modified'}
                              </span>
                            </td>
                            <td>
                              {order.status === OrderCancelled ? (
                                <span className="badge bg-light-danger fs-14 fw-600 text-dark p-3 px-4">
                                  Order Cancelled
                                </span>
                              ) : (
                                ''
                              )}
                              {order.status === OrderSubmitted ||
                              order.status === OrderProcessed ||
                              order.status === OrderOutForDelivery ? (
                                <span className="badge bg-fff4d9 fs-14 fw-600 text-dark p-3 px-4">
                                  {order.status === OrderSubmitted
                                    ? 'Order Placed'
                                    : ''}
                                  {order.status === OrderProcessed
                                    ? 'Order In-Progress'
                                    : ''}
                                  {order.status === OrderOutForDelivery
                                    ? 'Order Out-For-Delivery'
                                    : ''}
                                </span>
                              ) : (
                                <></>
                              )}{' '}
                              {order.status === OrderDelivered ? (
                                <span className="badge bg-light-success fs-14 fw-600 text-dark p-3 px-4">
                                  {' '}
                                  {order.status === OrderDelivered
                                    ? 'Order Delivered'
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              {order.payment.adjustmentTransactions.length ? (
                                <span className="badge bg-e5f6de fs-14 fw-600 text-dark p-3 px-4">
                                  Refunded on{' '}
                                  {Method.convertDateToDDMMYYYY(
                                    order.payment.adjustmentTransactions[0]
                                      .completedAt
                                  )}
                                </span>
                              ) : (
                                // <span className="badge bg-fff4d9 fs-14 fw-600 text-dark p-3 px-4">
                                //   Not refunded
                                // </span>
                                <>
                                  {' '}
                                  {order.status === OrderCancelled ||
                                  order.status === OrderDelivered ? (
                                    <Button
                                      className="fs-14 fw-600"
                                      variant="light-primary"
                                      onClick={() => {
                                        handleRefund(order);
                                      }}
                                    >
                                      Make a payment
                                    </Button>
                                  ) : (
                                    <OverlayTrigger
                                      trigger="hover"
                                      placement="bottom"
                                      overlay={popover}
                                    >
                                      <Button
                                        className="fs-14 fw-600"
                                        variant="light-primary"
                                      >
                                        Make a payment
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="text-end">
                              <Button
                                className="fs-14 fw-600"
                                onClick={() =>
                                  navigate('/orders/order-details', {
                                    state: order._id,
                                  })
                                }
                              >
                                View details
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                            No Data found
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}{' '}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
      {!loading ? (
        <>
          {refundList.length ? (
            <Pagination
              totalRecords={totalRecords}
              currentPage={page}
              handleCurrentPage={(event: any) => {
                handleCurrentPage(event);
              }}
              handleNextPage={(event: any) => {
                handleNextPage(event);
              }}
              handlePreviousPage={(event: any) => {
                handlePreviousPage(event);
              }}
              pageLimit={pageLimit}
              handlePageLimit={(event: any) => {
                handlePageLimit(event);
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CustomerFinanceDetails;
