import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import AddNewOrderSubAdmin from './add-new-order-assist';
import ReturnOrder from './return-order';
import AdjustReturnOrder from './adjust-return-order';
import ReviewOrderList from './review-orders-list';
import AdjustProductInventory from './adjust-product-inventory';
import DeliveryRoutesPlanDetails from './delivery-plan-details';
const OrderDetails = lazy(() => import('./order-details'));
const PastDeliveryRouteDetails = lazy(
  () => import('./past-delivery-route-details')
);
const PastDeliveryRoutePlans = lazy(
  () => import('./past-delivery-route-plans')
);
const GoodsLoadingDetails = lazy(() => import('./goods-loading-details'));
const RouteDetails = lazy(() => import('./route-details'));
const EditOrderDetails = lazy(() => import('./edit-order-details'));
// const AllOrders = lazy(() => import('./orders-delivery-finance'));
const AllOrders = lazy(() => import('./all-orders'));
const AddNewOrder = lazy(() => import('./add-new-order'));
const index = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="*"
          element={<Navigate to="/error/404" />}
        />
        <Route
          index
          element={<AllOrders />}
        />
        <Route
          path="add-new-order"
          element={
            <>
              <AddNewOrder />
            </>
          }
        />
        <Route
          path="adjust-return-order"
          element={
            <>
              <AdjustReturnOrder />
            </>
          }
        />
        <Route
          path="adjust-product-inventory"
          element={
            <>
              <AdjustProductInventory />
            </>
          }
        />
        <Route
          path="return-order"
          element={
            <>
              <ReturnOrder />
            </>
          }
        />
        <Route
          path="add-new-order-assistAdmin"
          element={
            <>
              <AddNewOrderSubAdmin />
            </>
          }
        />
        <Route
          path="order-details"
          element={
            <>
              <OrderDetails />
            </>
          }
        />
        <Route
          path="edit-order-details"
          element={
            <>
              <EditOrderDetails />
            </>
          }
        />
        <Route
          path="past-delivery-route-plans"
          element={
            <>
              <PastDeliveryRoutePlans />
            </>
          }
        />
        <Route
          path="past-delivery-route-details"
          element={
            <>
              <PastDeliveryRouteDetails />
            </>
          }
        />
        <Route
          path="goods-loading-details"
          element={
            <>
              <GoodsLoadingDetails />
            </>
          }
        />
        <Route
          path="review-order-list"
          element={
            <>
              <ReviewOrderList />
            </>
          }
        />
        <Route
          path="route-details"
          element={
            <>
              <RouteDetails />
            </>
          }
        />
        <Route
          path="plan-details"
          element={
            <>
              <DeliveryRoutesPlanDetails />
            </>
          }
        />
      </Route>
    </Routes>
  );
};
export default index;
