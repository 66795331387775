export const auth = {
  resendOTP: 'We’ve sent an OTP to register email and phone number',
  verifiedEmail: 'Email verified!',
  verifiedOTP: 'OTP verified!',
  forgotOTPResend: 'OTP sent on your provided email or phone number!',
  register: 'Registered successfully!',
  login: 'Logged In done successfully!',
  passwordReset: 'Password reset successfully',
};
export const sellerToast = {
  updateProfile: 'Profile updated successfully!',
  kycStatusUpdate: 'Kyc status updated successfully!',
  reverify: 'We’ve sent an OTP to your updated email and phone number',
  messageUpdate: 'Message updated successfully!',
};
export const Auth = {
  login: 'Logged In successfully!',
};
export const masterToast = {
  addbrand: 'Brand details added successfully!',
  editbrand: 'Brand details edited successfully!',
  addPrimaryCategory: 'Primary Category added successfully!',
  editPrimaryCategory: 'Primary Category edited successfully!',
  addSubCategory: 'Sub Category added successfully!',
  editSubCategory: 'Sub Category edited successfully!',
  addGroupCategory: 'Group Category added successfully!',
  editGroupCategory: 'Group Category edited successfully!',
  addCertificate: 'Certificate added successfully!',
  editCertificate: 'Certificate edited successfully!',
  addBusinessType: 'Business Type added successfully!',
  editBusinessType: 'Business Type edited successfully!',
  addVariant: 'Product Variant added successfully!',
  editVariant: 'Product Variant edited successfully!',
  addTechnical: 'Technical Details added successfully!',
  editTechnical: 'Technical Details edited successfully!',
  addArea: 'Loading Area added successfully!',
  editArea: 'Loading Area edited successfully!',
  dateSaved: 'Data saved successfully!',
  addFinance: 'Finance member added successfully!',
  updateFinance: 'Member details updated successfully!',
};
export const catalogue = {
  added: 'Product details added successfully!',
  draft: 'Product details saved as draft!',
  updated: 'Product details updated successfully!',
  variantUpdated: 'Variant details updated successfully!',
};
export const pendingCertificatesToast = {
  notified: 'Notification sent successfully!',
};
export const platformFeesToast = {
  feesUpdated: 'Fees updated successfully!',
};
export const advertismentToast = {
  published: 'Advertisement Published',
};
export const driverToast = {
  added: 'Driver Added Successfully',
  edited: 'Driver Edited Successfully',
};
export const vehicleToast = {
  added: 'Vehicle Added Successfully',
  edited: 'Vehicle edited Successfully',
};
export const customerServiceToast = {
  added: 'Member Added Successfully',
  edited: 'Member edited Successfully',
};
export const appSettingsToast = {
  addPrivacyPolicy: 'Privacy Policy saved successfully!',
  addTermsCondition: 'Terms and Conditions saved successfully!',
  addAppSettings: 'App Settings saved successfully!',
};
