import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IMAGES } from '../../../utils/dummyJSON';
import ActiveSellersCustomers from './chart/active-sellers-customers';
import OrdersReport from './chart/orders-report';
import SalesReport from './chart/admin-sale-report';
import SellersReport from './chart/sellers-report';
import { useAuth } from '../auth';
import { AssistAdmin, FinanceManager } from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { useEffect, useState } from 'react';
import { DASHBOARD } from '../../../api/apiEndPoints';
import DashboardReport from '../../modals/report/dashboard-report';
import Method from '../../../utils/methods';
const AdminDashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [count, setCount] = useState({
    pendingKYC: 0,
    totalSellers: 0,
    pendingProducts: 0,
    pendingShipmentsRequests: 0,
    totalListedProducts: 0,
    totalOrders: 0,
    totalBuyers: 0,
    totalOrderAmount: 0,
  });
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);
  const fetchData = async () => {
    let apiService = new APICallService(DASHBOARD, {
      needStats: true,
      needAllStats: true,
      fromDate: Method.convertDateToFormat(new Date(), 'YYYY-MM-DD'),
    });
    let response = await apiService.callAPI();
    if (response) {
      setCount(response.stats);
    }
  };
  return (
    <>
      <div className="p-9">
        {showDownloadModal ? (
          <DashboardReport
            show={showDownloadModal}
            onHide={() => setShowDownloadModal(false)}
          />
        ) : (
          <></>
        )}
        <Row className="mb-7 align-items-center">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Dashboard
          </Col>
          {currentUser?.userType === AssistAdmin ? (
            <></>
          ) : (
            <>
              {' '}
              <Col sm="auto">
                <Button
                  className="min-h-50px"
                  onClick={() => {
                    navigate('/orders');
                  }}
                >
                  View orders & delivery plans
                </Button>
              </Col>
              {/* <Col sm="auto">
                <Button
                  className="min-h-50px"
                  onClick={() => {
                    navigate('/orders');
                  }}
                  variant="light-primary"
                >
                  Download Report
                </Button>
              </Col> */}
            </>
          )}
          {currentUser?.role.length === 0 ||
          currentUser?.role.some((obj: any) => obj.type === FinanceManager) ? (
            <Col sm="auto">
              <Button
                variant=""
                size="lg"
                className="text-primary bg-light-primary min-h-50px"
                onClick={() => setShowDownloadModal(true)}
              >
                Download Report
              </Button>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row className="align-items-center mb-7 g-6">
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-fbe5e5 rounded py-4 px-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span className="fs-22 fw-bolder">
                    TSh{' '}
                    {count.totalOrderAmount > 0
                      ? Method.getGeneralizedAmount(count.totalOrderAmount)
                      : 0}
                  </span>
                  <span className="fw-medium fs-16">
                    {`Today's total order value`}{' '}
                  </span>
                </div>
                <img
                  src={IMAGES.PaymentSVG}
                  alt=""
                />
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-c9f3d7 rounded py-4 px-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span className="fs-22 fw-bolder">
                    {count.totalSellers > 0 ? count.totalSellers : 0}
                  </span>
                  <span className="fw-medium fs-16">Total sellers</span>
                </div>
                <img
                  src={IMAGES.SellerSVG}
                  alt=""
                />
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-fbeace rounded py-4 px-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span className="fs-22 fw-bolder">
                    {count.totalBuyers > 0 ? count.totalBuyers : 0}
                  </span>
                  <span className="fw-medium fs-16">Total customers</span>
                </div>
                <img
                  src={IMAGES.CustomerSVG}
                  alt=""
                />
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-c6e4fb rounded py-4 px-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span className="fs-22 fw-bolder">
                    {count.totalListedProducts > 0
                      ? count.totalListedProducts
                      : 0}
                  </span>
                  <span className="fw-medium fs-16">Total products</span>
                </div>
                <img
                  src={IMAGES.ProductSVG}
                  alt=""
                />
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            md={6}
            sm={4}
          >
            <div className="border-r8px bg-d5d5f2  rounded py-4 px-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span className="fs-22 fw-bolder">
                    {count.totalOrders > 0 ? count.totalOrders : 0}
                  </span>
                  <span className="fw-medium fs-16">Total orders</span>
                </div>
                <img
                  src={IMAGES.OrderSVG}
                  alt=""
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* Chart 1 */} <SalesReport />
        {/* Chart 2 */} <ActiveSellersCustomers />
        {/* Chart 3 */} <OrdersReport />
        {/* Chart 4 */} <SellersReport />
      </div>
    </>
  );
};
export default AdminDashboard;
