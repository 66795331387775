import { IAddUpdateOtherFees, IAddUpdateCommission } from "../../types";

export const platformFeesJSON = {
  addUpdateOtherFees: ({
    homePageSingleProduct,
    homePageMultipleProduct,
    categoryPageSingleProduct,
    categoryPageMultipleProduct,
    minimumOrder,
  }: IAddUpdateOtherFees) => {
    return {
      homePageSingleProduct: homePageSingleProduct,
      homePageMultipleProduct: homePageMultipleProduct,
      categoryPageSingleProduct: categoryPageSingleProduct,
      categoryPageMultipleProduct: categoryPageMultipleProduct,
      minimumOrder: minimumOrder,
    };
  },
  addUpdatePlatformFees: ({ commission }: IAddUpdateCommission) => {
    let data: any = [];
    commission.map((val: any) => {
      let subTemp: any = [];
      if (val.categories && val.categories.length) {
        val.categories.map((subVal: any) => {
          let subDataTemp: any = {
            _id: subVal._id,
            commission:
              subVal.commission === "-" ? 0 : parseInt(subVal.commission),
          };
          if (subVal.categories && subVal.categories.length) {
            let groupTemp: any = [];
            subVal.categories.map((groupVal: any) => {
              groupTemp.push({
                _id: groupVal._id,
                commission:
                  groupVal.commission === "-"
                    ? 0
                    : parseInt(groupVal.commission),
              });
            });
            subDataTemp = { ...subDataTemp, categories: groupTemp };
          } else {
            subDataTemp = { ...subDataTemp, categories: [] };
          }
          subTemp.push(subDataTemp);
        });
      }
      data.push({
        _id: val._id,
        commission: val.commission === "-" ? 0 : parseInt(val.commission),
        categories: subTemp,
      });
    });
    return data;
  },
};
