import { Col, Modal, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { HomePage, SingleProduct } from '../../utils/constants';
import Method from '../../utils/methods';
function AdvertisementDetails(props: any) {
  return (
    <>
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-690px"
        className="border-r10px"
        centered
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-lg-3">
            {props.advertisement.business.ownerName}
            <br></br>
            {props.advertisement.business.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-lg-6 gy-6">
            <Col lg={6}>
              <div className="border border-r8px p-5">
                <h3 className="fs-22 fw-bolder mb-1">
                  {props.advertisement.placement === HomePage
                    ? 'Home page'
                    : 'Category page'}
                </h3>
                <span className="fs-16 fw-500 text-gray">Ad placement</span>
              </div>
            </Col>
            <Col lg={6}>
              <div className="border border-r8px p-5">
                <h3 className="fs-22 fw-bolder mb-1">Products</h3>
                <span className="fs-16 fw-500 text-gray">
                  {props.advertisement.variantCount > 0
                    ? props.advertisement.variantCount
                    : ''}{' '}
                  (
                  {props.advertisement.placement === SingleProduct
                    ? 'Single'
                    : 'multiple'}
                  )
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <div className="border border-r8px p-5">
                <h3 className="fs-22 fw-bolder mb-1">
                  {Method.convertDateToDDMMYYYY(props.advertisement.startDate)}
                </h3>
                <span className="fs-16 fw-500 text-gray">Start date</span>
              </div>
            </Col>
            <Col lg={6}>
              <div className="border border-r8px p-5">
                <h3 className="fs-22 fw-bolder mb-1">
                  {' '}
                  {Method.convertDateToDDMMYYYY(props.advertisement.endDate)}
                </h3>
                <span className="fs-16 fw-500 text-gray">End date</span>
              </div>
            </Col>
            <Col xs={12}>
              <h3 className="fs-22 fw-bolder">Advertise instruction</h3>
              <p className="fs-18 fw-500">{props.advertisement.description}</p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AdvertisementDetails;
