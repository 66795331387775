import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../_admin/helpers';
import { IGetCustomers } from '../../../types/responseIndex';
import { useAuth } from '../auth';
import APICallService from '../../../api/apiCallService';
import { buyer } from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import Pagination from '../../../Global/pagination';
import { PAGE_LIMIT } from '../../../utils/constants';
import b1 from '../../../_admin/assets/media/product/default.svg';
const ViewCustomersMember = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [customers, setCustomers] = useState<IGetCustomers | any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [district, setDistrict] = useState<any>(
    currentUser &&
      currentUser.districts &&
      currentUser.districts[0] &&
      currentUser.districts.find((val: any) => val.flag === true).reference
  );
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      await fetchCustomerData(page, pageLimit, searchTerm);
      setFetchLoading(false);
    })();
  }, []);
  const fetchCustomerData = async (
    pageNo: number,
    limit: number,
    search: string
  ) => {
    setFetchLoading(true);
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search,
      needCount: pageNo === 1,
    };
    params = {
      ...params,
      ['addedBy[' + 0 + ']']: currentUser?._id,
      district: district,
    };
    let apiService = new APICallService(buyer.listOnBoardedBuyers, params);
    let response = await apiService.callAPI();
    if (response) {
      if (pageNo === 1) {
        setTotalRecords(response.total);
      } else {
        let prevTotal = totalRecords;
        setTotalRecords(prevTotal);
      }
      setCustomers(response.records);
    }
    setFetchLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setLoading(true);
    setPage(val);
    await fetchCustomerData(val, pageLimit, searchTerm);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    await fetchCustomerData(val + 1, pageLimit, searchTerm);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    await fetchCustomerData(val - 1, pageLimit, searchTerm);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    await fetchCustomerData(1, event.target.value, searchTerm);
    setLoading(false);
  };
  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    setLoading(true);
    await fetchCustomerData(1, pageLimit, value);
    setLoading(false);
  };
  const handleCustomerProfile = (id: string) => {
    navigate('/customers/customer-profile', { state: id });
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>No More details available</span>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="p-9">
        <Row className="align-items-center g-5 mb-7">
          <Col
            xs={12}
            sm
          >
            <h1 className="fs-22 fw-bolder mb-0">Customers</h1>
          </Col>
          <Col xs="auto">
            <Button
              size="lg"
              onClick={() => navigate('/customers/add-new-customers')}
            >
              Add new customer
            </Button>
          </Col>
        </Row>
        <>
          {searchTerm === '' && !customers.length ? (
            <></>
          ) : (
            <Card className="bg-light border">
              <Card.Body className="px-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center min-w-md-317px w-fit-content position-relative me-lg-4">
                      <KTSVG
                        path="/media/icons/duotune/general/gen021.svg"
                        className="svg-icon-3 position-absolute ps-5"
                      />
                      <input
                        type="text"
                        id="kt_filter_search"
                        className="form-control form-control-custom bg-white min-h-60px ps-13 text-dark"
                        placeholder="Search by customer name…"
                        onChange={(event: any) => {
                          handleSearch(event.target.value.trimStart());
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </>
        <Card className="border border-r10px mt-6">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                    <th className="min-w-250px">Customer name</th>
                    <th className="min-w-130px">Orders placed</th>
                    <th className="min-w-125px">Total sales</th>
                    <th className="min-w-150px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {fetchLoading ? (
                    <>
                      <td colSpan={3}>
                        <div className="w-100 d-flex justify-content-center">
                          <Loader loading={fetchLoading} />
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      {customers.length ? (
                        <>
                          {customers.map(
                            (customVal: any, customIndex: number) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-50px border me-5">
                                        <img
                                          src={
                                            customVal.business.image
                                              ? customVal.business.image
                                              : b1
                                          }
                                          className="object-fit-contain"
                                          alt={customVal.business.name}
                                        />
                                      </div>
                                      <div className="fs-15 fw-600">
                                        {customVal.name} <br />
                                        <span className="fw-500 text-gray">
                                          {customVal.business.name}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">0</span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-600">TSh 0</span>
                                  </td>
                                  <td className="text-end">
                                    {/* {!customVal.regCompleted &&
                                    customVal.addedByAdmin === false ? (
                                      <OverlayTrigger
                                        trigger="hover"
                                        placement="bottom"
                                        overlay={popover}
                                      >
                                        <Button
                                          className="fs-14"
                                          variant="light"
                                        >
                                          View details
                                        </Button>
                                      </OverlayTrigger>
                                    ) : (
                                      <Button
                                        className="fs-14"
                                        disabled={
                                          !customVal.regCompleted &&
                                          customVal.addedByAdmin === false
                                        }
                                        onClick={() => {
                                          handleCustomerProfile(customVal._id);
                                        }}
                                      >
                                        View details
                                      </Button>
                                    )}{' '} */}
                                    <Button
                                      className="fs-14"
                                      onClick={() => {
                                        handleCustomerProfile(customVal._id);
                                      }}
                                    >
                                      View details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!fetchLoading ? (
          <>
            {customers.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={(event: any) => {
                  handleCurrentPage(event);
                }}
                handleNextPage={(event: any) => {
                  handleNextPage(event);
                }}
                handlePreviousPage={(event: any) => {
                  handlePreviousPage(event);
                }}
                pageLimit={pageLimit}
                handlePageLimit={(event: any) => {
                  handlePageLimit(event);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default ViewCustomersMember;
