import { useState } from 'react';
import { Button, Card, Col, FormLabel, Nav, Row, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../_admin/helpers';
import { ordersJSON } from '../../../utils/dummyJSON';
import CustomDatePicker from '../../custom/DateRange/DatePicker';

const OrdersDelivery = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [defaultValue, setDefaultValue] = useState('1');

  const handleActive = (event: any) => {
    setActive(event);
  };

  const handleSelect = (event: any) => {
    setDefaultValue(event);
  };

  const [startDate, setStartDate] = useState<any>(new Date());

  const handleChange = (e: any) => {
    setStartDate(e);
  };

  return (
    <>
      <div className="p-9">
        <Row className="align-items-center mb-7">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Orders & delivery
          </Col>
          <Col xs="auto">
            <CustomDatePicker
              className="form-control bg-f9f9f9 min-h-50px fs-16 fw-bold text-dark
                            min-w-md-255px min-w-175px"
              selected={startDate}
              onChange={handleChange}
              // selectsRange
              startDate={startDate}
              dateFormat="MMMM yyyy"
              showFullMonthYearPicker
            />
          </Col>
        </Row>

        {defaultValue == '1' ? null : (
          <>
            <Card className="bg-light border mb-7">
              <Card.Body className="px-7">
                <Row className="align-items-center">
                  <Col
                    md
                    className="mb-md-0 mb-5"
                  >
                    <div className="d-flex align-items-center mw-lg-315px   position-relative me-lg-4">
                      <KTSVG
                        path="/media/icons/duotune/general/gen021.svg"
                        className="svg-icon-3 position-absolute ms-3"
                      />
                      <input
                        type="text"
                        id="kt_filter_search"
                        className="form-control form-control-white form-control-lg min-h-60px ps-10"
                        placeholder="Search by product, business name…"
                      />
                    </div>
                  </Col>
                  <Col md="auto">
                    <div className="d-flex align-items-center">
                      <FormLabel className="fs-16 fw-500">
                        Filter by dates
                      </FormLabel>
                      <div className="ms-5">
                        <CustomDatePicker
                          className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                          selected={startDate}
                          onChange={handleChange}
                          // selectsRange
                          startDate={startDate}
                          dateFormat="dd/MMMM/yyyy"
                          showFullMonthYearPicker
                          inputTextBG="bg-white"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        )}

        <div className="custom-tabContainer">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultValue}
            onSelect={handleSelect}
          >
            <Row className="variant-categories">
              <Col
                xs={12}
                className="mb-6"
              >
                <div className="bg-light border border-r8px p-3 px-md-6 w-fit-content">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="1"
                        onClick={() => {
                          handleActive(1);
                        }}
                        active={active === 1}
                      >
                        {' '}
                        Delivery plans ({142}){' '}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="2"
                        onClick={() => {
                          handleActive(2);
                        }}
                        active={active === 2}
                      >
                        {' '}
                        New orders{' '}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="3"
                        onClick={() => {
                          handleActive(3);
                        }}
                        active={active === 3}
                      >
                        {' '}
                        In progress{' '}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="4"
                        onClick={() => {
                          handleActive(4);
                        }}
                        active={active === 4}
                      >
                        {' '}
                        Out for delivery{' '}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="5"
                        onClick={() => {
                          handleActive(5);
                        }}
                        active={active === 5}
                      >
                        {' '}
                        Delivered{' '}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="6"
                        onClick={() => {
                          handleActive(6);
                        }}
                        active={active === 6}
                      >
                        {' '}
                        Cancelled{' '}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>

              <Tab.Content>
                <Tab.Pane
                  eventKey="1"
                  active={defaultValue === '1'}
                >
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                          <thead>
                            <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                              <th className="min-w-125px">Date</th>
                              <th className="min-w-100px">Total vehicles</th>
                              <th className="min-w-100px">Total products</th>
                              <th className="min-w-100px">
                                Total goods weight
                              </th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-125px text-end"></th>
                            </tr>
                          </thead>
                          <tbody className="fs-15 fw-500">
                            <tr>
                              <td>25/03/2023</td>
                              <td>10</td>
                              <td>58</td>
                              <td>9.7 tons</td>
                              <td>
                                <span className="badge bg-fff4d9 border-r4px p-3 fs-14 fw-600 text-dark">
                                  In progress
                                </span>
                              </td>
                              <td className="text-end">
                                <Button
                                  variant="primary"
                                  className="fs-14 fw-600"
                                >
                                  View details
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <td>24/03/2023</td>
                              <td>13</td>
                              <td>58</td>
                              <td>13.9 tons</td>
                              <td>
                                <span className="badge bg-e5f6de border-r4px p-3 fs-14 fw-600 text-dark">
                                  Completed
                                </span>
                              </td>
                              <td className="text-end">
                                <Button
                                  variant="primary"
                                  className="fs-14 fw-600"
                                >
                                  View details
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane
                  eventKey="2"
                  active={defaultValue === '2'}
                >
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                          <thead>
                            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                              <th className="min-w-md-175px min-w-150px">
                                Order Date & ID
                              </th>
                              <th className="min-w-md-200px min-w-125px">
                                Customer name
                              </th>
                              <th className="min-w-md-125px">Quantity</th>
                              <th className="min-w-md-125px min-w-100px">
                                Total amount
                              </th>
                              <th className="min-w-150px text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersJSON.map((val: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="fs-15 fw-500">
                                    <span className="text-dark d-block">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                    <span className="text-gray d-block">
                                      {val.orderId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500 d-block">
                                      {val.buyerDetails}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.quantity + ' items'}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {'TSh ' + val.totalAmount}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          '/orders-delivery/order-details'
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table align-middle table-rounded table-row-bordered gs-7 gy-6 mb-0 no-footer">
                          <thead>
                            <tr className="text-start fw-bold fs-16 gs-0 border-bottom">
                              <th
                                className="w-10px pe-2"
                                rowSpan={1}
                                colSpan={1}
                              >
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                  <input
                                    className="form-check-input h-30px w-30px"
                                    type="checkbox"
                                    value="1"
                                  />
                                </div>
                              </th>
                              <th
                                className="min-w-125px"
                                tabIndex={0}
                                rowSpan={1}
                                colSpan={1}
                              >
                                Select all
                              </th>
                              <th
                                className="min-w-125px"
                                tabIndex={0}
                                rowSpan={1}
                                colSpan={1}
                              >
                                Customer name
                              </th>
                              <th
                                className="min-w-125px"
                                tabIndex={0}
                                rowSpan={1}
                                colSpan={1}
                              >
                                Quantity
                              </th>
                              <th
                                className="min-w-125px"
                                tabIndex={0}
                                rowSpan={1}
                                colSpan={1}
                              >
                                Total amount
                              </th>
                              <th
                                className="text-end min-w-70px"
                                rowSpan={1}
                                colSpan={1}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersJSON.map((val: any, index: number) => {
                              return (
                                <>
                                  <tr
                                    className="odd"
                                    key={index}
                                  >
                                    <td>
                                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                                        <input
                                          className="form-check-input h-30px w-30px"
                                          type="checkbox"
                                          value="1"
                                        />
                                      </div>
                                    </td>
                                    <td className="fs-15 fw-600">
                                      <span className="text-dark d-block">
                                        {val.orderDate + ' - ' + val.orderTime}
                                      </span>
                                      <span className="text-gray d-block">
                                        {val.orderId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-500 d-block">
                                        {val.buyerDetails}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-500">
                                        {val.quantity + ' items'}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-15 fw-500">
                                        {'TSh ' + val.totalAmount}
                                      </span>
                                    </td>
                                    <td className="text-end">
                                      <Button
                                        className="fs-14 fw-600"
                                        onClick={() =>
                                          navigate(
                                            '/orders-delivery/order-details'
                                          )
                                        }
                                      >
                                        View details
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane
                  eventKey="3"
                  active={defaultValue === '3'}
                >
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                          <thead>
                            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                              <th className="min-w-md-175px min-w-150px">
                                Order Date & ID
                              </th>
                              <th className="min-w-md-200px min-w-125px">
                                Customer name
                              </th>
                              <th className="min-w-md-125px">Quantity</th>
                              <th className="min-w-md-125px">Processed on</th>
                              <th className="min-w-md-125px min-w-100px">
                                Total amount
                              </th>
                              <th className="min-w-150px text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersJSON.map((val: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="fs-15 fw-500">
                                    <span className="text-dark d-block">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                    <span className="text-gray d-block">
                                      {val.orderId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500 d-block">
                                      {val.buyerDetails}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.quantity + ' items'}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {'TSh ' + val.totalAmount}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          '/orders-delivery/order-details'
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane
                  eventKey="4"
                  active={defaultValue === '4'}
                >
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                          <thead>
                            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                              <th className="min-w-md-175px min-w-150px">
                                Order Date & ID
                              </th>
                              <th className="min-w-md-200px min-w-125px">
                                Customer name
                              </th>
                              <th className="min-w-md-125px">Quantity</th>
                              <th className="min-w-md-125px">
                                Out for delivey on
                              </th>
                              <th className="min-w-md-125px min-w-100px">
                                Total amount
                              </th>
                              <th className="min-w-150px text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersJSON.map((val: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="fs-15 fw-500">
                                    <span className="text-dark d-block">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                    <span className="text-gray d-block">
                                      {val.orderId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500 d-block">
                                      {val.buyerDetails}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.quantity + ' items'}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {'TSh ' + val.totalAmount}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          '/orders-delivery/order-details'
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane
                  eventKey="5"
                  active={defaultValue === '5'}
                >
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                          <thead>
                            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                              <th className="min-w-md-175px min-w-150px">
                                Order Date & ID
                              </th>
                              <th className="min-w-md-200px min-w-125px">
                                Customer name
                              </th>
                              <th className="min-w-md-125px">Quantity</th>
                              <th className="min-w-md-125px">Delivered on</th>
                              <th className="min-w-md-125px min-w-100px">
                                Total amount
                              </th>
                              <th className="min-w-150px text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersJSON.map((val: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="fs-15 fw-500">
                                    <span className="text-dark d-block">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                    <span className="text-gray d-block">
                                      {val.orderId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500 d-block">
                                      {val.buyerDetails}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.quantity + ' items'}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {'TSh ' + val.totalAmount}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          '/orders-delivery/order-details'
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>

                <Tab.Pane
                  eventKey="6"
                  active={defaultValue === '6'}
                >
                  <Card className="border border-r10px">
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                          <thead>
                            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                              <th className="min-w-md-175px min-w-150px">
                                Order Date & ID
                              </th>
                              <th className="min-w-md-200px min-w-125px">
                                Customer name
                              </th>
                              <th className="min-w-md-125px">Quantity</th>
                              <th className="min-w-md-125px">Delivered on</th>
                              <th className="min-w-md-125px min-w-100px">
                                Total amount
                              </th>
                              <th className="min-w-150px text-end"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersJSON.map((val: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td className="fs-15 fw-500">
                                    <span className="text-dark d-block">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                    <span className="text-gray d-block">
                                      {val.orderId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500 d-block">
                                      {val.buyerDetails}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.quantity + ' items'}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {val.orderDate + ' - ' + val.orderTime}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-500">
                                      {'TSh ' + val.totalAmount}
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      className="fs-14 fw-600"
                                      onClick={() =>
                                        navigate(
                                          '/orders-delivery/order-details'
                                        )
                                      }
                                    >
                                      View details
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default OrdersDelivery;
