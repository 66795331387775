import clsx from 'clsx';
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials';
import { useAuth } from '../../../../app/modules/auth';
import NotificationSVG from '../../../assets/media/dashboard/notifications.svg';
import { useEffect, useRef, useState } from 'react';
import BellSVG from '../../../../_admin/assets/media/svg_uTrade/bell-icon.svg';
import APICallService from '../../../../api/apiCallService';
import { AUTH } from '../../../../api/apiEndPoints';
import { KTSVG } from '../../../helpers';
const itemClass = 'ms-1 ms-lg-3';
const btnClass = 'btn btn-icon btn-custom btn-icon-muted';
const userAvatarClass =
  'symbol-40px symbol-md-45px symbol-lg-50px symbol-circle';
const btnIconClass = 'svg-icon-1';
const Navbar = () => {
  // const { config } = useLayout()
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(false);
  const dropdownRef: any = useRef(null);
  useEffect(() => {
    (async () => {
      await fetchNotificationCount();
    })();
  }, []);
  const fetchNotificationCount = async () => {
    let apiService = new APICallService(AUTH.notificationCount);
    let response = await apiService.callAPI();
    if (response) {
      setCount(response.unreadItems.length ? true : false);
    }
  };
  const MarkAsRead = async () => {
    if (show === true) {
      let apiService = new APICallService(AUTH.readNotifications);
      let response = await apiService.callAPI();
      if (response) {
        await fetchNotificationCount();
      }
    }
  };
  useEffect(() => {
    (async () => {
      await MarkAsRead();
    })();
  }, [show]);
  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);
  return (
    <div className="app-navbar flex-shrink-0">
      <div
        className={clsx('app-navbar-item', itemClass)}
        ref={dropdownRef}
      >
        <div
          className={btnClass}
          onClick={() => {
            setShow(!show);
          }}
        >
          <div>
            <KTSVG
              path={BellSVG}
              className={btnIconClass}
            />
            {count ? (
              <span className="bullet bullet-dot bg-success h-9px w-9px position-absolute translate-end top-10 start-400 animation-blink"></span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <HeaderNotificationsMenu
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <div className="symbol  symbol-50px symbol-circle ">
            <div className="symbol-label fs-1 fw-bold bg-light-primary text-primary fw-700">
              {currentUser?.name.charAt(0).toUpperCase()}
            </div>
          </div>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};
export { Navbar };
