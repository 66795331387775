import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_admin/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_admin/assets/ts/_utils';
import { WithChildren } from '../../_admin/helpers';
import AllProducts from '../modules/all-products/index';
import Customers from '../modules/customers/index';
import Orders from '../modules/orders-delivery/index';
import SettingPages from '../modules/settings';
const FulfillmentAdminRoutes = () => {
  const SellerPage = lazy(() => import('../modules/seller/index'));
  const MasterPage = lazy(() => import('../modules/master/index'));
  const ProductPage = lazy(() => import('../modules/products/index'));
  const InventoryPage = lazy(() => import('../modules/Inventory/index'));
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path="auth/*"
          element={<Navigate to="/sellers" />}
        />
        {/* Pages */}
        <Route
          path="master/*"
          element={
            <SuspensedView>
              <MasterPage />
            </SuspensedView>
          }
        />
        <Route
          path="all-products/*"
          element={
            <SuspensedView>
              <AllProducts />
            </SuspensedView>
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <Orders />
            </SuspensedView>
          }
        />
        <Route
          path="products/*"
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path="sellers/*"
          element={
            <SuspensedView>
              <SellerPage />
            </SuspensedView>
          }
        />
        <Route
          path="customers/*"
          element={
            <SuspensedView>
              <Customers />
            </SuspensedView>
          }
        />
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <Orders />
            </SuspensedView>
          }
        />
        <Route
          path="inventory/*"
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingPages />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: { '0': baseColor },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
export { FulfillmentAdminRoutes };
