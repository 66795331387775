import { Button } from "react-bootstrap";
import { KTSVG } from "../../../../_admin/helpers";
import Loader from "../../../../Global/loader";
import Method from "../../../../utils/methods";
import { useState } from "react";
import ViewInquiries from "../../../modals/view-inquiries";
import Pagination from "../../../../Global/pagination";
const SellerContact = (props: any) => {
  const [showInquiries, setShowInquiries] = useState(false);
  const [inquiryData, setInquiryData] = useState();
  const [search, setSearch] = useState<string>("");
  const handleInquiryShow = (data: any) => {
    setInquiryData(data);
    setShowInquiries(true);
  };
  const handleClose = () => {
    setShowInquiries(!showInquiries);
    setInquiryData(undefined);
  };
  return (
    <>
      {showInquiries ? (
        <ViewInquiries
          show={showInquiries}
          onHide={() => handleClose()}
          inquiryData={inquiryData}
        />
      ) : (
        <></>
      )}
      {/* begin::Search */}
      <div className="position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-13"
        />
        <input
          type="text"
          className="form-control form-control-custom borde-r8px bg-light w-375px ps-11"
          name="Search Team"
          onChange={(event: any) => {
            props.handleSearch(event.target.value.trimStart(), "1");
          }}
          placeholder="Search by seller name…"
        />
      </div>
      {/* end::Search */}
      <div className="table-responsive">
        <table className="table table-rounded table-row-bordered align-middle gy-4">
          <thead>
            <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
              <th className="min-w-250px">Name</th>
              <th className="min-w-150px">Received on</th>
              <th className="min-w-180px"></th>
            </tr>
          </thead>
          <tbody>
            {props.fetchLoading ? (
              <>
                <td colSpan={3}>
                  <div className="w-100 d-flex justify-content-center">
                    <Loader loading={props.fetchLoading} />
                  </div>
                </td>
              </>
            ) : (
              <>
                {props.inquiries&&props.inquiries.length ? (
                  props.inquiries.map(
                    (inquiryVal: any, inquiryIndex: number) => {
                      return (
                        <tr>
                          <td>
                            <span className="fs-15 fw-500">
                              {inquiryVal.user.name}
                            </span>
                          </td>
                          <td>
                            <span className="fs-15 fw-500">
                              {Method.convertDateToDDMMYYYYHOURS(
                                inquiryVal._createdAt
                              )}
                            </span>
                          </td>
                          <td className="text-end">
                            <Button
                              onClick={() => handleInquiryShow(inquiryVal)}
                              variant="primary"
                              className="btn-md fs-14 fw-600"
                            >
                              View message{" "}
                            </Button>
                            {/* <Button variant='primary' className='btn-md fs-14 fw-600'>View message </Button> */}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                          No Data found
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {props.fetchLoading ? (
        <></>
      ) : (
        <>
          {props.inquiries.length ? (
            <>
              <Pagination
                totalRecords={props.total}
                currentPage={props.currentPage}
                handleCurrentPage={(event: any) => {
                  props.handleCurrentPage(event, "1");
                }}
                handleNextPage={(event: any) => {
                  props.handleNextPage(event, "1");
                }}
                handlePreviousPage={(event: any) => {
                  props.handlePreviousPage(event, "1");
                }}
                pageLimit={parseInt(props.pageLimit)}
                handlePageLimit={(event: any) => {
                  props.handlePageLimit(event, "1");
                }}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default SellerContact;
