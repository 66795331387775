import {
  ICreditManually,
  IInventoryStats,
  IListInventoryProduct,
  IListInventoryTransaction,
  IListVariantExpiringStock,
  IUpdateInventoryStatus,
  IUpdateVariantInventory,
  IUpdateVariantInventoryReminder,
  IVariantExpiringStockInfo,
  IAddLowStockList,
  ILowStockList,
  ILowStockReport,
} from '../../types';
export const inventoryJSON = {
  creditManually: ({ records }: ICreditManually) => {
    let temp: any = [];
    records.map(({ variant, batch, expiry, quantityTypes }) => {
      let data: any = { variant: variant, batch: batch, expiry: expiry };
      if (quantityTypes.length) {
        let quantityTypes: any = [];
        quantityTypes.map(({ stockCount, type }: any) => {
          quantityTypes.push({ stockCount: stockCount, type: type });
        });
        data = { ...data, quantityTypes: quantityTypes };
      }
      temp.push(data);
    });
    return { records: temp };
  },
  updateVariantInventory: ({ reason, records }: IUpdateVariantInventory) => {
    let temp: any = [];
    records.map(({ batch, expiry, quantityTypes }) => {
      let data: any = { batch: batch, expiry: expiry };
      if (quantityTypes.length) {
        let quantityTypes: any = [];
        quantityTypes.map(({ stockCount, type }: any) => {
          quantityTypes.push({ stockCount: stockCount, type: type });
        });
        data = { ...data, quantityTypes: quantityTypes };
      }
      temp.push(data);
    });
    return { reason: reason, records: temp };
  },
  updateVariantInventoryRemainder: ({
    days,
  }: IUpdateVariantInventoryReminder) => {
    return { days: days };
  },
  listInventoryProduct: ({
    limit,
    sortKey,
    sortOrder,
    state,
    pageNo,
    expiry,
    forecastedDays,
    business,
    viewRemovedOnly,
    searchTerm,
  }: IListInventoryProduct) => {
    let data: any = {
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      state: state ? state : '1',
      needCount: true,
      pageNo: pageNo,
      searchTerm: searchTerm,
    };
    if (viewRemovedOnly) {
      data = { ...data, viewRemovedOnly: viewRemovedOnly };
    } else {
      if (expiry) {
        data = { ...data, expiry: expiry };
      }
    }
    if (forecastedDays) {
      data = { ...data, forecastedDays: forecastedDays };
    }
    if (business) {
      data = { ...data, business: business };
    }
    return data;
  },
  lowStockList: ({
    limit,
    needCount,
    requiredStockFor,
    ordered,
    date,
    searchTerm,
    pageNo,
    sortKey,
    sortOrder,
  }: ILowStockList) => {
    let data: any = {
      limit: limit,
      needCount: true,
      ordered: ordered,
      searchTerm: searchTerm,
      pageNo: pageNo,
      sortKey: sortKey,
      sortOrder: sortOrder,
    };
    if (requiredStockFor) {
      data = { ...data, requiredStockFor: requiredStockFor };
    }
    if (date) {
      data = { ...data, date: date };
    }
    return data;
  },

  lowStockReport: ({ fromDate, toDate, ordered }: ILowStockReport) => {
    let data: any = { ordered: ordered };
    if (fromDate) {
      data = { ...data, fromDate: fromDate };
    }
    if (toDate) {
      data = { ...data, toDate: toDate };
    }
    return data;
  },

  inventoryStats: ({ business }: IInventoryStats) => {
    let data: any = {};
    if (business) {
      data = { ...data, business: business };
    }
    return data;
  },
  listInventoryTransaction: ({
    limit,
    sortKey,
    sortOrder,
    fromDate,
    toDate,
    pageNo,
    sourceTypes,
  }: IListInventoryTransaction) => {
    let data: any = {
      limit: limit,
      sortKey: sortKey,
      sortOrder: sortOrder,
      needCount: true,
      pageNo: pageNo,
    };
    if (fromDate && toDate) {
      data = { ...data, fromDate: fromDate, toDate: toDate };
    }
    if (sourceTypes) {
      data = { ...data, sourceTypes: sourceTypes };
    }
    return data;
  },
  variantExpiringStockInfo: ({ variant }: IVariantExpiringStockInfo) => {
    let data: any = {};
    if (variant) {
      data = { ...data, variant: variant };
    }
    return data;
  },
  listVariantExpiringStock: ({ variant }: IListVariantExpiringStock) => {
    let data: any = {};
    if (variant) {
      data = { ...data, variant: variant };
    }
    return data;
  },
  updateInventoryStatus: ({ status, variants }: IUpdateInventoryStatus) => {
    return { status: status };
  },
  addProductToLowStockList: ({ variants, forecastDays }: IAddLowStockList) => {
    return { variants: variants, forecastDays: forecastDays };
  },
};

