import { createRoot } from 'react-dom/client';
// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { UTradeAdminI18nProvider } from './_admin/i18n/UTradeAdmin18n';
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_admin/assets/css/style.rtl.css'
 **/
import './_admin/assets/sass/style.scss';
import './_admin/assets/sass/plugins.scss';
import './_admin/assets/sass/style.react.scss';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider } from './app/modules/auth';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
// import setupLocatorUI from '@locator/runtime';
import store from './redux/sellerStore';
// setupAxios(axios)
Chart.register(...registerables);
const queryClient = new QueryClient();
const container = document.getElementById('root');
// setupLocatorUI();
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <UTradeAdminI18nProvider>
        <AuthProvider>
          <ToastContainer />
          <Provider store={store}>
            <AppRoutes />
          </Provider>
        </AuthProvider>
      </UTradeAdminI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
