import { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import Commission from './commission';
import OtherFees from './otherFees';
import { getKey, setKey } from '../../../Global/history';
import { listPlatformFees } from '../../../utils/storeString';
const PlatformFees = () => {
  const [active, setActive] = useState(
    getKey(listPlatformFees.currentTab)
      ? () => {
          let temp: any = getKey(listPlatformFees.currentTab);
          return parseInt(temp);
        }
      : 1
  );
  const handleActive = (event: any) => {
    setKey(listPlatformFees.currentTab, parseInt(event));
    setActive(event);
  };
  return (
    <div className="px-9 py-8">
      <Row>
        <Col
          lg={12}
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">Platform fees</h1>
        </Col>
        <Col
          lg={12}
          className="custom-tabContainer"
        >
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={active}
          >
            <Row className="variant-categories g-6">
              <Col lg={'auto'}>
                <div className="bg-light border border-r8px p-3 px-md-6">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="1"
                        onClick={() => {
                          handleActive(1);
                        }}
                        active={active === 1}
                      >
                        Commission fees
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="2"
                        onClick={() => {
                          handleActive(2);
                        }}
                        active={active === 2}
                      >
                        Other fees
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="1"
                    active={active.toString() === '1'}
                  >
                    <Commission />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="2"
                    active={active.toString() === '2'}
                  >
                    <OtherFees />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
};
export default PlatformFees;
