/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
// import ApexCharts, { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { getCSSVariableValue } from '../../../../_admin/assets/ts/_utils';
import { useThemeMode } from '../../../../_admin/partials';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import CustomDatePicker from '../../../custom/DateRange/DatePicker';
import green from '../../../../_admin/assets/media/svg_uTrade/green_dot.svg';
import blue from '../../../../_admin/assets/media/svg_uTrade/blue.svg';
import {
  customerService,
  dashboard,
  master,
} from '../../../../api/apiEndPoints';
import APICallService from '../../../../api/apiCallService';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import { error } from '../../../../Global/toast';
import moment from 'moment';
const SalesReportChart = () => {
  const depth = 1;
  const baseColor = getCSSVariableValue('--bs-primary');
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [salesData, setSalesData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(
    new Date().getMonth() + 1 ==
      new Date(new Date().setDate(new Date().getDate() - 7)).getMonth() + 1
      ? new Date(new Date().setDate(new Date().getDate() - 7))
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState<any>(
    new Date().getMonth() + 1 ==
      new Date(new Date().setDate(new Date().getDate() - 7)).getMonth() + 1
      ? new Date()
      : new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [startMonthSeries, setStartMonthSeries] = useState<any>([]);
  const [endMonthSeries, setEndMonthSeries] = useState<any>([]);
  const [active, setActive] = useState(-1);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startMonth = moment(new Date(startDate)).format('MM');
    const endMonth = moment(new Date(endDate)).format('MM');
    if (startDate && endDate) {
      if (startMonth === endMonth) {
        if (Method.dayDifference(startDate, endDate) >= 6) {
          await fetchReports(
            selectedCategory,
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict
          );
        } else {
          error(
            'Please select a date range with a minimum one-week difference'
          );
        }
      } else {
        error('Please select a date range within a single month');
      }
    }
  };
  useEffect(() => {
    (async () => {
      await fetchCategory(depth);
      await fetchReports(
        selectedCategory,
        startDate,
        endDate,
        selectedDistrict
      );
      await fetchDistrict();
    })();
  }, []);
  const fetchDistrict = async () => {
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params);
    let response = await apiService.callAPI();
    let data: any = [];
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      });
    });
    setDistricts(data);
  };
  const fetchCategory = async (categoriesDepth: number) => {
    setCategoryLoading(true);
    let params = {
      sortKey: 'title',
      sortOrder: 1,
      categoriesDepth: categoriesDepth,
    };
    let apiService = new APICallService(master.categoryList, {
      sortKey: 'title',
      sortOrder: 1,
      categoriesDepth: categoriesDepth,
    });
    let response = await apiService.callAPI();
    setCategory(response.records);
    setCategoryLoading(false);
    setActive(-1);
  };
  const fetchReports = async (
    categories: any,
    fromDate: any,
    toDate: any,
    district: any
  ) => {
    setLoading(true);
    let params: any = {
      sortKey: 'title',
      sortOrder: 1,
      fromDate1: Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
      toDate1: Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
      fromDate2: Method.convertToPreviousMonth(
        Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
        'YYYY-MM-DD'
      ),
      toDate2: Method.convertToPreviousMonth(
        Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
        'YYYY-MM-DD'
      ),
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (categories !== undefined && categories) {
      params = { ...params, ['categories[' + 0 + ']']: categories };
    }
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    let apiService = new APICallService(dashboard.salesReport, params);
    let response = await apiService.callAPI();
    // if (response.line2.data.length === 0) {
    //   response.line2.data = [
    //     {
    //       date: Method.previousMonthStartDate(
    //         Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
    //         'YYYY-MM-DD'
    //       ),
    //       totalSales: 0,
    //     },
    //   ];
    // } else {
    // }
    if (response.line1.data.length) {
      let line1Dates = new Set(
        response.line1.data.map((entry: any) => entry.date)
      );
      let line2Dates = new Set(
        response.line2.data.map((entry: any) => entry.date)
      );
      // Find unique dates across both lines
      let allDates2 = new Set([...line1Dates, ...line2Dates]);
      // Add zero totalSales for missing dates in line1
      allDates2.forEach((date: any) => {
        if (!line1Dates.has(date)) {
          response.line1.data.push({ date: date, totalSales: 0 });
        }
      });
      // Add zero totalSales for missing dates in line2
      allDates2.forEach((date: any) => {
        if (!line2Dates.has(date)) {
          response.line2.data.push({ date: date, totalSales: 0 });
        }
      });
      for (let i = 0; i < response.line1.data.length; i++) {
        response.line1.data[i].date = response.line1.data[i].date.replace(
          /-..-/,
          `-${`${Method.convertDateToFormat(fromDate, 'MM')}`}-`
        );
      }
      for (let i = 0; i < response.line2.data.length; i++) {
        response.line2.data[i].date = response.line2.data[i].date.replace(
          /-..-/,
          `-${`${Method.convertToPreviousMonth(
            Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
            'MM'
          )}`}-`
        );
      }
      for (let lineKey in response) {
        let line = response[lineKey];
        let uniqueDates: any = {};
        line.data.forEach((item: any) => {
          let date = item.date;
          let sales = item.totalSales;
          if (
            !(date in uniqueDates) ||
            (date in uniqueDates &&
              uniqueDates[date].totalSales === 0 &&
              sales > 0)
          ) {
            uniqueDates[date] = { date, totalSales: sales };
          }
        });
        line.data = Object.values(uniqueDates);
      }
      response.line2.data = Method.populateMissingDates(
        response.line2.data,
        Method.convertToPreviousMonth(
          Method.convertDateToFormat(fromDate, 'YYYY-MM-DD'),
          'DD'
        ),
        Method.convertToPreviousMonth(
          Method.convertDateToFormat(toDate, 'YYYY-MM-DD'),
          'DD'
        )
      );
      response.line1.data = Method.populateMissingDates(
        response.line1.data,
        Method.convertDateToFormat(fromDate, 'DD'),
        Method.convertDateToFormat(toDate, 'DD')
      );
    }
    const allDatesSet = new Set([
      ...response.line1.data.map((entry: any) => entry.date),
    ]);
    const allDates: any = Array.from(allDatesSet);
    setCategories(allDates);
    setStartMonthSeries(response.line1);
    if (response.line2.data.length) {
      setEndMonthSeries(response.line2);
    }
    if (response.line1.data.length) {
      setSalesData(response);
    } else {
      setSalesData(null);
    }
    setLoading(false);
  };
  const handleCategoryFilter = async (event: any) => {
    setSelectedCategory(event);
    await fetchReports(event, startDate, endDate, selectedDistrict);
  };
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event);
      await fetchReports(selectedCategory, startDate, endDate, event);
    } else {
      setSelectedDistrict(undefined);
      await fetchReports(selectedCategory, startDate, endDate, undefined);
    }
  };
  return (
    <>
      <Card className="mb-7 bg-f9f9f9">
        <Card.Header className="pt-7 border-bottom-0">
          <div className="card-title">
            <h2 className="fs-22 fw-bolder">Sale report</h2>
          </div>
          <div className="card-toolbar">
            <div className="min-w-md-193px">
              <CustomSelect
                backgroundColor="#ffff"
                minHeight="50px"
                disabled={loading}
                default={selectedDistrict}
                options={districts}
                loadingMessage={'Fetching Data'}
                isClearable={true}
                onChange={(e: any) => handleDistrictChange(e)}
              />
            </div>
            <div className="ms-3">
              <CustomDatePicker
                className="bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px"
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="1"
          >
            {!categoryLoading ? (
              <>
                {category.length ? (
                  <>
                    <Nav
                      variant="pills"
                      className="nav-pills-custom mb-3"
                    >
                      <div className="overflow-x-scroll pb-5">
                        <div className="d-flex flex-row gap-3">
                          <Nav.Item>
                            <Nav.Link
                              eventKey={-1}
                              className="btn btn-outline btn-flex bg-white border btn-active-color-primary flex-column overflow-hidden w-126px h-122px pt-6 pb-2"
                              onClick={async () => {
                                setActive(-1);
                                setSelectedCategory(null);
                                await fetchReports(
                                  null,
                                  startDate,
                                  endDate,
                                  selectedDistrict
                                );
                              }}
                              active={active == -1}
                            >
                              <div className="nav-icon mb-4">
                                <div className="symbol symbol-50px border-r8px">
                                  <div className="symbol-label fs-22 fw-600 bg-e7f1fd text-primary">
                                    {category.length}
                                  </div>
                                </div>
                              </div>
                              <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                                All categories
                              </span>
                              <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                            </Nav.Link>
                          </Nav.Item>
                          {category.map((val: any, index: number) => {
                            return (
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={index}
                                  onClick={() => {
                                    setActive(index);
                                    handleCategoryFilter(val._id);
                                  }}
                                  active={active == index}
                                  className="btn btn-outline btn-flex bg-white border btn-active-color-primary flex-column overflow-hidden w-126px h-130px pt-6 pb-2"
                                >
                                  <div className="nav-icon mb-4">
                                    <div className="symbol symbol-50px border border-r8px">
                                      <img
                                        className="w-100 object-fit-contain"
                                        src={val.image}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                                    {val.title}
                                  </span>
                                  <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </div>
                      </div>
                    </Nav>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <Loader loading={categoryLoading} />
                </div>
              </>
            )}
            {/* Tab content */}
            <Tab.Content className="pt-2">
              <Tab.Pane
                eventKey={active}
                active={true}
              >
                {!loading ? (
                  <>
                    {salesData && Object.keys(salesData).length ? (
                      <>
                        <Row className="g-5">
                          <Col
                            xs="auto"
                            xl={3}
                          >
                            <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                              <span className="fs-22 fw-bold">
                                TSh{' '}
                                {Method.getGeneralizedAmount(
                                  parseInt(salesData.line1.totalSales)
                                )}
                              </span>
                              <div className="fs-16 fw-500 text-gray-400">
                                Total sales
                              </div>
                            </div>
                          </Col>
                          <Col
                            xs="auto"
                            xl={3}
                          >
                            <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                              <span className="fs-22 fw-bold">
                                TSh{' '}
                                {Method.getGeneralizedAmount(
                                  parseInt(salesData.line1.totalCommission)
                                )}
                              </span>
                              <div className="fs-16 fw-500 text-gray-400">
                                Total earning
                              </div>
                            </div>
                          </Col>
                          {selectedCategory ? (
                            <></>
                          ) : (
                            <>
                              {' '}
                              <Col
                                xs="auto"
                                xl={3}
                              >
                                <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                                  <span className="fs-22 fw-bold">
                                    TSh{' '}
                                    {Method.getGeneralizedAmount(
                                      parseInt(salesData.line1.payoutAmount)
                                    )}
                                  </span>
                                  <div className="fs-16 fw-500 text-gray-400">
                                    Total payments to sellers
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                          {selectedCategory ? (
                            <></>
                          ) : (
                            <>
                              {' '}
                              <Col
                                xs="auto"
                                xl={3}
                              >
                                <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                                  <span className="fs-22 fw-bold">
                                    TSh{' '}
                                    {Method.getGeneralizedAmount(
                                      parseInt(salesData.line1.totalSales) -
                                        parseInt(
                                          salesData.line1.totalCommission
                                        ) -
                                        parseInt(salesData.line1.payoutAmount)
                                    )}
                                  </span>
                                  <div className="fs-16 fw-500 text-gray-400">
                                    Total pending payments
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                          <Col
                            xs="auto"
                            xl={3}
                          >
                            <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                              <span className="fs-22 fw-bold">
                                {parseInt(salesData.line1.totalOrders)}{' '}
                                {parseInt(salesData.line1.totalOrders) > 1
                                  ? 'orders'
                                  : 'order'}
                              </span>
                              <div className="fs-16 fw-500 text-gray-400">
                                Total orders
                              </div>
                            </div>
                          </Col>
                          <Col
                            xs="auto"
                            xl={3}
                          >
                            <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                              <span className="fs-22 fw-bold">
                                {' '}
                                {parseInt(
                                  salesData.line1.totalCancelledOrders
                                )}{' '}
                                {parseInt(
                                  salesData.line1.totalCancelledOrders
                                ) > 1
                                  ? 'orders'
                                  : 'order'}
                              </span>
                              <div className="fs-16 fw-500 text-gray-400">
                                Total cancelled orders
                              </div>
                            </div>
                          </Col>
                          {selectedCategory ? (
                            <></>
                          ) : (
                            <>
                              <Col
                                xs="auto"
                                xl={3}
                              >
                                <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                                  <span className="fs-22 fw-bold">
                                    TSh{' '}
                                    {Method.getGeneralizedAmount(
                                      parseInt(salesData.line1.totalRefunds)
                                    )}
                                  </span>
                                  <div className="fs-16 fw-500 text-gray-400">
                                    Total refunds to customers
                                  </div>
                                </div>
                              </Col>
                            </>
                          )}
                          {/* <Col
                            xs="auto"
                            xl={3}
                          >
                            <div className="sales-report-box border bg-white border-r8px min-w-100px min-h-md-auto min-h-lg-115px w-100 p-5">
                              <span className="fs-22 fw-bold">
                                TSh{' '}
                                {Method.getGeneralizedAmount(
                                  parseInt(salesData.line1.totalLoss)
                                )}
                              </span>
                              <div className="fs-16 fw-500 text-gray-400">
                                Total loss
                              </div>
                            </div>
                          </Col> */}
                          <Chart
                            options={{
                              chart: {
                                type: 'area' as 'area',
                                fontFamily: 'inherit',
                                height: '275',
                                toolbar: { show: false },
                                dropShadow: { enabled: false },
                              },
                              dataLabels: {
                                enabled: false,
                              },
                              stroke: {
                                curve: 'smooth',
                                show: true,
                                width: 3,
                                colors: [baseColor, '#4fbf26'],
                              },
                              legend: { show: false },
                              xaxis: {
                                type: 'datetime',
                                tickAmount: 10,
                                categories: categories,
                                axisBorder: {
                                  show: false,
                                },
                                axisTicks: {
                                  show: false,
                                },
                                labels: {
                                  style: {
                                    colors: '#1a1a1a',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                  },
                                },
                                tooltip: {
                                  enabled: false,
                                },
                              },
                              yaxis: [
                                {
                                  seriesName: '',
                                  opposite: true,
                                  labels: {
                                    show: false,
                                    align: 'center',
                                    style: {
                                      colors: '#7c7c7c',
                                      fontSize: '12px',
                                    },
                                    formatter: function (value) {
                                      return Method.getGeneralizedAmount(value); // Adjust locale as needed
                                    },
                                  },
                                },
                                {
                                  seriesName: '',
                                  labels: {
                                    align: 'center',
                                    style: {
                                      colors: '#7c7c7c',
                                      fontSize: '12px',
                                    },
                                    formatter: function (value) {
                                      return Method.getGeneralizedAmount(value); // Adjust locale as needed
                                    },
                                  },
                                },
                              ],
                              tooltip: {
                                enabled: true,
                                custom: function ({
                                  series,
                                  seriesIndex,
                                  dataPointIndex,
                                  w,
                                }) {
                                  const monthData =
                                    startMonthSeries.data[dataPointIndex];
                                  const endMonthData =
                                    endMonthSeries.data[dataPointIndex];
                                  return `<div style='width:164px !important'>
                                  ${
                                    Method.convertDateToDDMMYYYY(
                                      monthData.date
                                    ) !== 'Invalid date'
                                      ? ` <div class="p-2 mb-0"><span class="fw-500 fs-16 text-dark ms-2 mb-2">${
                                          Method.convertDateToDDMMYYYY(
                                            monthData.date
                                          ) !== 'Invalid date'
                                            ? monthData.fullDate
                                            : '-'
                                        }</span><div class="d-flex align-items-center ms-2 mb-0 mt-2"><img src=${blue}></img><span class="ms-2 fw-500 fs-16 text-dark"> TSh  ${Method.getGeneralizedAmount(
                                          monthData.totalSales
                                        )}</span> </div>
                                    </div>`
                                      : ''
                                  }
                                    ${
                                      Method.convertDateToDDMMYYYY(
                                        endMonthData.date
                                      ) !== 'Invalid date'
                                        ? `<div class='separator my-2'></div>
            <div class="p-2 mb-0 ms-2 mb-2"><span class="fw-500 fs-16 text-dark">${
              Method.convertDateToDDMMYYYY(endMonthData.date) !== 'Invalid date'
                ? endMonthData.fullDate
                : '-'
            }</span><div class="d-flex align-items-center ms-2 mb-0 mt-2"><img src=${green}></img><span class="ms-2 fw-500 fs-16 text-dark"> ${
                                            Method.convertDateToDDMMYYYY(
                                              endMonthData.date
                                            ) !== 'Invalid date'
                                              ? 'TSh ' +
                                                Method.getGeneralizedAmount(
                                                  endMonthData.totalSales
                                                )
                                              : '-'
                                          } 
                                  </span> </div>`
                                        : ` `
                                    }
           </div>
                             `;
                                },
                              },
                              colors: [baseColor, '#4fbf26'],
                              grid: {
                                borderColor: '#e0e0df',
                                strokeDashArray: 6,
                                yaxis: {
                                  lines: {
                                    show: true,
                                  },
                                },
                              },
                              fill: {
                                type: 'solid',
                                opacity: 0.05,
                              },
                              markers: {
                                size: 0,
                                colors: undefined,
                                strokeColors: [baseColor, '#4fbf26'],
                                strokeWidth: 8,
                                strokeOpacity: 1,
                                strokeDashArray: 0,
                                fillOpacity: 1,
                                shape: 'circle',
                                radius: 0,
                                onClick: undefined,
                                onDblClick: undefined,
                                showNullDataPoints: false,
                                hover: {
                                  size: undefined,
                                  sizeOffset: 3,
                                },
                              },
                            }}
                            series={[
                              {
                                name: '',
                                data: startMonthSeries.data.map((val: any) => {
                                  return val.totalSales;
                                }),
                              },
                              {
                                name: '',
                                data: endMonthSeries.data.map((val: any) => {
                                  return val.totalSales;
                                }),
                              },
                            ]}
                            type="area"
                            height="350"
                          />
                        </Row>
                      </>
                    ) : (
                      <>
                        {' '}
                        <div className="d-flex flex-center min-h-140px">
                          <span className="fs-18 fw-medium">
                            No details available for these dates.
                          </span>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-center min-h-140px">
                      <Loader loading={loading} />
                    </div>
                  </>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={active}></Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </>
  );
};
export default SalesReportChart;
