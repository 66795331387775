import clsx from "clsx";
export const Notification = (props: any) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center p-5 ${props.bgColor}`}
    >
      {props.icon ? (
        <div className="w-20px me-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g data-name="Layer 35">
              <path
                data-name="Path 16426"
                d="M13.4 3.4a10 10 0 1 0 10 10 10 10 0 0 0-10-10zm0 17.2a1.511 1.511 0 1 1 1.507-1.5 1.511 1.511 0 0 1-1.507 1.5zm1.829-12.445-.57 7.692a.21.21 0 0 1-.21.2h-2.1a.21.21 0 0 1-.21-.2l-.57-7.692a1.832 1.832 0 1 1 3.657 0z"
                transform="translate(-3.4 -3.4)"
                style={{ fill: props.iconColor }}
              />
            </g>
          </svg>
        </div>
      ) : (
        <></>
      )}
      <span className={`fs-16 fw-600 ${props.textColor}`}>{props.text}</span>
    </div>
  );
};
export default Notification;
