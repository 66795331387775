import { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { CustomSelect } from '../../../../custom/Select/CustomSelect';
import APICallService from '../../../../../api/apiCallService';
import { seller } from '../../../../../api/apiEndPoints';
import {
  AddedOnOrderCancellation,
  AddedOnOrderModification,
  AddedOnReturn,
  CartonWithDozens,
  CartonWithPieces,
  DeductedOnDamage,
  DeductedOnMissing,
  DeductedOnOrderModification,
  DeductedOnReturnToSeller,
  DeductedOnSale,
  DirectlyReceived,
  Dozen,
  ManuallyAdded,
  ManuallyDeducted,
  PAGE_LIMIT,
  Piece,
  ShipmentThroughAdded,
} from '../../../../../utils/constants';
import { operationJSON } from '../../../../../utils/staticJSON';
import CustomDatePicker from '../../../../custom/DateRange/DatePicker';
import Method from '../../../../../utils/methods';
import Loader from '../../../../../Global/loader';
import clsx from 'clsx';
import Pagination from '../../../../../Global/pagination';
import { listSellerHistoryOfInventory } from '../../../../../utils/storeString';
import { getKey, setKey } from '../../../../../Global/history';
const SellerInventoryHistory = (props: any) => {
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [history, setHistory] = useState<any>([]);
  const [endDate, setEndDate] = useState<any>();
  const [page, setPage] = useState<any>(
    getKey(listSellerHistoryOfInventory.page) || 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listSellerHistoryOfInventory.limit) || PAGE_LIMIT
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState<any>([]);
  const [expiryModal, setExpiryModal] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails(page, pageLimit, startDate, endDate, search);
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async (
    pageNo: number,
    limit: number,
    fromDate?: string,
    toDate?: string,
    sourceTypes?: any
  ) => {
    setLoading(true);
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: '_createdAt',
      sortOrder: -1,
      needCount: true,
      fromDate: fromDate ? fromDate : '',
      toDate: toDate ? toDate : '',
    };
    if (sourceTypes.length > 0) {
      sourceTypes.map((val: any, index: number) => {
        params = { ...params, ['sourceTypes[' + index + ']']: val };
      });
    }
    let apiService = new APICallService(seller.listInventoryHistory, params, {
      id: props.businessId,
    });
    let response = await apiService.callAPI();
    if (response.records.length) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setHistory(response.records);
    }
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listSellerHistoryOfInventory.page, val);
    await fetchDetails(val, pageLimit, startDate, endDate, search);
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listSellerHistoryOfInventory.page, val + 1);
    await fetchDetails(val + 1, pageLimit, startDate, endDate, search);
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listSellerHistoryOfInventory.page, val - 1);
    await fetchDetails(val - 1, pageLimit, startDate, endDate, search);
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    setKey(listSellerHistoryOfInventory.page, 1);
    setKey(listSellerHistoryOfInventory.limit, parseInt(event.target.value));
    await fetchDetails(1, event.target.value, startDate, endDate, search);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setHistory([]);
      setTotalRecords(0);
      await fetchDetails(
        page,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        search
      );
    } else {
      await fetchDetails(page, pageLimit, '', '', search);
    }
  };
  const handleStatusFilter = async (value: string) => {
    let temp: any = [];
    setHistory([]);
    setTotalRecords(0);
    if (value === '1') {
      temp = ['c1', 'c3', 'c4'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '2') {
      temp = ['c2'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '3') {
      temp = ['d2'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '4') {
      temp = ['d3', 'd4', 'd5'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    if (value === '5') {
      temp = ['d1'];
      await fetchDetails(1, pageLimit, startDate, endDate, temp);
    }
    setSearch(temp);
  };
  const getTypeLabel = (type: string) => {
    switch (type) {
      case ShipmentThroughAdded:
      case ManuallyAdded:
      case DirectlyReceived:
      case DeductedOnOrderModification:
        return 'New Stock Updated';
      case AddedOnReturn:
        return 'Customer return';
      case DeductedOnSale:
        return 'New order';
      case DeductedOnReturnToSeller:
        return 'Goods return request';
      case ManuallyDeducted:
      case DeductedOnMissing:
      case DeductedOnDamage:
        return 'Missing/Damaged goods';
      case AddedOnOrderCancellation:
      case AddedOnOrderModification:
        return 'Order Modified';
      default:
        return '';
    }
  };
  return (
    <>
      {fetchLoader ? (
        <>
          <Row className="mt-4">
            <Col
              sm
              className="fs-22 fw-bolder"
            >
              History of inventory
            </Col>
            <Col xs={12}>
              <Card className="border border-r10px">
                <Card.Body className="p-0 ">
                  <Row className="align-items-center h-250px">
                    <div className="d-flex justify-content-center">
                      <Loader loading={fetchLoader} />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-4">
            <Col
              sm
              className="fs-22 fw-bolder"
            >
              History of inventory
            </Col>
            <Col xs={12}>
              <Card className="bg-light border mb-7">
                <Card.Body className="px-7">
                  <Row className="align-items-center">
                    <Col
                      lg={4}
                      md={6}
                    >
                      <Form.Control
                        className="form-control form-control-custom bg-white min-h-60px"
                        placeholder="Search by product name…"
                      />
                    </Col>
                    <Col
                      lg={4}
                      md={6}
                    >
                      <CustomSelect
                        options={operationJSON}
                        backgroundColor="white"
                        onChange={(event: any) => {
                          handleStatusFilter(event.value);
                        }}
                      />
                    </Col>
                    <Col
                      lg={4}
                      md={6}
                    >
                      <div className="d-flex align-items-center">
                        <div className="">
                          <CustomDatePicker
                            className="form-control bg-white min-h-60px fs-16 fw-bold text-dark min-w-md-288px min-w-100px"
                            selected={startDate}
                            onChange={handleChange}
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                            maxDate={new Date()}
                            inputTextBG="bg-white"
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card className="border border-r10px">
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table align-middle table-rounded table-row-bordered gs-7 gy-6 mb-0 no-footer">
                      <thead>
                        <tr className="text-start fw-bold fs-16 gs-0 border-bottom">
                          <th className="min-w-175px">Date & Time</th>
                          <th className="min-w-300px">Product name</th>
                          <th className="min-w-50px">Carton</th>
                          <th className="min-w-50px">Dozen</th>
                          <th className="min-w-50px">Pieces</th>
                          <th className="min-w-150px">Operation type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <>
                            <td colSpan={4}>
                              <div className="w-100 d-flex justify-content-center">
                                <Loader loading={loading} />
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            {history && history.length ? (
                              <>
                                {history.map((historyVal: any) => {
                                  return (
                                    <tr className="odd">
                                      <td className="fs-15 fw-600">
                                        <span className="text-dark d-block">
                                          {Method.convertDateToDDMMYYYYHHMM(
                                            historyVal._createdAt,
                                            ' - '
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center mw-300px">
                                          <div className="symbol symbol-50px border me-3">
                                            <img
                                              src={
                                                historyVal.variant.media[0].url
                                              }
                                              className="object-fit-contain"
                                              alt=""
                                            />
                                          </div>
                                          <span className="fs-15 fw-600">
                                            {historyVal.variant.title.replace(
                                              /\s*\)\s*/g,
                                              ')'
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        {historyVal.quantityTypes.some(
                                          (item: any, idx: number) =>
                                            item.type === CartonWithDozens ||
                                            item.type === CartonWithPieces
                                        ) ? (
                                          <span
                                            className={clsx(
                                              'fs-15 fw-600 d-block',
                                              historyVal.source.type ===
                                                ShipmentThroughAdded ||
                                                historyVal.source.type ===
                                                  ManuallyAdded ||
                                                historyVal.source.type ===
                                                  DirectlyReceived ||
                                                historyVal.source.type ===
                                                  AddedOnReturn ||
                                                historyVal.source.type ===
                                                  AddedOnOrderCancellation ||
                                                historyVal.source.type ===
                                                  AddedOnOrderModification
                                                ? 'text-success'
                                                : '',
                                              historyVal.source.type ===
                                                ManuallyDeducted ||
                                                historyVal.source.type ===
                                                  DeductedOnMissing ||
                                                historyVal.source.type ===
                                                  DeductedOnDamage ||
                                                historyVal.source.type ===
                                                  DeductedOnReturnToSeller ||
                                                historyVal.source.type ===
                                                  DeductedOnSale ||
                                                historyVal.source.type ===
                                                  DeductedOnOrderModification
                                                ? 'text-danger'
                                                : ''
                                            )}
                                          >
                                            {historyVal.source.type ===
                                              ShipmentThroughAdded ||
                                            historyVal.source.type ===
                                              ManuallyAdded ||
                                            historyVal.source.type ===
                                              DirectlyReceived ||
                                            historyVal.source.type ===
                                              AddedOnReturn ||
                                            historyVal.source.type ===
                                              AddedOnOrderCancellation ||
                                            historyVal.source.type ===
                                              AddedOnOrderModification
                                              ? '+'
                                              : ''}
                                            {historyVal.source.type ===
                                              ManuallyDeducted ||
                                            historyVal.source.type ===
                                              DeductedOnMissing ||
                                            historyVal.source.type ===
                                              DeductedOnDamage ||
                                            historyVal.source.type ===
                                              DeductedOnReturnToSeller ||
                                            historyVal.source.type ===
                                              DeductedOnSale ||
                                            historyVal.source.type ===
                                              DeductedOnOrderModification
                                              ? '-'
                                              : ''}{' '}
                                            {
                                              historyVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type ===
                                                    CartonWithDozens ||
                                                  item.type === CartonWithPieces
                                              ).stockCount
                                            }{' '}
                                          </span>
                                        ) : (
                                          <span className="fs-15 fw-600  d-block">
                                            -
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {historyVal.quantityTypes.some(
                                          (item: any, idx: number) =>
                                            item.type === Dozen
                                        ) ? (
                                          <span
                                            className={clsx(
                                              'fs-15 fw-600 d-block',
                                              historyVal.source.type ===
                                                ShipmentThroughAdded ||
                                                historyVal.source.type ===
                                                  ManuallyAdded ||
                                                historyVal.source.type ===
                                                  DirectlyReceived ||
                                                historyVal.source.type ===
                                                  AddedOnReturn ||
                                                historyVal.source.type ===
                                                  AddedOnOrderCancellation ||
                                                historyVal.source.type ===
                                                  AddedOnOrderModification
                                                ? 'text-success'
                                                : '',
                                              historyVal.source.type ===
                                                ManuallyDeducted ||
                                                historyVal.source.type ===
                                                  DeductedOnMissing ||
                                                historyVal.source.type ===
                                                  DeductedOnDamage ||
                                                historyVal.source.type ===
                                                  DeductedOnReturnToSeller ||
                                                historyVal.source.type ===
                                                  DeductedOnSale ||
                                                historyVal.source.type ===
                                                  DeductedOnOrderModification
                                                ? 'text-danger'
                                                : ''
                                            )}
                                          >
                                            {historyVal.source.type ===
                                              ShipmentThroughAdded ||
                                            historyVal.source.type ===
                                              ManuallyAdded ||
                                            historyVal.source.type ===
                                              DirectlyReceived ||
                                            historyVal.source.type ===
                                              AddedOnReturn ||
                                            historyVal.source.type ===
                                              AddedOnOrderCancellation ||
                                            historyVal.source.type ===
                                              AddedOnOrderModification
                                              ? '+'
                                              : ''}
                                            {historyVal.source.type ===
                                              ManuallyDeducted ||
                                            historyVal.source.type ===
                                              DeductedOnMissing ||
                                            historyVal.source.type ===
                                              DeductedOnDamage ||
                                            historyVal.source.type ===
                                              DeductedOnReturnToSeller ||
                                            historyVal.source.type ===
                                              DeductedOnSale ||
                                            historyVal.source.type ===
                                              DeductedOnOrderModification
                                              ? '-'
                                              : ''}{' '}
                                            {
                                              historyVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type === Dozen
                                              ).stockCount
                                            }{' '}
                                          </span>
                                        ) : (
                                          <span className="fs-15 fw-600  d-block">
                                            -
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {historyVal.quantityTypes.some(
                                          (item: any, idx: number) =>
                                            item.type === Piece
                                        ) ? (
                                          <span
                                            className={clsx(
                                              'fs-15 fw-600 d-block',
                                              historyVal.source.type ===
                                                ShipmentThroughAdded ||
                                                historyVal.source.type ===
                                                  ManuallyAdded ||
                                                historyVal.source.type ===
                                                  DirectlyReceived ||
                                                historyVal.source.type ===
                                                  AddedOnReturn ||
                                                historyVal.source.type ===
                                                  AddedOnOrderCancellation ||
                                                historyVal.source.type ===
                                                  AddedOnOrderModification
                                                ? 'text-success'
                                                : '',
                                              historyVal.source.type ===
                                                ManuallyDeducted ||
                                                historyVal.source.type ===
                                                  DeductedOnMissing ||
                                                historyVal.source.type ===
                                                  DeductedOnDamage ||
                                                historyVal.source.type ===
                                                  DeductedOnReturnToSeller ||
                                                historyVal.source.type ===
                                                  DeductedOnSale ||
                                                historyVal.source.type ===
                                                  DeductedOnOrderModification
                                                ? 'text-danger'
                                                : ''
                                            )}
                                          >
                                            {historyVal.source.type ===
                                              ShipmentThroughAdded ||
                                            historyVal.source.type ===
                                              ManuallyAdded ||
                                            historyVal.source.type ===
                                              DirectlyReceived ||
                                            historyVal.source.type ===
                                              AddedOnReturn ||
                                            historyVal.source.type ===
                                              AddedOnOrderCancellation ||
                                            historyVal.source.type ===
                                              AddedOnOrderModification
                                              ? '+'
                                              : ''}
                                            {historyVal.source.type ===
                                              ManuallyDeducted ||
                                            historyVal.source.type ===
                                              DeductedOnMissing ||
                                            historyVal.source.type ===
                                              DeductedOnDamage ||
                                            historyVal.source.type ===
                                              DeductedOnReturnToSeller ||
                                            historyVal.source.type ===
                                              DeductedOnSale ||
                                            historyVal.source.type ===
                                              DeductedOnOrderModification
                                              ? '-'
                                              : ''}{' '}
                                            {
                                              historyVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type === Piece
                                              ).stockCount
                                            }{' '}
                                          </span>
                                        ) : (
                                          <span className="fs-15 fw-600  d-block">
                                            -
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="badge badge-light border-r23px">
                                          <span className="fs-15 fw-600 text-dark p-3 px-4">
                                            {getTypeLabel(
                                              historyVal.source.type
                                            )}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={5}>
                                  <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                    No Data found
                                  </div>
                                </td>
                              </tr>
                            )}{' '}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {!fetchLoader && history.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={handleCurrentPage}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                handlePageLimit={handlePageLimit}
                pageLimit={pageLimit}
              />
            ) : (
              <></>
            )}
          </Row>
        </>
      )}
    </>
  );
};
export default SellerInventoryHistory;
