/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import threeDot from '../../../_admin/assets/media/svg_uTrade/three-dot-round.svg';
import { useAuth } from '../auth';
import APICallService from '../../../api/apiCallService';
import { customerService } from '../../../api/apiEndPoints';
import Loader from '../../../Global/loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Method from '../../../utils/methods';
import Pagination from '../../../Global/pagination';
import { PAGE_LIMIT } from '../../../utils/constants';
const DashboardWrapper: FC = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState<any>(new Date());
  const [tabKey, setTabKey] = useState<any>(1);
  const options = [{ value: 'June 2023', label: 'June 2023' }];
  const { currentUser } = useAuth();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [dashboard, setDashboard] = useState<any>([]);
  const [totalAssignedMembers, setTotalAssignedMember] = useState<number>(0);
  const [totalOnBoardedBuyers, setTotalOnBoardedBuyers] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      await fetchData(page, pageLimit, '1', searchTerm, date);
      setFetchLoading(false);
    })();
  }, []);
  const fetchData = async (
    pageNo: number,
    limit: number,
    state: string,
    search: string,
    dateFilter: string
  ) => {
    let params = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
      searchTerm: search,
      needCount: pageNo === 1,
      needStats: pageNo === 1,
      state: state,
      year: Method.getYear(dateFilter),
      month: parseInt(Method.getMonth(dateFilter)) - 1,
    };
    let apiService = new APICallService(customerService.memberDetails, params, {
      id: currentUser?._id,
    });
    let response = await apiService.callAPI();
    if (response) {
      if (Object.keys(response.user).length) {
        if (pageNo === 1) {
          setTotalRecords(response.user.total);
        } else {
          let prevTotal = totalRecords;
          setTotalRecords(prevTotal);
        }
        setDashboard(response.user.records);
      } else {
        setTotalRecords(0);
      }
      setTotalAssignedMember(response.totalAssignedMembers);
      setTotalOnBoardedBuyers(response.totalOnBoardedBuyers);
    }
  };
  const handleActive = async (val: string) => {
    setLoading(true);
    setTabKey(val);
    setDashboard([]);
    setTotalRecords(0);
    await fetchData(page, pageLimit, val, searchTerm, date);
    setLoading(false);
  };
  const handleDateFilter = async (value: any) => {
    setLoading(true);
    setDate(value);
    setDashboard([]);
    setTotalRecords(0);
    await fetchData(page, pageLimit, tabKey, searchTerm, value);
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setLoading(true);
    setPage(val);
    await fetchData(val, pageLimit, tabKey, searchTerm, date);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    await fetchData(val + 1, pageLimit, tabKey, searchTerm, date);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    await fetchData(val - 1, pageLimit, tabKey, searchTerm, date);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    await fetchData(1, event.target.value, tabKey, searchTerm, date);
    setLoading(false);
  };
  const handleCustomerProfile = (id: string) => {
    navigate('/customers/customer-profile', { state: id });
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>No More details available</span>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <>
        <div className="p-9">
          <Row className="align-items-center g-5 mb-7">
            <Col
              xs={12}
              sm
            >
              <h1 className="fs-22 fw-bolder mb-0">{currentUser?.name}</h1>
            </Col>
            <Col xs="auto">
              <div className="w-175px me-2">
                <DatePicker
                  className="form-control bg-light mh-50px fs-16 fw-bold text-dark mw-lg-174px"
                  selected={date}
                  onChange={(value) => handleDateFilter(value)}
                  selectsStart
                  startDate={date}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  fixedHeight
                />
              </div>
            </Col>
            <Col xs="auto">
              <Button
                variant="link"
                className="btn-flush"
              >
                <img
                  src={threeDot}
                  width={47}
                  height={47}
                  alt=""
                />
              </Button>
            </Col>
          </Row>
          {!fetchLoading ? (
            <>
              <Row className="align-items-center g-6 mb-7">
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-d4e1fc rounded py-4 px-5">
                    <div className="d-flex align-items-center">
                      <div className="fs-22 fw-bolder">
                        {totalAssignedMembers} customers
                      </div>
                    </div>
                    <div className="fw-500 fs-16">Assigned customers</div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-ccebfd rounded py-4 px-5">
                    <div className="d-flex align-items-center">
                      <div className="fs-22 fw-bolder">0 orders</div>
                    </div>
                    <div className="fw-500 fs-16">Total orders</div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-ccf3f0 rounded py-4 px-5">
                    <div className="d-flex align-items-center">
                      <div className="fs-22 fw-bolder">
                        {totalOnBoardedBuyers} customers
                      </div>
                    </div>
                    <div className="fw-500 fs-16">Onboarded customers</div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-fbebbc rounded py-4 px-5">
                    <div className="d-flex align-items-center">
                      <div className="fs-22 fw-bolder">TSh 0</div>
                    </div>
                    <div className="fw-500 fs-16">Total sales</div>
                  </div>
                </Col>
              </Row>
              <div className="custom-tabContainer">
                <Tab.Container
                  activeKey={tabKey}
                  onSelect={(value) => {
                    if (value) {
                      handleActive(value);
                    }
                  }}
                >
                  <Row className="align-items-center variant-categories">
                    <Col
                      lg={'auto'}
                      className="mb-6"
                    >
                      <div className="bg-light border border-r8px p-3 px-md-6">
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey={'1'}>
                              Assigned customers{' '}
                              {tabKey === '1' ? '(' + totalRecords + ')' : ''}
                            </Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                            <Nav.Link eventKey={"3"}>
                              Total orders
                              {tabKey === "3" ? "(" + totalRecords + ")" : ""}
                            </Nav.Link>
                          </Nav.Item> */}
                          <Nav.Item>
                            <Nav.Link eventKey={'2'}>
                              Onboarded customers
                              {tabKey === '2' ? '(' + totalRecords + ')' : ''}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Tab.Content>
                        {/* Assigned customers */}
                        <Tab.Pane eventKey={'1'}>
                          <Card className="border border-r10px">
                            <Card.Body className="p-0">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Customer name
                                      </th>
                                      <th className="min-w-200px ">
                                        Orders placed
                                      </th>
                                      <th className="min-w-100px">
                                        Total sales
                                      </th>
                                      <th className="min-w-175px text-end"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {dashboard && dashboard.length ? (
                                          <>
                                            {dashboard.map(
                                              (val: any, index: number) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <div className="d-flex align-items-center">
                                                        <div className="symbol symbol-50px border me-5">
                                                          <img
                                                            src={
                                                              val.business.image
                                                            }
                                                            className="object-fit-contain"
                                                            alt=""
                                                          />
                                                        </div>
                                                        <div className="fs-15 fw-600">
                                                          {val.name} <br />{' '}
                                                          <span className="fs-14 fw-500 text-gray">
                                                            {val.business.name}
                                                          </span>{' '}
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <span className="fs-15 fw-600">
                                                        0
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className="fs-15 fw-600">
                                                        TSh 0
                                                      </span>
                                                    </td>
                                                    <td className="text-end">
                                                      {!val.regCompleted &&
                                                      val.addedByAdmin ===
                                                        false ? (
                                                        <OverlayTrigger
                                                          trigger="hover"
                                                          placement="bottom"
                                                          overlay={popover}
                                                        >
                                                          <Button
                                                            className="fs-14"
                                                            variant="light"
                                                          >
                                                            View details
                                                          </Button>
                                                        </OverlayTrigger>
                                                      ) : (
                                                        <Button
                                                          className="fs-14"
                                                          disabled={
                                                            !val.regCompleted &&
                                                            val.addedByAdmin ===
                                                              false
                                                          }
                                                          onClick={() => {
                                                            handleCustomerProfile(
                                                              val._id
                                                            );
                                                          }}
                                                        >
                                                          View details
                                                        </Button>
                                                      )}{' '}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {' '}
                                            <tr>
                                              <td colSpan={4}>
                                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                                  No Data found
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <td colSpan={4}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        {/* Total orders */}
                        <Tab.Pane eventKey={'3'}>
                          <Card className="border border-r10px">
                            <Card.Body className="p-0">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                                      <th className="min-w-md-175px min-w-150px">
                                        Order Date & ID
                                      </th>
                                      <th className="min-w-140px">
                                        Ordered by
                                      </th>
                                      <th className="min-w-125px">Quantity</th>
                                      <th className="min-w-125px">
                                        Total amount
                                      </th>
                                      <th className="min-w-150px text-end"></th>
                                    </tr>
                                  </thead>
                                  <tbody className="fs-15 fw-600">
                                    <tr>
                                      <td>
                                        <span className="text-dark d-block">
                                          25/03/23 - 21:30
                                        </span>
                                        <span className="text-gray d-block">
                                          {' '}
                                          #00982
                                        </span>
                                      </td>
                                      <td>
                                        <div className="badge badge-light border-r23px">
                                          <span className="fs-15 fw-600 text-dark p-3 d-block">
                                            Customer
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <span>23 items</span>
                                      </td>
                                      <td>
                                        <span>TSh 1,000,000</span>
                                      </td>
                                      <td className="text-end">
                                        <Button
                                          className="fs-14 fw-600"
                                          onClick={() =>
                                            navigate(
                                              '/orders-delivery/order-details'
                                            )
                                          }
                                        >
                                          View details
                                        </Button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="text-dark d-block">
                                          25/03/23 - 21:30
                                        </span>
                                        <span className="text-gray d-block">
                                          {' '}
                                          #00982
                                        </span>
                                      </td>
                                      <td>
                                        <div className="badge badge-light border-r23px">
                                          <span className="fs-15 fw-600 text-dark p-3 d-block">
                                            Patrick Richards
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <span>23 items</span>
                                      </td>
                                      <td>
                                        <span>TSh 1,000,000</span>
                                      </td>
                                      <td className="text-end">
                                        <Button
                                          className="fs-14 fw-600"
                                          onClick={() =>
                                            navigate(
                                              '/orders-delivery/order-details'
                                            )
                                          }
                                        >
                                          View details
                                        </Button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className="text-dark d-block">
                                          25/03/23 - 21:30
                                        </span>
                                        <span className="text-gray d-block">
                                          {' '}
                                          #00982
                                        </span>
                                      </td>
                                      <td>
                                        <div className="badge badge-light border-r23px">
                                          <span className="fs-15 fw-600 text-dark p-3 d-block">
                                            {' '}
                                            Nalla Okafor
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        <span>23 items</span>
                                      </td>
                                      <td>
                                        <span>TSh 1,000,000</span>
                                      </td>
                                      <td className="text-end">
                                        <Button
                                          className="fs-14 fw-600"
                                          onClick={() =>
                                            navigate(
                                              '/orders-delivery/order-details'
                                            )
                                          }
                                        >
                                          View details
                                        </Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        {/* Onboarded customers */}
                        <Tab.Pane eventKey={'2'}>
                          <Card className="border border-r10px">
                            <Card.Body className="p-0">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Customer name
                                      </th>
                                      <th className="min-w-200px ">
                                        Orders placed
                                      </th>
                                      <th className="min-w-100px">
                                        Total sales
                                      </th>
                                      <th className="min-w-175px text-end"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {dashboard && dashboard.length ? (
                                          <>
                                            {dashboard.map(
                                              (val: any, index: number) => {
                                                return (
                                                  <>
                                                    <tr>
                                                      <td>
                                                        <div className="d-flex align-items-center">
                                                          <div className="symbol symbol-50px border me-5">
                                                            <img
                                                              src={
                                                                val.business
                                                                  .image
                                                              }
                                                              className="object-fit-contain"
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div className="fs-15 fw-600">
                                                            {val.name} <br />
                                                            <span className="fs-14 fw-500 text-gray">
                                                              {
                                                                val.business
                                                                  .name
                                                              }
                                                            </span>{' '}
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <span className="fs-15 fw-600">
                                                          0
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span className="fs-15 fw-600">
                                                          TSh 0
                                                        </span>
                                                      </td>
                                                      <td className="text-end">
                                                        {!val.regCompleted &&
                                                        val.addedByAdmin ===
                                                          false ? (
                                                          <OverlayTrigger
                                                            trigger="hover"
                                                            placement="bottom"
                                                            overlay={popover}
                                                          >
                                                            <Button
                                                              className="fs-14"
                                                              variant="light"
                                                            >
                                                              View details
                                                            </Button>
                                                          </OverlayTrigger>
                                                        ) : (
                                                          <Button
                                                            className="fs-14"
                                                            disabled={
                                                              !val.regCompleted &&
                                                              val.addedByAdmin ===
                                                                false
                                                            }
                                                            onClick={() => {
                                                              handleCustomerProfile(
                                                                val._id
                                                              );
                                                            }}
                                                          >
                                                            View details
                                                          </Button>
                                                        )}{' '}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {' '}
                                            <tr>
                                              <td colSpan={4}>
                                                <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                                  No Data found
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <td colSpan={4}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                      {!fetchLoading ? (
                        <>
                          {dashboard && dashboard.length ? (
                            <Pagination
                              totalRecords={totalRecords}
                              currentPage={page}
                              handleCurrentPage={(event: any) => {
                                handleCurrentPage(event);
                              }}
                              handleNextPage={(event: any) => {
                                handleNextPage(event);
                              }}
                              handlePreviousPage={(event: any) => {
                                handlePreviousPage(event);
                              }}
                              pageLimit={pageLimit}
                              handlePageLimit={(event: any) => {
                                handlePageLimit(event);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Loader loading={fetchLoading} />
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
};
export { DashboardWrapper };
