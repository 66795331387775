// src/store.js
import { createStore } from 'redux';
import { listSeller } from '../utils/storeString';
const initialState = {
  currentTab: -1,
  page: 1,
};
// Define a reducer function
const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case listSeller.currentTab:
      return { ...state, currentTab: action.payload };
    case listSeller.page:
      return { ...state, page: action.payload };
    case listSeller.limit:
      return { ...state, limit: action.payload };
    default:
      return state;
  }
};
// Create the Redux store
const store = createStore(rootReducer);
export default store;
