import { Button, Card, Col, Row } from 'react-bootstrap';
import { CustomNotificationString } from '../../../utils/string';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ApprovedProduct } from '../../../utils/constants';
import { error, success } from '../../../Global/toast';
import APICallService from '../../../api/apiCallService';
import { buyer, dashboard, manageProduct } from '../../../api/apiEndPoints';
import { notificationJSON } from '../../../utils/staticJSON';
import { CustomSelectWhite } from '../../custom/Select/CustomSelectWhite';
import { CustomReportSelect } from '../../custom/Select/CustomReportSelect';
import Method from '../../../utils/methods';
import { useNavigate } from 'react-router-dom';
import e from 'express';
const CustomNotification = () => {
  const navigate = useNavigate();
  const [productLoading, setProductLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [notificationData, setNotificationData] = useState<{
    title: string;
    message: string;
    buyers: any;
    variants: any;
  }>({
    title: '',
    message: '',
    buyers: [],
    variants: [],
  });
  const [validation, setValidation] = useState<{
    title: boolean;
    message: boolean;
    buyers: boolean;
    variants: boolean;
  }>({
    title: false,
    message: false,
    buyers: false,
    variants: false,
  });
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [customerList, setCustomerList] = useState<any>([]);
  const [productsList, setProductsList] = useState<any>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [topOrderedData, setTopOrderedData] = useState<any>([]);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [reload, setReload] = useState<Boolean>(false);
  useEffect(() => {
    (async () => {
      await fetchCustomers(1);
    })();
  }, []);
  const fetchCustomers = async (pageNo: number) => {
    setCustomerLoading(true);
    let params = {
      pageNo: pageNo,
      limit: 0,
      sortKey: 'createdAt',
      sortOrder: -1,
      state: 1,
      needCount: pageNo === 1,
    };
    const apiService = new APICallService(buyer.listBuyer, params);
    const response = await apiService.callAPI();
    if (response) {
      let data: any = [];
      let selectAll: any = [
        {
          value: 0,
          name: 'Select All',
          title: 'Select All',
          label: (
            <>
              <span className="fs-16 fw-600 text-black ">Select All</span>
            </>
          ),
          id: 0,
        },
      ];
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          name: val.business.name,
          title: val.business.name,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.business.image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {val.business.name}
              </span>
            </>
          ),
          id: val._id,
        });
      });
      data.unshift(...selectAll);
      setCustomerList(data);
    }
    setCustomerLoading(false);
  };
  const fetchProducts = async (pageNo: number) => {
    setProductLoading(true);
    let data = {
      pageNo: pageNo,
      limit: 0,
      sortKey: 'title',
      sortOrder: -1,
      state: 1,
      needCount: false,
      ['status[0]']: ApprovedProduct,
    };
    let apiService = new APICallService(manageProduct.listVariant, data);
    let response = await apiService.callAPI();
    if (response.records) {
      let data: any = [];
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          name: Method.replaceSpace(val.title),
          title: Method.replaceSpace(val.title),
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.media[0].image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {Method.replaceSpace(val.title)}
              </span>
            </>
          ),
          id: val._id,
          productId: val.product.reference,
        });
      });
      setProductsList(data);
    } else {
      setProductsList([]);
    }
    setProductLoading(false);
  };
  const fetchTopProducts = async () => {
    let params: any = {
      fromDate: Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
      toDate: Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '2',
    };
    let apiService = new APICallService(dashboard.orderReport, params);
    let response = await apiService.callAPI();
    if (response && response.topProducts.length) {
      let data: any = [];
      let selectAll: any = [
        {
          value: 0,
          name: 'Select All',
          title: 'Select All',
          label: (
            <>
              <span className="fs-16 fw-600 text-black ">Select All</span>
            </>
          ),
          id: 0,
        },
      ];
      response.topProducts.map((val: any) => {
        data.push({
          value: val._id,
          name: Method.replaceSpace(val.variant.title),
          title: Method.replaceSpace(val.variant.title),
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.variant.media[0].image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {Method.replaceSpace(val.variant.title)}
              </span>
            </>
          ),
          id: val.variant._id,
          productId: val.variant?.product?.reference,
        });
      });
      data.unshift(...selectAll);
      setProductsList(data);
    } else {
      error('No top products are available for selected customer');
      setProductsList([]);
    }
  };
  const fetchTopCustomers = async () => {
    let params: any = {
      fromDate: Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
      toDate: Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
      // fromDate: '2023-11-01',
      // toDate: '2023-11-30',
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '1',
    };
    let apiService = new APICallService(dashboard.orderReport, params);
    let response = await apiService.callAPI();
    if (response && response.topCustomers.length) {
      let data: any = [];
      let selectAll: any = [
        {
          value: 0,
          name: 'Select All',
          title: 'Select All',
          label: (
            <>
              <span className="fs-16 fw-600 text-black ">Select All</span>
            </>
          ),
          id: 0,
        },
      ];
      response.topCustomers.map((val: any) => {
        data.push({
          value: val.customer._id,
          name: val.customer.business.name,
          title: val.customer.business.name,
          label: (
            <>
              <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                <img
                  src={val.customer.business.image}
                  className="p-1"
                />
              </span>
              <span className="fs-16 fw-600 text-black ">
                {val.customer.business.name}
              </span>
            </>
          ),
          id: val.customer._id,
        });
      });
      data.unshift(...selectAll);
      setCustomerList(data);
    } else {
      error('No top customers in last month');
      setProductsList([]);
    }
  };
  const fetchBuyerCart = async (customerId: string) => {
    setProductLoading(true);
    let data = {
      limit: 0,
      sortKey: 'title',
      sortOrder: -1,
      needCount: false,
    };
    let apiService = new APICallService(buyer.cart, data, {
      id: customerId,
    });
    let response = await apiService.callAPI();
    if (response.records.length) {
      setProductsList(response.records);
    } else {
      error('No products are available in the cart');
      setProductsList([]);
    }
    setProductLoading(false);
  };
  const fetchTopOrders = async (customerId: string) => {
    let params: any = {
      fromDate: Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
      toDate: Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
      reportType: '1',
    };
    let apiService = new APICallService(buyer.dashboard, params, {
      id: customerId,
    });
    let response = await apiService.callAPI();
    if (response) {
      if (response.topProducts.length) {
        let data: any = [];
        let selectAll: any = [
          {
            value: 0,
            name: 'Select All',
            title: 'Select All',
            label: (
              <>
                <span className="fs-16 fw-600 text-black ">Select All</span>
              </>
            ),
            id: 0,
          },
        ];
        response.topProducts.map((val: any) => {
          data.push({
            value: val._id,
            name: Method.replaceSpace(val.variant.title),
            title: Method.replaceSpace(val.variant.title),
            label: (
              <>
                <span className="symbol symbol-xl-40px symbol-35px border border-r10px me-2">
                  <img
                    src={val.variant.media[0].image}
                    className="p-1"
                  />
                </span>
                <span className="fs-16 fw-600 text-black ">
                  {Method.replaceSpace(val.variant.title)}
                </span>
              </>
            ),
            id: val.variant._id,
            productId: val.variant?.product?.reference,
          });
        });
        data.unshift(...selectAll);
        setProductsList(data);
      } else {
        error('No top ordered products are available for these customer');
        setProductsList([]);
      }
    }
  };
  const handleInputChange = (event: any, name: string) => {
    const tempData: any = { ...notificationData };
    const tempValidation: any = { ...validation };
    tempData[name] = event.target.value;
    if (event.target.value.trim().length > 0) {
      tempValidation[name] = false;
    } else {
      tempValidation[name] = true;
    }
    setValidation(tempValidation);
    setNotificationData(tempData);
  };
  const handleCustomer = async (event: any) => {
    const tempData = { ...notificationData };
    if (Array.isArray(event)) {
      if (event.length > tempData.buyers.length) {
        if (
          event.some((item) => item.value === 0) ||
          event.length == customerList.length - 1
        ) {
          tempData.buyers = customerList;
        } else {
          tempData.buyers = event;
        }
      } else {
        if (event.some((val: any) => val.value === 0)) {
          let temp = event.filter((val: any) => val.value !== 0);
          tempData.buyers = temp;
        } else if (
          !event.some((val: any) => val.value === 0) &&
          event.length == customerList.length - 1
        ) {
          tempData.buyers = [];
        } else {
          tempData.buyers = event;
        }
      }
    } else {
      tempData.buyers = [event];
    }
    setSelectedCustomer(event);
    if (event && selectedNotification?.value == 3) {
      await fetchBuyerCart(event.id);
    }
    if (event && selectedNotification?.value == 2) {
      if (tempData.buyers.length) {
        await fetchTopOrders(event.id);
      }
    }
    setNotificationData(tempData);
  };
  const handleProduct = async (event: any) => {
    const tempData = { ...notificationData };
    if (Array.isArray(event)) {
      if (event.length > tempData.variants.length) {
        if (
          event.some((item) => item.value === 0) ||
          event.length == productsList.length - 1
        ) {
          tempData.variants = productsList;
        } else {
          tempData.variants = event;
        }
      } else {
        if (event.some((val: any) => val.value === 0)) {
          let temp = event.filter((val: any) => val.value !== 0);
          tempData.variants = temp;
        } else if (
          !event.some((val: any) => val.value === 0) &&
          event.length == productsList.length - 1
        ) {
          tempData.variants = [];
        } else {
          tempData.variants = event;
        }
      }
    } else {
      tempData.variants = [event];
    }
    setSelectedCustomer(event);
    setNotificationData(tempData);
  };
  const handleNotification = async (event: any) => {
    const temp = { ...notificationData };
    temp.buyers = [];
    temp.variants = [];
    setProductsList([]);
    if (selectedNotification && selectedNotification.value === 6) {
      await fetchCustomers(1);
    }
    // setSelectedProduct([]);
    // setSelectedCustomer([]);
    setSelectedNotification(event);
    if (event.value == 4) {
      await fetchProducts(1);
    }
    if (event.value === 5) {
      await fetchTopProducts();
    }
    if (event.value === 6) {
      await fetchTopCustomers();
    }
    setNotificationData(temp);
  };
  const handleSubmit = async () => {
    const tempValidation: any = { ...validation };
    const tempData: any = { ...notificationData };
    tempData.title = tempData.title.trim();
    tempData.message = tempData.message.trim();
    if (tempData.title.length === 0) {
      tempValidation.title = true;
    }
    if (tempData.message.length === 0) {
      tempValidation.message = true;
    }
    setValidation(tempValidation);
    if (!tempValidation.title && !tempValidation.message) {
      if (!selectedNotification) {
        return error('Please select a notification type');
      }
      if (
        selectedNotification.value == 2 &&
        selectedNotification.value == 5 &&
        selectedNotification.value == 4 &&
        (tempData.buyers.length === 0 || tempData.variants.length === 0)
      ) {
        return error('Please select customers and products');
      }
      if (selectedNotification.value !== 2 && tempData.buyers.length === 0) {
        return error('Please select customers');
      }
      if (
        selectedNotification.value !== 3 &&
        tempData.buyers.some((val: any) => val.value == 0)
      ) {
        tempData.buyers.shift();
      }
      if (selectedNotification.value === 3) {
        if (!productsList.length) {
          return error('No products are available in the cart');
        }
      }
      if (
        (selectedNotification.value == 2 ||
          selectedNotification.value == 4 ||
          selectedNotification.value == 5 ||
          selectedNotification.value == 6) &&
        tempData.variants.some((val: any) => val.value == 0)
      ) {
        tempData.variants.shift();
      }
      tempData.buyers = tempData.buyers.map((item: any) => item.value);
      tempData.variants = tempData.variants.map((item: any) => {
        return {
          variantId: item.value,
          productId: item.productId,
        };
      });
      tempData.type = selectedNotification.type;
      setLoading(true);
      const apiService = new APICallService(buyer.sendNotification, tempData);
      const response = await apiService.callAPI();
      if (response) {
        success('Notification Sent successfully!');
        navigate('/custom-notification');
        setReload(!reload);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      setNotificationData({
        title: '',
        message: '',
        buyers: [],
        variants: [],
      });
      await fetchCustomers(1);
      setProductsList([]);
      setSelectedProduct([]);
      setSelectedNotification(null);
      setSelectedCustomer([]);
    })();
  }, [reload]);
  return (
    <div className="p-9">
      <Row className="g-6">
        <Col xs={12}>
          <h1 className="fs-22 fw-bolder mb-0">
            {CustomNotificationString.cutomNofication}
          </h1>
        </Col>
        <Col xs={12}>
          <Card className="bg-light border">
            <Card.Body className="p-9">
              <Row>
                <Col
                  xs={12}
                  className="mb-6 "
                >
                  <Row className="align-items-center">
                    <Col lg={2}>
                      <label className="fs-16 fw-500 text-dark mb-lg-0 mb-3">
                        {CustomNotificationString.title}
                      </label>
                    </Col>
                    <Col lg={5}>
                      <input
                        type="text"
                        className={clsx(
                          'form-control form-control-solid bg-white h-60px  form-control-lg text-dark fs-16 fw-600',
                          validation.title ? 'border-danger' : 'border-gray-300'
                        )}
                        name="title"
                        placeholder="Type here…"
                        value={notificationData.title}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleInputChange(e, 'title')}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Row className="align-items-center ">
                    <Col
                      lg={2}
                      className="d-flex align-items-start"
                    >
                      <label className="fs-16 fw-500 text-dark">
                        {CustomNotificationString.description}
                      </label>
                    </Col>
                    <Col lg={5}>
                      <textarea
                        className={clsx(
                          'form-control form-control-solid bg-white  form-control-lg text-dark fs-16 fw-600',
                          validation.message
                            ? 'border-danger'
                            : 'border-gray-300'
                        )}
                        rows={3}
                        name="message"
                        placeholder="Type here…"
                        value={notificationData.message}
                        onChange={(e: any) => {
                          handleInputChange(e, 'message');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Row className="align-items-center ">
                    <Col
                      lg={2}
                      className="d-flex align-items-start"
                    >
                      <label className="fs-16 fw-500 text-dark">
                        Send notification for:-
                      </label>
                    </Col>
                    <Col lg={5}>
                      <CustomSelectWhite
                        // border={
                        //   validation[index].primaryCategory ? '#e55451' : ''
                        // }
                        // disabled={loading}
                        // isLoading={fetchLoader}
                        options={notificationJSON}
                        loadingMessage={'Fetching Data'}
                        isMulti={false}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): any => handleNotification(e)}
                        value={selectedNotification}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={12}
                  className="mb-6"
                >
                  <Row className="align-items-center ">
                    <Col
                      lg={2}
                      className="d-flex align-items-start"
                    >
                      <label className="fs-16 fw-500 text-dark">
                        Select Customer
                      </label>
                    </Col>
                    <Col lg={5}>
                      {/* <CustomReportSelect
                        backgroundColor="#ffff"
                        minHeight="60px"
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        options={customerList}
                        text={'customer selected'}
                        hideSelectedOptions={false}
                        onChange={(event: any) => {
                          handleCustomer(event);
                        }}
                        value={notificationData.buyers}
                        isMulti={
                          selectedNotification
                            ? selectedNotification.value == 2 ||
                              selectedNotification.value == 3
                              ? false
                              : true
                            : true
                        }
                      />
                       */}
                      <CustomReportSelect
                        backgroundColor="#ffff"
                        className={
                          validation.buyers
                            ? 'border border-danger rounded'
                            : ''
                        }
                        minHeight="60px"
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        options={
                          selectedNotification
                            ? selectedNotification.value == 2 ||
                              selectedNotification.value == 3
                              ? customerList.filter(
                                  (val: any) => val.value !== 0
                                )
                              : customerList
                            : customerList
                        }
                        isDeduct={true}
                        text={'customer selected'}
                        hideSelectedOptions={false}
                        onChange={(event: any) => {
                          handleCustomer(event);
                        }}
                        isLoading={customerLoading}
                        isDisabled={customerLoading}
                        value={
                          notificationData.buyers.length
                            ? notificationData.buyers
                            : []
                        }
                        defaultValue={
                          notificationData.buyers.length
                            ? notificationData.buyers
                            : []
                        }
                        isMulti={
                          selectedNotification
                            ? selectedNotification.value == 2 ||
                              selectedNotification.value == 3
                              ? false
                              : true
                            : true
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                {selectedNotification &&
                selectedNotification.value != 1 &&
                selectedNotification.value != 3 &&
                productsList.length ? (
                  <Col
                    lg={12}
                    className="mb-6"
                  >
                    <Row className="align-items-center ">
                      <Col
                        lg={2}
                        className="d-flex align-items-start"
                      >
                        <label className="fs-16 fw-500 text-dark">
                          Select Product
                        </label>
                      </Col>
                      <Col lg={5}>
                        <CustomReportSelect
                          backgroundColor="#ffff"
                          minHeight="60px"
                          closeMenuOnSelect={false}
                          isSearchable={true}
                          options={productsList}
                          text={'products selected'}
                          hideSelectedOptions={false}
                          onChange={(event: any) => {
                            handleProduct(event);
                          }}
                          value={notificationData.variants}
                          isMulti={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            {loading && (
              <span
                className="indicator-progress fs-16 fw-bold"
                style={{ display: 'block' }}
              >
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
            {!loading && <span className="indicator-label">Submit</span>}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default CustomNotification;
