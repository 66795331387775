import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import VerifiedPro from '../../../../_admin/assets/media/svg_uTrade/verified.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../custom/DateRange/dateRange.css';
import GrayClose from '../../../../_admin/assets/media/svg_uTrade/cross_gray.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import APICallService from '../../../../api/apiCallService';
import { inventory } from '../../../../api/apiEndPoints';
import Loader from '../../../../Global/loader';
import {
  All,
  NewRequests,
  UnderReview,
  Accepted,
  Rejected,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../../../utils/constants';
import Method from '../../../../utils/methods';
import { error } from '../../../../Global/toast';
import clsx from 'clsx';
const EditStockCount = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [details, setDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [initialDetails, setInitialDetails] = useState<any>({});
  const [missing, setMissing] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date('2023/07/07'));
  const [count, setCount] = useState(0);
  const [isGoodsMissing, setGoodsMissing] = useState<boolean>(false);
  const [isGoodsMissingValidation, setGoodsMissingValidation] =
    useState<boolean>(false);
  const [batchValidation, setBatchValidation] = useState<
    {
      quantityTypes: boolean;
    }[]
  >([
    {
      quantityTypes: false,
    },
  ]);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails();
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async () => {
    let apiService = new APICallService(inventory.variantUpdateInit, state);
    let response = await apiService.callAPI();
    setDetails(JSON.parse(JSON.stringify(response)));
    const arr = Array.from(
      { length: response.inventoryDetails.batchesList.length },
      (_, index) => ({
        quantityTypes: false,
      })
    );
    setBatchValidation(arr);
    setInitialDetails(JSON.parse(JSON.stringify(response)));
    setCount(response.batch);
  };
  const handleChange = (index: number, date: any) => {
    let temp = { ...details };
    temp.inventoryDetails.batchesList[index] = {
      ...temp.inventoryDetails.batchesList[index],
      expiry: date,
    };
    setDetails(temp);
  };
  const handleAddMore = () => {
    let temp = { ...details };
    let batchValidationTemp = [...batchValidation];
    let quantityTypes: any = [];
    if (temp.inventoryDetails && temp.inventoryDetails.quantityTypes) {
      const allObjectsHaveQuantityTypeWithStock =
        temp.inventoryDetails.batchesList.every((obj: any) =>
          obj.quantityTypes.some(
            (quantityType: any) =>
              quantityType.stockCount && quantityType.stockCount > 0
          )
        );
      if (allObjectsHaveQuantityTypeWithStock) {
        temp.inventoryDetails.quantityTypes.map((val: any) => {
          quantityTypes.push({
            type: val.type,
            credited: 0,
            stockCount: 0,
          });
        });
        temp.inventoryDetails.batchesList.push({
          batch: count,
          quantityTypes: quantityTypes,
          expiry: new Date(),
          isCustomAdded: true,
        });
        setCount(count + 1);
        batchValidationTemp.push({
          quantityTypes: false,
        });
      } else {
        temp.inventoryDetails.batchesList.forEach(
          (product: any, index: number) => {
            const hasStockCountGreaterThanZero = product.quantityTypes.some(
              (quantityType: any) =>
                quantityType.stockCount !== undefined &&
                quantityType.stockCount > 0
            );
            if (!hasStockCountGreaterThanZero) {
              batchValidationTemp[index].quantityTypes = true;
              error('At least one stock must have a value greater than 0.');
            }
          }
        );
      }
    }
    setBatchValidation(batchValidationTemp);
    setDetails(temp);
  };
  const handleRemove = (batchId: number) => {
    let temp = { ...details };
    let batchValidationTemp = [...batchValidation];
    temp.inventoryDetails.batchesList =
      temp.inventoryDetails.batchesList.filter(
        (batch: any) => batch.batch !== batchId
      );
    let counter = initialDetails.batch;
    temp.inventoryDetails.batchesList
      .filter((batch: any) => batch.isCustomAdded)
      .forEach((batch: any, index: number) => {
        if (batch.isCustomAdded) {
          batch.batch = counter;
          if (index === 0) {
          } else {
            batch.batch = counter + 1;
            counter++;
          }
        }
      });
    const index = temp.inventoryDetails.batchesList.findIndex(
      (batch: any) => batch.batch === batchId
    );
    batchValidationTemp.splice(index, 1);
    setBatchValidation(batchValidationTemp);
    setCount(counter);
    setDetails(temp);
  };
  useEffect(() => {
    if (details.inventoryDetails && details.inventoryDetails.batchesList) {
      const stockCountsMatch = checkStockCount(details.inventoryDetails);
      setMissing(stockCountsMatch);
    }
  }, [details]);
  const checkStockCount = (data: any) => {
    const { batchesList, quantityTypes } = data;
    const stockCountMap = new Map();
    batchesList.forEach((batch: any) => {
      batch.quantityTypes.forEach((quantityType: any) => {
        const { type, stockCount } = quantityType;
        const mappedType = type == 4 ? 1 : type;
        const count = stockCount == '' ? 0 : stockCount;
        const parsedStockCount = parseInt(count);
        if (!isNaN(parsedStockCount)) {
          if (stockCountMap.has(mappedType)) {
            stockCountMap.set(
              mappedType,
              stockCountMap.get(mappedType) + parsedStockCount
            );
          } else {
            stockCountMap.set(mappedType, parsedStockCount);
          }
        }
      });
    });
    const missingStock: any = quantityTypes
      .map((quantityType: any) => {
        const { type, stockCount, reservedQty } = quantityType;
        const mappedType = type == 4 ? 1 : type; // Map type 4 to type 1
        const totalStockCount = stockCountMap.get(mappedType) || 0;
        console.log('totalStockCount', type, totalStockCount);
        const missing = stockCount - totalStockCount;
        return {
          type,
          missing: missing < 0 ? 0 : missing,
        };
      })
      .filter((item: any) => item.missing > 0); // Exclude items with missing count 0
    return missingStock;
  };
  const handleCountChange = (
    index: number,
    quantityIndex: number,
    newValue: string
  ) => {
    let temp = { ...details };
    temp.inventoryDetails.batchesList[index].quantityTypes[
      quantityIndex
    ].stockCount = newValue;
    setDetails(temp);
  };
  const handleSubmit = async () => {
    setLoading(true);
    let temp = { ...details };
    let batchValidationTemp = [...batchValidation];
    const allObjectsHaveQuantityTypeWithStock =
      temp.inventoryDetails.batchesList.every((obj: any) =>
        obj.quantityTypes.some((quantityType: any) => quantityType.stockCount)
      );
    let valid = missing.length ? (isGoodsMissing ? true : false) : true;
    if (allObjectsHaveQuantityTypeWithStock && valid) {
      let apiTemp: any = {};
      if (isGoodsMissing) {
        apiTemp = { ...apiTemp, missing: 1 };
      }
      if (temp.inventoryDetails.batchesList.length) {
        apiTemp = { ...apiTemp, records: temp.inventoryDetails.batchesList };
        let apiService = new APICallService(inventory.variantUpdate, apiTemp, {
          id: state,
        });
        let response = await apiService.callAPI();
        if (response) {
          navigate('/inventory/goods-in-warehouse');
        }
      } else {
      }
    } else {
      temp.inventoryDetails.batchesList.forEach(
        (product: any, index: number) => {
          const hasStockCountGreaterThanZero = product.quantityTypes.some(
            (quantityType: any) =>
              quantityType.stockCount !== undefined &&
              quantityType.stockCount > 0
          );
          if (!hasStockCountGreaterThanZero) {
            batchValidationTemp[index].quantityTypes = true;
            error('At least one stock must have a value greater than 0.');
          }
        }
      );
    }
    setGoodsMissingValidation(!valid);
    setBatchValidation(batchValidationTemp);
    setDetails(temp);
    setLoading(false);
  };
  const cartonItem = missing.find(
    (item: any) =>
      item.type === CartonWithDozens || item.type === CartonWithPieces
  );
  const dozenItem = missing.find((item: any) => item.type === Dozen);
  const pieceItem = missing.find((item: any) => item.type === Piece);
  return (
    <>
      <div className="p-9">
        <div className="mb-7">
          <h1 className="fs-22 fw-bolder">Edit stock count</h1>
        </div>
        {!fetchLoader ? (
          <>
            {details !== null && details !== undefined ? (
              <>
                <div className="bg-light border border-r10px p-7 mb-7">
                  <Row className="align-items-center g-5">
                    <Col xs="auto">
                      <div className="symbol symbol-50px border">
                        <img
                          src={details.business.image}
                          className="img-fluid object-fit-contain"
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xs>
                      <div className="fs-20 fw-bolder d-flex align-items-center">
                        {details.business.name}
                        {details.business.KYC.status === Accepted - 1 ? (
                          <img
                            className="ms-2 mb-1"
                            width={24}
                            height={24}
                            src={VerifiedPro}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                        {details.business.KYC.status === All - 1 ||
                        details.business.KYC.status === NewRequests - 1 ||
                        details.business.KYC.status === UnderReview - 1 ? (
                          <>
                            <span className="bage badge-light-warning text-dark border-r5px fs-14 fw-600 ms-2 p-2">
                              Verification pending
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                        {details.business.KYC.status === Rejected - 1 ? (
                          <>
                            <span className="bage badge-light-danger text-dark border-r5px fs-14 fw-600 ms-2 p-2">
                              Rejected
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                    {details.business.goodsLoadingAreas &&
                    details.business.goodsLoadingAreas.length ? (
                      <Col
                        sm="auto"
                        className="text-end"
                      >
                        <div className="d-flex align-items-center fs-16 fw-600">
                          Seller products zone
                          <div className="bg-fcd00d border-r5px p-5 py-2 ms-4">
                            <span className="fs-20 fw-bolder">
                              {`${details.business.goodsLoadingAreas
                                .map((u: any) => u.name)
                                .join(' & ')}`}
                            </span>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
                <Card className="border border-r10px mb-7">
                  <Card.Body className="py-5">
                    <div className="table-responsive">
                      <table className="table align-middle gs-0 gy-3 mb-0">
                        <thead>
                          <tr className="fs-16 fw-600 border-bottom">
                            <th className="min-w-150px text-start">
                              Product name
                            </th>
                            <th className="w-144px text-center">Expires on</th>
                            <th className="w-115px text-center">Cartons</th>
                            <th className="w-115px text-center">Dozens</th>
                            <th className="w-100px text-center">Pieces</th>
                            <th className="w-15px text-end"></th>
                          </tr>
                        </thead>
                        <tbody className="mt-10">
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px border me-3">
                                  <img
                                    src={details.record.media[0].url}
                                    className="object-fit-contain"
                                    alt=""
                                  />
                                </div>
                                <span className="fs-15 fw-600">
                                  {details.record.title}
                                </span>
                              </div>
                            </td>
                            <td className="text-center">
                              <span className="fw-600 fs-15">-</span>
                            </td>
                            <td className="text-center">
                              {details.inventoryDetails.quantityTypes.some(
                                (item: any, idx: number) =>
                                  item.type === CartonWithDozens ||
                                  item.type === CartonWithPieces
                              ) ? (
                                <span className="fw-600 fs-15">
                                  {
                                    details.inventoryDetails.quantityTypes.find(
                                      (item: any) =>
                                        item.type === CartonWithDozens ||
                                        item.type === CartonWithPieces
                                    ).stockCount
                                  }{' '}
                                </span>
                              ) : (
                                <span className="fw-600 fs-15">-</span>
                              )}
                            </td>
                            <td className="text-center">
                              {details.inventoryDetails.quantityTypes.some(
                                (item: any, idx: number) => item.type === Dozen
                              ) ? (
                                <span className="fw-600 fs-15">
                                  {
                                    details.inventoryDetails.quantityTypes.find(
                                      (item: any) => item.type === Dozen
                                    ).stockCount
                                  }{' '}
                                </span>
                              ) : (
                                <span className="fw-600 fs-15">-</span>
                              )}
                            </td>
                            <td className="text-center">
                              {details.inventoryDetails.quantityTypes.some(
                                (item: any, idx: number) => item.type === Piece
                              ) ? (
                                <span className="fw-600 fs-15">
                                  {
                                    details.inventoryDetails.quantityTypes.find(
                                      (item: any) => item.type === Piece
                                    ).stockCount
                                  }{' '}
                                </span>
                              ) : (
                                <span className="fw-600 fs-15">-</span>
                              )}
                            </td>
                          </tr>
                          <>
                            {details.inventoryDetails.batchesList.length ? (
                              <>
                                {details.inventoryDetails.batchesList.map(
                                  (batchVal: any, index: number) => {
                                    return (
                                      <tr>
                                        {' '}
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <div className="symbol symbol-50px border me-3">
                                              <div className="symbol-label fs-18 fw-600 text-gray">
                                                {batchVal.batch}
                                              </div>
                                            </div>
                                            <span className="fs-15 fw-600">
                                              {batchVal.batch >=
                                              initialDetails.batch ? (
                                                <>Batch {batchVal.batch}</>
                                              ) : (
                                                <>
                                                  {' '}
                                                  Batch {batchVal.batch} -{' '}
                                                  {Method.convertDateToFormat(
                                                    batchVal._createdAt,
                                                    'DD/MM/YYYY'
                                                  )}
                                                </>
                                              )}
                                            </span>
                                          </div>
                                        </td>{' '}
                                        <td className="text-center">
                                          {batchVal.batch >=
                                          initialDetails.batch ? (
                                            <DatePicker
                                              className="form-control-custom fs-14 fw-600 min-w-144px min-h-45px px-3 text-center"
                                              selected={batchVal.expiry}
                                              onChange={(date) =>
                                                handleChange(index, date)
                                              }
                                              disabled={loading}
                                              selectsStart
                                              minDate={new Date()}
                                              placeholderText="Select date"
                                              fixedHeight
                                              dateFormat={'dd/MM/yyyy'}
                                            />
                                          ) : (
                                            <span className="fw-600 fs-15">
                                              {Method.convertDateToFormat(
                                                batchVal.expiry,
                                                'DD/MM/YYYY'
                                              )}
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <Form.Control
                                            className="form-control-custom fs-14 fw-600 w-88px min-h-45px px-3 text-center"
                                            value={
                                              batchVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type ===
                                                    CartonWithDozens ||
                                                  item.type === CartonWithPieces
                                              )?.stockCount ?? 0
                                            }
                                            disabled={
                                              !batchVal.quantityTypes.some(
                                                (item: any) =>
                                                  item.type ===
                                                    CartonWithDozens ||
                                                  item.type === CartonWithPieces
                                              ) || loading
                                            }
                                            type="number"
                                            onChange={(event) => {
                                              handleCountChange(
                                                index,
                                                batchVal.quantityTypes.findIndex(
                                                  (item: any) =>
                                                    item.type ===
                                                      CartonWithDozens ||
                                                    item.type ===
                                                      CartonWithPieces
                                                ),
                                                event.target.value
                                              );
                                            }}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <Form.Control
                                            className="form-control-custom fs-14 fw-600 w-88px min-h-45px px-3 text-center"
                                            value={
                                              batchVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type === Dozen
                                              )?.stockCount ?? 0
                                            }
                                            disabled={
                                              !batchVal.quantityTypes.some(
                                                (item: any) =>
                                                  item.type === Dozen
                                              ) || loading
                                            }
                                            onChange={(event) => {
                                              handleCountChange(
                                                index,
                                                batchVal.quantityTypes.findIndex(
                                                  (item: any) =>
                                                    item.type === Dozen
                                                ),
                                                event.target.value
                                              );
                                            }}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <Form.Control
                                            className="form-control-custom fs-14 fw-600 w-88px min-h-45px px-3 text-center"
                                            value={
                                              batchVal.quantityTypes.find(
                                                (item: any) =>
                                                  item.type === Piece
                                              )?.stockCount ?? 0
                                            }
                                            disabled={
                                              !batchVal.quantityTypes.some(
                                                (item: any) =>
                                                  item.type === Piece
                                              ) || loading
                                            }
                                            onChange={(event) => {
                                              handleCountChange(
                                                index,
                                                batchVal.quantityTypes.findIndex(
                                                  (item: any) =>
                                                    item.type === Piece
                                                ),
                                                event.target.value
                                              );
                                            }}
                                          />
                                        </td>
                                        {batchVal.batch >=
                                        initialDetails.batch ? (
                                          <td className="text-end">
                                            <Button
                                              variant="link"
                                              className="btn-flush"
                                              onClick={() => {
                                                handleRemove(batchVal.batch);
                                              }}
                                            >
                                              <img
                                                className="w-17px h-17px"
                                                src={GrayClose}
                                                alt=""
                                              />
                                            </Button>
                                          </td>
                                        ) : (
                                          <td className="text-end"></td>
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        </tbody>
                      </table>
                    </div>
                    <div className="my-2">
                      <Button
                        variant="link"
                        className="btn-flush fs-16 fw-bolder text-primary"
                        onClick={() => {
                          handleAddMore();
                        }}
                      >
                        + Add new batch & expiry
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                {missing && missing.length ? (
                  <div className="bg-light border border-r10px p-7 mb-9">
                    <Row className="align-items-center g-5">
                      <Col xs>
                        <span className="fs-20 fw-600">
                          {missing
                            .filter((item: any) => item.missing > 0)
                            .map((item: any, index: number) => {
                              const { type, missing } = item;
                              let unit;
                              if (
                                type === CartonWithDozens ||
                                type === CartonWithPieces
                              ) {
                                unit = 'cartons';
                              } else if (type === Dozen) {
                                unit = 'dozens';
                              } else if (type === Piece) {
                                unit = 'pieces';
                              }
                              return `${missing} ${unit}`;
                            })
                            .join(', ')}
                          {' are missing / damaged?'}
                        </span>
                      </Col>
                      <Col xs="auto">
                        <div className="form-check form-check-custom form-check-solid form-check-lg ">
                          <input
                            className={clsx(
                              'form-check-input h-34px w-34px ',
                              isGoodsMissingValidation
                                ? 'border border-danger'
                                : ''
                            )}
                            type="checkbox"
                            checked={isGoodsMissing}
                            onClick={() => {
                              setGoodsMissingValidation(isGoodsMissing);
                              setGoodsMissing(!isGoodsMissing);
                            }}
                          />
                          <label className="form-check-label fs-16 fw-600 text-dark ms-3">
                            Mark as missing
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <></>
                )}
                <div className="d-flex justify-content-end">
                  <Button
                    size="lg"
                    onClick={() => handleSubmit()}
                  >
                    {!loading && (
                      <span className="indicator-label fs-16 fw-bold">
                        Save details
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress fs-16 fw-bold"
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoader} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default EditStockCount;
