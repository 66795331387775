import { Button, Col, Modal, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
const ViewRequestedGoodsProducts = (props: any) => {
  return (
    <>
      <Modal
        {...props}
        show={props.products && props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-lg-794px"
        className="border-r10px"
        centered
      >
        <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
          <img
            className="btn btn-flush close-inner-top"
            width={40}
            height={40}
            src={CrossSvg}
            onClick={props.onHide}
            alt="closebutton"
          />
          <Modal.Title className="fs-26 fw-bolder mw-lg-375px pt-lg-3">
            {props.products && props.products.length} products
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="mb-3"
          style={{ maxHeight: '500px', overflowY: 'auto' }}
        >
          <Row className="px-lg-6 gy-6">
            {props.products && props.products.length ? (
              <>
                {props.products.map((productVal: any) => {
                  return (
                    <Col md={6}>
                      <div className="border border-r8px p-5">
                        <div className="d-flex align-items-center">
                          <div className="me-5 position-relative">
                            <div className="symbol symbol-50px border">
                              <img
                                src={productVal.variant.media[0].url}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="ms-3">
                            <span className="fs-15 fw-600">
                              {productVal.variant.title.replace(
                                /\s*\)\s*/g,
                                ')'
                              )}
                            </span>
                            {productVal.variant.product ? (
                              <div className="fs-14 fw-500 text-gray">
                                {productVal.variant.product.business.name}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewRequestedGoodsProducts;
